class Alert {

	static name() {
		return 'Alert';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$closeBtns: $(el).find('[data-close-alert]'),
			$heroSlider: $('[data-module="HeroSlider"]'),
			$consentAlert: $(el).find('#consent-message'),
		};

		this.getTtl = () => {
			let cookieTtl = parseInt(objects.$el.data('cookie-ttl'));

			return isNaN(cookieTtl) ? 0 : cookieTtl;
		};

		this.getItem = (name, ttl) => {
			if (ttl <= 0) {
				return sessionStorage.getItem(name);
			}

			let item = localStorage.getItem(name);
			if (item == null) {
				return null;
			}

			item = JSON.parse(item);

			if (item.expiration == null || new Date(item.expiration) <= new Date()) {
				localStorage.removeItem(name);

				return null;
			}

			return item;
		};

		this.setCheckedAlert = (event) => {
			let cookie = objects.$el.data('cookie');
			let cookieTtl = this.getTtl();

			if (cookieTtl <= 0) {
				sessionStorage.setItem(cookie, true);
			}
			else {
				let expiration = new Date();
				expiration.setDate(expiration.getDate() + cookieTtl);

				localStorage.setItem(cookie, JSON.stringify({expiration: expiration}));
			}

			if(objects.$heroSlider.length > 0) {
				objects.$heroSlider.css('top', 0);
			}
			
			if($(event.target).parents('#site-alert').length) {				
				$('.header ul.navbar-nav > li.nav-item .dropdown-menu.parent').addClass("normal");
				$('.header ul.navbar-nav > li.nav-item .dropdown-menu.parent').removeClass("alert-sized");
			}
			

		};

		this.checkSession = () => {
			let cookie = objects.$el.data('cookie');
			console.log("Going into check session");
			if(!this.getItem(cookie, this.getTtl())) {
				objects.$el.collapse('show');
				objects.$consentAlert.focus();
				$('.header ul.navbar-nav > li.nav-item .dropdown-menu.parent').removeClass("normal");
				$('.header ul.navbar-nav > li.nav-item .dropdown-menu.parent').addClass("alert-sized");
			}
		};

		this.initAlert = () => {
			this.checkSession();
			objects.$closeBtns.on('click', this.setCheckedAlert);
		};
	}

	init() {
		this.initAlert();
	}
}

export default Alert;

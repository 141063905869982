class EvaluationBoard {

	static name() {
		return 'EvaluationBoard';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$productPkgSpecs: $('.product-package-specs'),
			$partNumber: $(el).data('part-number'),
			$partNumberQtyInput: $(el).find('input[name="paramProductId"]')
		};

		let utils = new Utils();

		this.initEvaluationBoard = () => {
			utils.submitForm(objects.$el, this.validationSuccessful);

			objects.$el.on('hide.bs.collapse', function () {
				objects.$productPkgSpecs.removeClass('eb-active');
			});

			objects.$el.on('show.bs.collapse', function () {
				objects.$productPkgSpecs.addClass('eb-active');
			});

			$(document).on('click', (e) => {
				if (!$(e.target).parents().is(objects.$el)) {
					objects.$el.collapse('hide');
				}
			});
		};

		this.validationSuccessful = () => {
			objects.$partNumberQtyInput.val(`${objects.$partNumber}[${$('#evaluation-quantity').val()}]`);
			objects.$el[0].submit();
		};
	}

	init() {
		this.initEvaluationBoard();
	}
}

export default EvaluationBoard;

class PowerSystemDesigner {

	static name() {
		return 'PowerSystemDesigner';
	}

	constructor(props, el, $, Utils) {
		const objects = {
			$el: $(el),
			$btnAddOutput: $(el).find('[data-add]'),
			$btnNext: $(el).find('[data-next]'),
			$btnRedirect: $(el).find('[data-redirect]'),
			$osContainer: $(el).find('.output-specs-container'),
			$osContainerPanel: $(el).find('.os-panel'),
			$outputMarkup: $($(el).data('outputspecs')),
			$formGroup: $(el).find('.form-group'),
			$formInput: $(el).find('.form-control'),
			$formRadio: $(el).find('.form-check-input'),
			$checkedFormRadio: $(el).find('.form-check-input[checked]'),
			$nextSetData: $(el).find('[data-validated]'),
			$conditionals: $(el).find('[data-conditionals] input'),
			$executeSearchInput: $(el).find('input[name="executeSearch"]'),
			$dataTextLabel: $(el).find('label[data-text]'),
			$dataInputs: $('.has-label-completion'),
			$numberInputs: $('.js-number-input'),
			$ampsLabel: $(el).data('amps-label'),
			$wattsLabel: $(el).data('watts-label')
		};

		const instanceId = props.instanceId;
		const isWidget = objects.$el.hasClass('widget');

		let utils = new Utils();
		let counter;
		let acOrDc = 'DC';
		this.populateMarkup = () => {
			let $os = $('<div class="output-specs"></div>');
			let $specs = objects.$outputMarkup.clone().removeAttr('data-populate');
			let $fg = $specs.find('.form-group');
			let $dc = $specs.find('[data-conditionals]');
			let outputId = $specs.find('.id');
			let newSpecId = $('.output-specs > div').attr('id');

			if (newSpecId) {
				if (newSpecId.substr(-1) == "1") {
					counter = 2;
				} else {
					counter = 1;
				}
			} else {
				counter = 1;
			}

			outputId.text(counter);

			// Input unique IDs

			$fg.each(function() {
				//var newCounter = specCounter++;
				$(this).find('input').attr('id', $(this).find('input').attr('id') + counter);
				$(this).find('input').attr('name', $(this).find('input').attr('name') + counter);
				$(this).find('.invalid-feedback').attr('data-error-id', $(this).find('input').attr('name'));
				$(this).find('select').attr('id', $(this).find('select').attr('id') + counter);
				$(this).find('label').attr('for', $(this).find('label').attr('for') + counter);
				$(this).find('select').attr('name', $(this).find('select').attr('name') + counter);
			});

			$dc.each(function() {
				$(this).attr('data-msg', $(this).attr('data-msg') + ' ' + counter);
			});

			$os.append($specs);
			//change isolation/regulation buttons depending on ac/dc
			if(acOrDc === 'DC'){
				$os.find('.ac-option').remove();
			}else{
				$os.find('.dc-option').remove();
			}
			objects.$osContainerPanel.append($os);

			//bind power current buttons
			$os.find('.power-current .form-check-input').on('click', this.togglePowerCurrent);
			$os.find('.js-number-input').on('keypress', utils.psdNumberKeyPress);
			$('[data-remove]').on('click', this.removeSpecs);

			$os.find('.custom-select').on('change', (e) => {
				this.toggleCustomInput(e, $os.find('.js-custom-input'), e.currentTarget);
			});
			$os.find('.js-custom-input').on('keypress', (e) => this.populateCustomSelect(e, $os.find('.custom-select')));

			$os.find('input[data-name]').on('blur', (e) => this.setOutputName(e, $os.find('[data-name]').val(), e.currentTarget));

			setTimeout(function(){
				$os.addClass('animate');
			}, 100);

			$specs.attr("id", function() { return $(this).attr("id") + counter });
		};

		this.addAnchor = () => {
			objects.$btnAddOutput.hide();
			objects.$btnRedirect.show();
			$('[data-remove]').removeClass('d-none');
		};

		this.removeSpecs = (e) => {
			$(e.target).parents('.output-specs').remove();
			$('[data-remove]').addClass('d-none');
			objects.$btnRedirect.hide();
			objects.$btnAddOutput.show();
			$('.id').text('1');
		};

		this.populateNextStep = () => {
			objects.$osContainer.show();
			objects.$nextSetData.show();
			objects.$btnNext.hide();
		};

		this.applyInstanceIds = () => {
			objects.$el.find('input, select').each(function() {
				let $this = $(this);
				if ($this.attr('name').indexOf(`-i${instanceId}`) === -1) {
					$this.attr('name', `${$this.attr('name')}-i${instanceId}`);
					$this.siblings('.invalid-feedback').attr('data-error-id', `${$this.attr('name')}`);
				}
			});
		};

		this.dropInstanceIds = () => {
			objects.$el.find('input, select').each(function() {
				let $this = $(this);
				let pattern = new RegExp("-i"+instanceId+"(-ac)?");
				$this.attr('name', $this.attr('name').replace(pattern, ''));
			});
		};

		this.callValidationApi = (e) => {
			let that = this;

			$.ajax({
				url: props.validationUrl,
				method: 'post',
				data: objects.$el.serialize(),
				headers: {
					Accept: 'application/json'
				}
			}).done(function(resp) {
				if (resp != null) {
					that.validation(e, that.mapResults(resp));
				}
			});
		};

		this.mapResults = (results) => {
			let fieldErrors = {};
			let errorMessages = {};
			let statusMsg = results.statusMsg == null ? '' : results.statusMsg;

			// We care about the # of output specs because we'll consider ourselves valid if we have no output specs
			// but get errors back for them
			let outputSpecCount = objects.$el.find('.output-specs').length;

			if (outputSpecCount <= 0) {
				// Void out the status message if it's regarding output specs if we don't have any
				statusMsg = '';
			}

			this.applyInstanceIds();

			results.errors.forEach(function(error) {
				error.fieldNames.forEach(function(fieldName) {
					if (outputSpecCount > 0 ||
						fieldName.toLowerCase().indexOf('out') === -1) {

						fieldErrors[fieldName] = true;
						errorMessages[error.errorMessage] = true;
					}

					let errorMessage;
					let requiredMsg = document.querySelector(`[data-error-id="${fieldName}-i${instanceId}"]`).dataset.errorRequired;
					let checkError = document.querySelector(`[data-error-id="${fieldName}-i${instanceId}"]`).dataset.errorCheck;

					if (error.errorMessage.indexOf('required') !== -1) {
						errorMessage = requiredMsg;
					} else {
						errorMessage = checkError;
					}

					document.querySelector(`[data-error-id="${fieldName}-i${instanceId}"]`).textContent=errorMessage;
				});
			});

			return {
				fieldErrors: Object.keys(fieldErrors),
				errorMessages: Object.keys(errorMessages),
				status: Object.keys(fieldErrors).length <= 0 && (statusMsg.length <= 0 || statusMsg === 'Success'),
				statusMsg: statusMsg
			};
		};

		this.validation = (e, results) => {
			this.clearErrors();
			this.dropInstanceIds();

			objects.$el.addClass('was-validated');

			if (results == null) {
				this.callValidationApi(e);
				return false;
			}

			if (!results.status) {
				this.applyInstanceIds();
				this.displayErrors(results);

				return false;
			}

			if (isWidget) {
				objects.$executeSearchInput.val('false');
				objects.$el[0].submit();
				this.applyInstanceIds();

				return false;
			}

			let outputSpecCount = objects.$el.find('.output-specs').length;

			if(outputSpecCount) {
				this.addAnchor();
			}

			let addOutput = e.target === objects.$btnAddOutput[0] || e.target === objects.$btnRedirect[0];

			objects.$executeSearchInput.val('' + (addOutput ? 'false' : 'true'));

			if ((!addOutput && outputSpecCount >= 1)
				|| (addOutput && outputSpecCount >= 2)) {
				objects.$el[0].submit();
			}

			else {
				if (outputSpecCount <= 1) {
					this.populateMarkup();
				}

				this.populateNextStep();
			}

			this.applyInstanceIds();

			return false;
		};

		this.displayErrors = (results) => {
			results.fieldErrors.forEach(function(field) {
				objects.$el.find(`[name="${field}-i${instanceId}"]`).prop('required', true).addClass('is-invalid');
			});

			results.statusMsg.split(' ').forEach(function(word) {
				objects.$el.find(`select[name="${word.replace('.', '')}-i${instanceId}"]`).prop('required', true).addClass('is-invalid');
			});
		};

		this.clearErrors = () => {
			objects.$el.find('select, input').prop('required', false).removeClass('is-invalid');
		};

		this.toggleSub = (e) => {

			// Reset OS Container Panel
			objects.$osContainerPanel.find('.output-specs').remove();
			objects.$osContainer.hide();
			objects.$nextSetData.hide();
			objects.$btnNext.show();
			counter = 1;

			let newLabel;
			let toggleSwitch = false;

			acOrDc = $(e.target).val();
			toggleSwitch = $(e.target).val() === 'AC';

			objects.$dataTextLabel.each(function() {
				$(this).find('.input-value').html('');
				let $thisText = $(this).html();

				objects.$dataTextLabel.removeClass('active-bold');
				objects.$conditionals.val('');

				newLabel = toggleSwitch ? $thisText.replace('DC', 'AC') : $thisText.replace('AC', 'DC');

				$(this).html(newLabel);
			});
		};

		this.toggleSelection = (e) => {
			this.toggleSub(e);
		};

		this.togglePowerCurrent = (e) => {
			let $container = $(e.currentTarget).closest('.js-wa-container');
			let $label = $container.find('.widget-label');

			if($(e.currentTarget).val()==='W'){
				$label.text('W');
			} else{
				$label.text('A');
			}
		};

		this.toggleCustomInput = (e, siblingEl, selectedInput) => {
			$(selectedInput).find('option').each(function() {
				this.innerHTML = this.innerHTML.replace('Output return: ', '');
			});
			$(selectedInput).find(':selected').prepend('Output return: ');

			if ($(e.target).val() === 'New') {
				$(e.target).addClass('d-none');
				siblingEl.removeClass('d-none');
				siblingEl.focus().addClass('new-focus');
			}
		};

		this.populateCustomSelect = (e, siblingEl) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				const newVal = $(e.target).val();

				siblingEl.find('[selected]').removeAttr('selected');
				siblingEl.find(':contains("Output return: ")').each(function(){
					$(this).html($(this).html().split("Output return: ").join(""));
				});
				siblingEl.prepend(
					`<option selected value="${newVal.replace(/\s+/g, '').toLowerCase()}">Output return: ${newVal}</option>`
				);

				$(e.target).val('');

				$(e.target).addClass('d-none new-focus');
				//Output return:
				siblingEl.removeClass('d-none');
			}
		};

		this.initPowerSystemDesigner = () => {
			objects.$el.find('button').on('click', this.validation);
			objects.$formRadio.on('click', this.toggleSelection);

			// Remove validation on field focus todo: make global
			$(document).on('focus keyup', '.form-control' , function() {
				$('.form-control').removeClass('is-invalid');
			});

			// Attach label completion to certain fields on keyup/ change todo: make global
			$(document).on('blur keyup change', '.has-label-completion' , function() {
				let $id = $(this).attr('id');
				let $val = $(this).val().split('V')[0].trim();
				let $label = $('label[for=' + $id + ']');
				let $inputVal = $('label[for=' + $id + '] .input-value');

				if ($(this).hasClass('js-number-input') && $val !== '') {
					$label.removeClass('d-none').addClass('active-bold');
					$inputVal.html($(this).val());
				} else {
					$label.removeClass('active-bold');
					$inputVal.html('');
				}
			});

			// Just set min and max input to the value specified in nom if min and max are null, like
			// the original form does
			objects.$el.find(`input[name="vinNom-i${instanceId}"]`).change(function() {
				let vinMin = objects.$el.find(`input[name="vinMin-i${instanceId}"]`);
				let vinMax = objects.$el.find(`input[name="vinMax-i${instanceId}"]`);

				if (vinMin.val() === '' && vinMax.val() === '') {
					vinMin.val($(this).val());
					vinMax.val($(this).val());
					vinMin.change();
					vinMax.change();
				}
			});

			objects.$numberInputs.on('keypress', utils.psdNumberKeyPress);
		};

		this.setOutputName = (e, customName, selectedInput) => {
			e.preventDefault();
			$(selectedInput).closest('.output-specs').find('span[data-optionalOutputName]').text(customName);
		};
	}

	init() {
		this.initPowerSystemDesigner();
	}
}

export default PowerSystemDesigner;


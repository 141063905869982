import Mark from 'mark.js';

class SearchResults {

	static name() {
		return 'SearchResults';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$loadMoreBtn: $(el).find('[data-load-more]'),
			$inputValue: $('[data-input-value]'),
			$resultsText: $(el).find('.res-txt-val'),
			$searchInput: $(el).find('#search-results-input'),
			$searchIconBtn: $(el).find('.btn[type="submit"]'),
			$results: $(el).find('.search-results-listing'),
		};

		const pageSize = 10;
		let resultsIndex = 0;

		// this.clearVal = () => {
		// 	// objects.$resultsText.hide();
		// 	// objects.$inputValue.hide();
		// 	objects.$searchInput.focus().css('position', 'relative').val('');
		// 	objects.$searchInput.get(0).scrollLeft = objects.$searchInput.get(0).scrollWidth;
		// };

		this.showResults = () => {
			objects.$results.slice(resultsIndex, resultsIndex + 10).removeClass('d-none');
		};


		this.setResultsTxt = () => {
			let inputValW = objects.$inputValue.outerWidth();
			let searchIconBtnW = objects.$searchIconBtn.width();
			let totalInputW = objects.$searchInput.width() - searchIconBtnW;


			if(inputValW + searchIconBtnW + objects.$resultsText.width() + 15 > totalInputW) {
				objects.$inputValue.addClass('ellipsis');
			} else {
				objects.$inputValue.removeClass('ellipsis');
			}
		};

		this.seeMoreResults = () => {
			resultsIndex += pageSize;
			this.showResults();

			if (resultsIndex + pageSize >= objects.$results.length) {
				objects.$loadMoreBtn.addClass('d-none');
			}
		};

		this.initSearchResults = () => {
			this.showResults();
			this.setResultsTxt();
			objects.$loadMoreBtn.on('click', this.seeMoreResults);
			objects.$searchInput.on('click', this.clearVal);
			objects.$resultsText.on('click', this.clearVal);
			objects.$inputValue.on('click', this.clearVal);

			$('.btn-group > .search-toggler').hide();

			let context = document.querySelector('.search-results');
			let instance = new Mark(context);
			instance.mark($('#search-results-input').val(), {"exclude": ['.res-txt-val', '.tabbed-nav a', '.search > *', '.col-lg-9 *', '.col-lg-8 > a', '.category-filter']});

		};
	}

	init() {
		this.initSearchResults();
	}
}

export default SearchResults;

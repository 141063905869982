class PressRoom {
	static name() {
		return 'PressRoom';
	}

	constructor(props, el, $) {

		const objects = {
			$el: $(el),
			$loadMoreBtn: $(el).find('[data-load-more]')
		};

		const totalResults = objects.$el.find('.article-listing').length;
		const pageSize = objects.$loadMoreBtn.data('load');

		let displayedResults = 0;

		this.loadMore = () => {
			if (displayedResults >= totalResults) {
				objects.$loadMoreBtn.addClass('d-none');

				return;
			}

			let toShow = objects.$el.find('.article-listing.d-none').slice(0, pageSize);
			toShow.removeClass('d-none');
			displayedResults += toShow.length;

			if (displayedResults >= totalResults) {
				objects.$loadMoreBtn.addClass('d-none');
			}
		};

		this.initPressRoom = () => {
			this.loadMore();
			objects.$loadMoreBtn.on('click', this.loadMore);
		};
	}

	init() {
		this.initPressRoom();
	}
}

export default PressRoom;

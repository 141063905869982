class DatasheetFinder {

	static name() {
		return 'DatasheetFinder';
	}

	constructor(props, el, $) {

		const objects = {
			$input: $(el).find('#content-search'),
			$form: $(el),
			$errors: $(el).find('#content-search-error'),
			$searchInput: $(el).find('.form-control')
		};

		this.findDatasheet = (productKey) => {
			objects.$errors.hide();
			objects.$searchInput.removeClass('is-invalid');

			$.post(props.apiUrl, {
				productKey: productKey,
				jcrReturnContentType: 'json'
			}, function(resp) {
				if (resp == null || resp.length === 0) {
					objects.$errors.show();
					objects.$searchInput.addClass('is-invalid');
				}

				else {
					window.open(resp.datasheetUrl);
				}
			});
		};

		this.initDatasheetFinder = () => {
			let that = this;

			objects.$form.submit(function(e) {
				e.preventDefault();
				that.findDatasheet(objects.$input.val());
			});
		};
	}

	init() {
		this.initDatasheetFinder();
	}
}

export default DatasheetFinder;

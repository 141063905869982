class CustomForm {

	static name() {
		return 'CustomForm';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$countrySelects: $(el).find('.custom-select.country'),
			$stateSelects: $(el).find('.custom-select.state'),
			$stateToggle: $(el).find('.state-toggle'),
			$customCheckList: $(el).find('.custom-checklist'),
			$customChecks: $(el).find('.custom-check'),
			$successMessage: $(el).siblings('.success-message'),
			$errorMessage: $(el).siblings('.error-message'),
			$phone: $(el).find('input[name="phone"]'),
		};

		const redirectUrl = objects.$el.data('redirect');

		let utils = new Utils();

		this.validationSuccessful = () => {
			objects.$errorMessage.addClass('d-none');

			// $.ajax({
			// 	type: 'POST',
			// 	url: objects.$el.attr('action'),
			// 	data: objects.$el.serialize(),
			// 	success: this.handleSuccess,
			// 	error: this.handleError,
			// });
			objects.$el.off('submit'); // Remove validation check or else we'll end up in an infinite loop
			objects.$el.submit(); // Submit the form
		};

		this.handleError = () => {
			objects.$el.hide();
			objects.$errorMessage.removeClass('d-none');
		};

		this.handleSuccess = () => {
			if (typeof(redirectUrl) !== 'undefined') {
				window.location.href = redirectUrl;
			}

			else {
				objects.$el.hide();
				objects.$successMessage.show();
			}
		};

		this.customChecklistClick = (e) => {
			let $target = $(e.target);
			let name = $target.attr('name');

			// checkedItems includes the one we just checked
			let $checkedItems = $(el).find(`.custom-check:checked[name="${name}"]`);

			// Do we support multiple choices?
			let multiple = $target.closest('.custom-checklist').data('multiple');
			multiple = typeof (multiple) !== 'undefined';

			// If we just checked the box
			let checked = $target.prop('checked');

			// If we don't support multiple choice and the user is trying to check an item
			if (!multiple && checked && $checkedItems.length >= 2) {
				$checkedItems.prop('checked', false);
				$target.prop('checked', true);
			}
		};

		this.customValidate = () => {
			let valid = true;

			objects.$el.find('.custom-field[required]').each(function() {
				let $customField = $(this);

				if ($customField.hasClass('custom-checklist')) {
					let hasChoice = false;

					$customField.find('.custom-check').each(function() {
						let $checkbox = $(this);

						if ($checkbox.prop("checked")) {
							hasChoice = true;
						}
					});

					if (!hasChoice) {
						$customField.addClass('custom-invalid');
						valid = false;
					}
				}
			});

			return valid;
		};

		this.initCustomForm = () => {
			objects.$countrySelects.change(function() {
				objects.$stateSelects.addClass('d-none').prop("disabled", true);

				let $select = objects.$el.find(`.custom-select.state[data-key="${$(this).val()}"]`);
				if($select.length == 0){
					$select = objects.$el.find('.custom-select.state.na');
					$select.prop("disabled", false);
					objects.$stateToggle.addClass('d-none');
				}else{
					$select.removeClass('d-none').prop("disabled", false);
					objects.$stateToggle.removeClass('d-none');
				}
				$select.siblings('label').attr('for', $select.attr('id'));
			});

			objects.$customChecks.click(this.customChecklistClick);

			objects.$phone.on('keypress', utils.telephoneKeyPress);

			utils.submitForm(objects.$el, this.validationSuccessful, this.customValidate);

			objects.$customCheckList.each(function() {
				if ($(this)[0].hasAttribute('required')) {
					$(this).find('.custom-check').prop('required', true);
				}
			});
		};
	}

	init() {
		this.initCustomForm();
	}
}

export default CustomForm;

class LocationSwitcher {

	static name() {
		return 'LocationSwitcher';
	}

	constructor(props, el, $) {
		const objects = {
			$el: $(el),
			$regionDropdown: $(el).find('[data-regions]'),
			$regionLabel: $(el).find('label[for="country-select"]'),
			$countryLabel: $(el).find('label[for="province-select"]'),
			$countryDropdown: $(el).find('[data-countries]'),
			$firstDivisionLabel: $(el).find('label[for="first-division-select"]'),
			$firstDivisionDropdown: $(el).find('[data-first-divisions]'),
			$toggle: $(el).find('[data-toggle]'),
			$currentLocation: $(el).find('[data-location]')
		};

		this.countries = [];
		this.regions = [];

		const that = this;

		this.loadRegions = () => {
			$.get(props.actionUrl, function(resp) {
				objects.$regionDropdown.find('option').remove();
				objects.$regionDropdown.append($(`<option value>${resp.label}</option>`));

				resp.items = resp.items.sort((a, b) => a.label.localeCompare(b.label));

				resp.items.forEach(function(region) {
					objects.$regionDropdown.append($(`<option value="${region.key}">${region.label}</option>`));
				});

				objects.$regionLabel.html(resp.label);

				that.regions = resp.items;
			});
		};

		this.loadCountries = (region) => {
			let that = this;

			$.get(props.actionUrl + '?region=' + region, function(resp) {
				objects.$countryDropdown.find('option').remove();
				objects.$countryDropdown.append($(`<option value>${resp.label}</option>`));

				resp.items = resp.items.sort((a, b) => that.sortJP(a,b));

				resp.items.forEach(function(country) {
					objects.$countryDropdown.append($(`<option value="${country.key}">${country.label}</option>`));
				});

				objects.$countryLabel.html(resp.label);

				that.countries = resp.items;
			});
		};

		this.loadFirstLevelDivisions = (region, country) => {
			let that = this;

			$.get(props.actionUrl + '?region=' + region + '&country=' + country, function(resp) {
				objects.$firstDivisionDropdown.find('option').remove();
				objects.$firstDivisionDropdown.append($(`<option value>${resp.label}</option>`));

				resp.items = resp.items.sort((a, b) => that.sortHongKong(a,b));

				resp.items.forEach(function(division) {
					objects.$firstDivisionDropdown.append($(`<option value="${division.key}">${division.label}</option>`));
				});

				objects.$firstDivisionLabel.html(resp.label);
			});
		};

		this.sortHongKong = (a, b) => {
			// This is a hack to make Hong Kong show up first in the sorting order for First Level Divisions
			if(a.label !== b.label) {
				if('Hong Kong' === a.label || a.label === '\u9999\u6E2F') {
					return -1;
				}
				else if('Hong Kong' === b.label || b.label === '\u9999\u6E2F') {
					return 1;
				}
			}
			return a.label.localeCompare(b.label);
		};

		this.sortJP = (a, b) => {
			// This is a hack to make Hong Kong show up first in the sorting order for First Level Divisions
			if(a.label !== b.label) {
				// This is a hack for Japanese ordering of Asia/Pacific countries
				let jpSortArray = ['\u65E5\u672C','\u4E2D\u56FD','\u97D3\u56FD','\u30B7\u30F3\u30AC\u30DD\u30FC\u30EB','\u53F0\u6E7E','\u30A4\u30F3\u30C9','\u30A4\u30F3\u30C9\u30CD\u30B7\u30A2','\u30BF\u30A4','\u30D5\u30A3\u30EA\u30D4\u30F3','\u30D9\u30C8\u30CA\u30E0','\u30DE\u30EC\u30FC\u30B7\u30A2','\u30AA\u30FC\u30B9\u30C8\u30E9\u30EA\u30A2','\u30CB\u30E5\u30FC\u30B8\u30FC\u30E9\u30F3\u30C9'];
				if(jpSortArray.includes(a.label) && jpSortArray.includes(b.label)) {
					return jpSortArray.indexOf(a.label) - jpSortArray.indexOf(b.label);
				}
				else {
					return a.label.localeCompare(b.label);
				}
			}
			return a.label.localeCompare(b.label);
		};

		this.initLocationSwitcher = () => {
			let that = this;

			objects.$regionDropdown.change(function() {
				objects.$countryDropdown.addClass('d-none');
				objects.$firstDivisionDropdown.addClass('d-none');

				let region = $(this).val();

				if (region == null || region.trim().length <= 0) {
					objects.$countryDropdown.addClass('d-none');

					return;
				}

				const regionObj = that.regions.find(c => c.key === region);

				if (regionObj.areaOverride != null) {
					document.dispatchEvent(new CustomEvent('locationUpdate', {
						detail: {
							regionKey: objects.$regionDropdown.val(),
							regionValue: objects.$regionDropdown.find('option:selected').text(),
						}
					}));
				} else {
					objects.$countryDropdown.removeClass('d-none');
					that.loadCountries(region);
				}
			});

			objects.$countryDropdown.change(function() {
				objects.$firstDivisionDropdown.addClass('d-none');

				let area = $(this).val();

				if (area == null || area.trim().length <= 0) {
					return;
				}

				let region = objects.$regionDropdown.val();
				if (region == null || region.trim().length <= 0) {
					return;
				}

				const country = that.countries.find(c => c.key === area);

				if (country == null) {
					return;
				}

				if (!country.children) {
					document.dispatchEvent(new CustomEvent('locationUpdate', {
						detail: {
							regionKey: objects.$regionDropdown.val(),
							regionValue: objects.$regionDropdown.find('option:selected').text(),
							countryKey: objects.$countryDropdown.val(),
							countryValue: objects.$countryDropdown.find('option:selected').text()
						}
					}));
				} else {
					objects.$firstDivisionDropdown.removeClass('d-none');
					that.loadFirstLevelDivisions(region, area);
				}
			});

			objects.$firstDivisionDropdown.change(function() {
				document.dispatchEvent(new CustomEvent('locationUpdate', {
					detail: {
						regionKey: objects.$regionDropdown.val(),
						regionValue: objects.$regionDropdown.find('option:selected').text(),
						countryKey: objects.$countryDropdown.val(),
						countryValue: objects.$countryDropdown.find('option:selected').text(),
						firstLevelDivisionKey: objects.$firstDivisionDropdown.val(),
						firstLevelDivisionValue: objects.$firstDivisionDropdown.find('option:selected').text()
					}
				}));
			});

			objects.$toggle.click(function() {
				if (objects.$regionDropdown.hasClass('d-none')) {
					objects.$regionDropdown.removeClass('d-none');
					that.loadRegions();
				}
			});

			document.addEventListener('location', function(e) {
				if (e.detail.data.siteAreaKey != null) {
					if (e.detail.data.siteAreaValue != null) {
						objects.$currentLocation.html(`${e.detail.data.siteAreaValue}, ${e.detail.data.siteRegionValue}`);
					} else {
						objects.$currentLocation.html(`${e.detail.data.siteRegionValue}`);
					}
				}

				// Elements annotated with data-location-reload signify that we want to reload the page
				// after our location changes
				$('[data-location-reload]').each(function() {
					if (e.detail.data.updated) {
						window.location.reload();
					}
				});
			});
		};
	}

	init() {
		this.initLocationSwitcher();
	}
}

export default LocationSwitcher;

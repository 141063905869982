class HeroSliderEdit {

	static name() {
		return 'HeroSliderEdit';
	}

	constructor(props, el, $) {
		// Functions for edit mode carousel dropdown
		const objects = {
			$carouselId: $(el).find('.dropdown-toggle').data('carousel-identifier')
		};

		this.selectSliderPanel = (carouselId, slideId) => {
			this.hideStoryEditControls(carouselId);
			this.showSlide($(".slide-" + slideId));
		};

		this.showSlide = (id) => {
			$(id).closest(".jahia-template-gxt").show();
		};

		this.hideStoryEditControls = (carouselId) => {
			$(".carousel-" + carouselId + " .carousel-slides .jahia-template-gxt").hide();
		};

		this.disableLazyLoad = () => {
			$('img').each(function () {
				let srcUrl = $(this).attr('data-src');

				if ($(this).attr('data-src')) {
					$(this).attr('src', srcUrl);
				}
			});
		};

		this.injectIcons = () => {
			const xhr = new XMLHttpRequest();
			let $bundle = $('#icon-bundle');
			let $path = $bundle.data('path') || '../img';
			xhr.onload = function (res) {
				$bundle.html(res.currentTarget.responseText);
			};
			xhr.open('get', $path + '/icons.svg', true);
			xhr.send();
		};

		this.initHeroSliderEdit = () => {
			$(window).on('load', (e) => {
				this.injectIcons();
				this.disableLazyLoad();
				this.hideStoryEditControls(objects.$carouselId);
				this.showSlide($(".carousel-" + objects.$carouselId + " .carousel-slides .slide").first());
			});

			//bind slide selection
			$(document).on('click', 'a.slide-select', (event)=>{
				let slideId = $(event.target).data('slide-id');
				this.selectSliderPanel(objects.$carouselId, slideId);
			});
		};
	}

	init() {
		this.initHeroSliderEdit();
	}
}

export default HeroSliderEdit;

class HeroBanner {

	static name() {
		return 'HeroBanner';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$video: $(el).find('video')
		};

		let utils = new Utils();

		this.setVideoSrc = () => {

			const desktopSrc = objects.$video.data('desktop-src'),
				mobileSrc = objects.$video.data('mobile-src');

			if((utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm')) {
				objects.$video.html('<source type="video/mp4" src="' + mobileSrc + '" />');
			} else {
				objects.$video.html('<source type="video/mp4" src="' + desktopSrc + '" />');
			}
		};

		this.initHeroBanner = () => {

			if(objects.$video.length > 0) {
				$(window).on('load', (e) => {
					this.setVideoSrc();
				});

				$(window).on('resize', (e) => {
					this.setVideoSrc();
				});
			}
		};

	}

	init() {
		this.initHeroBanner();
	}


}

export default HeroBanner;

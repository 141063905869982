class FilterDropdown {

	static name() {
		return 'FilterDropdown';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$primaryDropdown: $(el).find('select.primary-dropdown'),
			$secondaryDropdown: $(el).find('select.secondary-dropdown'),
			$clearAllFiltersBtn : $(el).find('.clear-all-filter.custom-filter')
		};

		this.initFilterDropdown = () => {
			objects.$primaryDropdown.on('change', (e)=>{
				if($(e.target).val()!= null && $(e.target).val()!= ""){
					$(".clear-all-filter.custom-filter").removeClass("d-none");
					if($(e.target).children("option:selected").hasClass("has-secondary")){
						$('.secondary-dropdown').addClass("d-none");
						$('#filter-dropdown-secondary-'+$(e.target).val()).removeClass("d-none");
					}else{
						$(".filter-dropdown-option").addClass("d-none");
						$(".filter-dropdown-option."+$(e.target).val()).removeClass("d-none");
					}
				}else{
					$(".filter-dropdown-option").removeClass("d-none");
					$(".clear-all-filter.custom-filter").addClass("d-none");
				}
			});

			objects.$secondaryDropdown.on('change', (e)=>{
				if($(e.target).val()!= null && $(e.target).val()!= ""){
					let $section = $(".filter-dropdown-option."+$(e.target).val());

					$(".filter-dropdown-option").addClass("d-none");
					$section.removeClass("d-none");
					$section.parents('.filter-dropdown-option').removeClass("d-none");
				}else{
					$(".filter-dropdown-option").removeClass("d-none");
					$(".clear-all-filter.custom-filter").addClass("d-none");
				}
			});

			objects.$clearAllFiltersBtn.on('click', (e)=>{
				objects.$primaryDropdown.val(null);
				objects.$secondaryDropdown.val(null);
				$(".filter-dropdown-option").removeClass("d-none");
				$(e.target).addClass("d-none");
			});
		};
	}
	init() {
		this.initFilterDropdown();
	}

}
export default FilterDropdown;

'use strict';


import ProductOptionSelection from './ProductOptionSelection';

class ChartUtil {

	static name() {
		return 'ChartUtil';
	}


	constructor(props, el, $, Utils) {

		this.initChartUtil = () => { };


		window.chartColors = {
			red: 'rgb(255, 99, 132)',
			orange: 'rgb(255, 159, 64)',
			yellow: 'rgb(255, 205, 86)',
			green: 'rgb(137, 178, 35)',
			blue: 'rgb(0, 134, 197)',
			purple: 'rgb(153, 102, 255)',
			grey: 'rgb(201, 203, 207)',
			black: 'rgb(0, 0, 0)'
		};

		((glob) => {

			let Months = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			];

			let COLORS = [
				'#4dc9f6',
				'#f67019',
				'#f53794',
				'#537bc4',
				'#acc236',
				'#166a8f',
				'#00a950',
				'#58595b',
				'#8549ba'
			];

			let Samples = glob.Samples || (glob.Samples = {});
			let Color = glob.Color;

			Samples.utils = {

				// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
				srand: function (seed) {
					this._seed = seed;
				},

				rand: (min, max) => {
					//rand: function (min, max) {
					let seed = this._seed;
					min = min === undefined ? 0 : min;
					max = max === undefined ? 1 : max;
					this._seed = (seed * 9301 + 49297) % 233280;
					return min + (this._seed / 233280) * (max - min);
				},

				numbers: (config) => {
					//numbers: function (config) {
					let cfg = config || {};
					let min = cfg.min || 0;
					let max = cfg.max || 1;
					let from = cfg.from || [];
					let count = cfg.count || 8;
					let decimals = cfg.decimals || 8;
					let continuity = cfg.continuity || 1;
					let dfactor = Math.pow(10, decimals) || 0;
					let data = [];
					let i, value;

					for (i = 0; i < count; ++i) {
						value = (from[i] || 0) + this.rand(min, max);
						if (this.rand() <= continuity) {
							data.push(Math.round(dfactor * value) / dfactor);
						} else {
							data.push(null);
						}
					}

					return data;
				},

				labels: function (config) {
					let cfg = config || {};
					let min = cfg.min || 0;
					let max = cfg.max || 100;
					let count = cfg.count || 8;
					let step = (max - min) / count;
					let decimals = cfg.decimals || 8;
					let dfactor = Math.pow(10, decimals) || 0;
					let prefix = cfg.prefix || '';
					let values = [];
					let i;

					for (i = min; i < max; i += step) {
						values.push(prefix + Math.round(dfactor * i) / dfactor);
					}

					return values;
				},

				months: function (config) {
					let cfg = config || {};
					let count = cfg.count || 12;
					let section = cfg.section;
					let values = [];
					let i, value;

					for (i = 0; i < count; ++i) {
						value = Months[Math.ceil(i) % 12];
						values.push(value.substring(0, section));
					}

					return values;
				},

				color: function (index) {
					return COLORS[index % COLORS.length];
				},

				transparentize: function (color, opacity) {
					let alpha = opacity === undefined ? 0.5 : 1 - opacity;
					return Color(color).alpha(alpha).rgbString();
				}
			};

			// DEPRECATED
			window.randomScalingFactor = function () {
				return Math.round(Samples.utils.rand(-100, 100));
			};

			// INITIALIZATION
			Samples.utils.srand(Date.now());
		})(this);

	}  //constructor

	init()
	{
		this.initChartUtil();
	}
}
export default ChartUtil;

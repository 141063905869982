import objectFitImages from 'object-fit-images';
// import intlTelInput from 'intl-tel-input';
import picturefill from 'picturefill';
import LazyLoad from 'vanilla-lazyload';
import 'jquery-mask-plugin';
import utilsScript from '../node_modules/intl-tel-input/build/js/utils';

class Main {

	constructor($, modules, Utils) {

		let _modules = modules.reduce((obj, x) => {
			obj[x.name()] = x;
			return obj;
		}, Object.create(null));

		this.instantiateModules = (selector = $('body')) => {
			let _utils = new Utils();

			selector.find('[data-module]').each((i,el) => {
				let _module = $(el)[0].dataset.module;
				if (_modules[_module]) {
					let _props = {};
					if ($(el)[0].dataset.props) {
						try {
							_props = JSON.parse($(el)[0].dataset.props);
						} catch (err) {
							console.error(err);
						}
					}
					let instance = new _modules[_module](_props, $(el)[0], $, Utils);
					if (_props.init !== false) {
						instance.init();
					}
					_utils.listeners(instance);
				}
			});
		};

		/**
		 * injectIcons()
		 * Loads icon bundle file via http request for page injection
		 */
		this.injectIcons = () => {
			const xhr = new XMLHttpRequest();
			let $bundle = $('#icon-bundle');
			let $path = $bundle.data('path') || '../img';
			xhr.onload = (res) => {
				$bundle.html(res.currentTarget.responseText);
			};
			xhr.open('get', `${$path}/icons.svg`, true);
			xhr.send();
		};

		this.initUtils = () => {
			let _utils = new Utils();
			_utils.setTooltips();
		};

		this.initinsertFormErrorContainers = () => {
			let _utils = new Utils();
			_utils.insertFormErrorContainers();
		};

		this.initHeight = () => {
			let _utils = new Utils();
			_utils.initEqualHeight();
		};

		this.initAutoResizeTextarea = () => {
			const textarea = document.querySelector('textarea');

			if (textarea == null) {
				return;
			}

			textarea.addEventListener('keydown', autosize);

			function autosize(){
				let el = this;

				setTimeout(function(){
					el.style.cssText = 'height:auto;';
					el.style.cssText = 'height:' + el.scrollHeight + 'px';
				},0);
			}

		};

		this.detectTabbing = () => {
			function handleFirstTab(e) {
				if (e.keyCode === 9) {
					document.body.classList.add('user-is-tabbing');
					window.removeEventListener('keydown', handleFirstTab);
				}
			}

			window.addEventListener('keydown', handleFirstTab);
		};

		this.setSelectActive = () => {
			function setActive(e) {
				const selectedOption = $(e.target).find('option:selected');
				if (selectedOption.val().length > 0) {
					$(e.target).addClass('selected');
				} else {
					$(e.target).removeClass('selected');
				}
			}
			$('select').on('change', setActive);
		};

		// this.initPhoneFormat = () => {
		//	$('[type="tel"]').each(function() {
		//
		//		// Initiate intel tel input plugin
		//		const thisInput = $(this);
		//
		//		const iti = intlTelInput($(this)[0], {
		//			preferredCountries: ["us", 'cn', 'jp'],
		//			utilsScript: utilsScript + '.js',
		//			autoPlaceholder: 'aggressive'
		//		});
		//
		//		// jQuery mask plugin
		//		// Only formatting for US and China for now
		//		function maskNumber() {
		//			let placeholderMask = thisInput.attr('placeholder');
		//			let newPH = placeholderMask.replace(/[0-9]/g, 'X');
		//			thisInput.attr('placeholder', newPH);
		//
		//			thisInput.mask(placeholderMask.replace(/[0-9]/g, '0'));
		//			//maxLength creates conflict between intlPhone and mask plugin
		//			thisInput.removeAttr('maxLength');
		//		}
		//
		//		$(this)[0].addEventListener("countrychange", function() {
		//			maskNumber();
		//		});
		//
		//
		//		$(this).on('change keyup', function() {
		//			let placeholderMask = thisInput.attr('placeholder');
		//			let newPH = placeholderMask.replace(/[0-9]/g, 'X');
		//			thisInput.attr('placeholder', newPH);
		//		});
		//
		//		maskNumber();
		//	});
		// };

		this.initCheckLanguage = () => {
			$(document).ready(function() {
				let urlLang = window.location.href;
				if ((urlLang.indexOf('zh-cn') > 0) || (urlLang.indexOf('zh_CN') > 0)) {
					$('html').attr('lang', 'zh-Hans');
				} else if ((urlLang.indexOf('zh-tw') > 0) || (urlLang.indexOf('zh_TW') > 0)) {
					$('html').attr('lang', 'zh-Hant');
				} else if ((urlLang.indexOf('ja-jp') > 0) || (urlLang.indexOf('ja_JP') > 0)) {
					$('html').attr('lang', 'ja');
				}
			});
		};
		this.isIE = () => {
			let ua = navigator.userAgent;
			/* MSIE used to detect old browsers and Trident used to newer ones*/
			let is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

			return is_ie;
		};

		this.callback_enter = function (element) {
			if (element.getAttribute('data-src').endsWith('.svg')) {
				element.setAttribute('src', element.getAttribute('data-src'));
				element.removeAttribute('class');
				element.setAttribute('style', 'background-position: center; background-image: url("'+element.getAttribute('data-src')+'"); background-repeat: no-repeat; background-size: cover; background-origin: content-box;');
				return false;
			}
		};


		this.lazyLoadImages = () => {
			if (!this.isIE()){
				let myLazyLoad = new LazyLoad({
					elements_selector: '.lazy'
				});
			} else {
				let myLazyLoad = new LazyLoad({
					elements_selector: '.lazy',
					callback_enter: this.callback_enter,
					cancel_on_exit: false
				});
			}
		};

		this.detectOS = () => {

			let _utils = new Utils();
			let OS = _utils.getOS();

			if(OS === 'Mac' || OS === 'Windows' || OS === 'Linux') {
				$('body').addClass('zoom-enabled');
			}
		};
	}

	init() {
		this.instantiateModules();
		this.initUtils();
		this.injectIcons();
		objectFitImages();
		this.detectTabbing();
		this.setSelectActive();
		// this.initPhoneFormat();
		this.initCheckLanguage();
		this.initHeight();
		picturefill();
		this.lazyLoadImages();
		this.initHeight();
		this.initAutoResizeTextarea();
		this.detectOS();
		this.initinsertFormErrorContainers();
	}
}

export default Main;

class FAQ {

	//TODO may want this to be FAQList and just call readMore on any child faqs if has

	static name() {
		return 'FAQ';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			more: $(el).data('more'),
			less: $(el).data('less'),
			linkClass: 'blue',
			fullTextClass: '.full-text',
			shortTextClass: '.short-text',
			readMoreClass: '.read-more'
		};

		this.readMore = () => {
			let $fullText = $(objects.fullTextClass, objects.$el);
			let $shortText = $(objects.shortTextClass, objects.$el);
			let $readMore = $(objects.readMoreClass, $shortText);
			if ($shortText.length) {
				$fullText.hide();
				let $moreLink = $('<a class="' + objects.linkClass + '">' + objects.more + '</a> ');
				let $lessLink = $('<a class="' + objects.linkClass + '">' + objects.less + '</a> ');
				$fullText.append($lessLink);
				$readMore.append($moreLink);
				$moreLink.click(function () {
					$shortText.hide();
					$fullText.show();
				});
				$lessLink.click(function () {
					$fullText.hide();
					$shortText.show();
				});
			}
		};

		this.initFAQ = () => {
			console.log('init FAQ');
			this.readMore();
		};
	}


	init() {
		this.initFAQ();
	}

}

export default FAQ;

class ValidateAndExecuteChart {

	static name() {
		return 'ValidateAndExecuteChart';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el:$(el),
			$addToCartBtn:$(el).find('.js-chart-button'),
			$chartInputQty:$(el).find('.js-chart-input-qty'),
			$addToCartTxt:$(el).find('.js-chart-button-add'),
			$maxQtyMessage:$(el).find('#js-max-qty-feedback'),
			$minQtyMessage:$(el).find('#js-min-qty-feedback'),
			$usrQtyInput:$(el).find('#'+$(el).data('identifier')+'-userInputQty'),
			$qtyPrice:$(el).find('#'+$(el).data('identifier')+'-price'),
			$identifier:$(el).data('identifier'),
			$productId:$(el).data('product-id'),
			$pricingServiceUrl:$(el).data('pricing-service-url'),
			$purchaseUrl:$(el).data('purchase-url'),
			$familyId:$(el).data('product-family'),
			$trueClientIp:$(el).data('trueClientIp'),
			$minItems:$(el).data('min-qty'),
			$maxItems:$(el).data('max-qty'),
			$maxItemsPlaceholder:$(el).data('max-qty-placeholder'),
			$minItemsPlaceholder:$(el).data('min-qty-placeholder'),
			$pricingSteps:$(el).data('pricing-steps')

		};

		let utils = new Utils();

		this.validateChart = (e, checkMin) => {
			// Validate Qty and reset input box if needed
			let elementQty = $('#' +objects.$identifier+'-quantityInput');
			let pricingQty = elementQty.val();
			let pricingQtyFix = pricingQty;
			let minQty = objects.$minItems;


			// verify quantity is a number between 1-10000
			if(pricingQty === '') {
				return 0;
			}
			if(isNaN(pricingQty)) {
				pricingQtyFix = '';
			}
			else if (pricingQty < 1) {
				pricingQtyFix = '';
				objects.$maxQtyMessage.addClass('d-none');

			}
			else if (pricingQty > objects.$maxItems && checkMin) {
				pricingQtyFix = '';

				objects.$maxQtyMessage.addClass('is-invalid');
				objects.$maxQtyMessage.show();
				$('#js-min-qty-feedback').hide();
				$('#js-max-qty-feedback').show();

				objects.$chartInputQty.attr('placeholder',objects.$maxItemsPlaceholder);
				objects.$chartInputQty.addClass('is-invalid');
			}
			else {
				if (pricingQty < objects.$minItems && checkMin) {
					pricingQtyFix = '';

					objects.$minQtyMessage.addClass('is-invalid');
					objects.$minQtyMessage.show();
					$('#js-invalid-qty-feedback').show();
					$('#js-max-qty-feedback').hide();
					$('#js-min-qty-feedback').show();

					objects.$chartInputQty.attr('placeholder',objects.$minItemsPlaceholder);
					objects.$chartInputQty.addClass('is-invalid');
				}
				else {
					//objects.$maxQtyMessage.addClass('d-none');
					objects.$minQtyMessage.removeClass('is-invalid');
					$('#js-min-qty-feedback').hide();
					$('#js-max-qty-feedback').hide();
				}
			}

			if(pricingQtyFix !== pricingQty) {
				// Update the input box
				elementQty.val(pricingQtyFix);

				// Set the response to the 'fixed' qty
				pricingQty = pricingQtyFix;
			}

			return pricingQty;
		};

		this.handleQuantityInputChange = (e) => {

			let pricingQty = this.validateChart(e, false);
		};

		this.checkForEnter = (event) => {

			let response = false;
			event.preventDefault();

			let key = event.keyCode || event.which;
			if (key === 13) {
				response = true;
			}

			return response;
		};

		this.handleButtonAction = (e) => {
			// Add to cart
			let orderquantity = this.validateChart(e, true);
			if(orderquantity === '') {
				return 0;
			}
			else {
				window.open(objects.$purchaseUrl + encodeURIComponent('[' + orderquantity + ']'), '_blank');
			}

		};



		this.validate = (evt) => {

			//13=enter
			let theEvent = evt || window.event;
			let key = theEvent.keyCode || theEvent.which;
			let keyChar = String.fromCharCode( key );
			let regex = /[0-9]|\./;
			//8=backspace, 46=delete, 37=left arrow, 39=right arrow
			if( !regex.test(keyChar) && key !== 8 && key !== 46
				&& key !== 37 && key !== 39 ) {
				theEvent.returnValue = false;
				if(theEvent.preventDefault) theEvent.preventDefault();
			}
		};

		this.initValidateAndExecuteChart = () => {
			//this.buildChart(objects.$el);
//			objects.$chartInputQty.on('change', this.handleQuantityInputChange);

//			objects.$chartInputQty.on('keyup', (e) => {
//				this.handleQuantityInputChange();

//				if(e.keyCode == 13) {
//					this.handleButtonAction(objects.$el);
//				}
//			});

			objects.$addToCartBtn.on('click ', () => {
				this.handleButtonAction(objects.$el);
			});

			objects.$addToCartBtn.on('keypress', (e) => {
				if(this.checkForEnter(e)) {
					this.handleButtonAction(objects.$el);
				}
			});

		};

	}  //constructor

	init() {
		this.initValidateAndExecuteChart();
	}
}

export default ValidateAndExecuteChart;


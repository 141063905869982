/** External Dependencies **/
import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class ReactSample extends PureComponent {

	static name() {
		return 'ReactSample';
	}

	static propTypes = {
		name: PropTypes.string,
		amount: PropTypes.number
	};

	static defaultProps = {
		name: 'Default',
		amount: 1
	};

	constructor(props, el) {
		super(props);
		this.el = el;
	}

	state = {
		counter: 0
	};

	componentDidMount() {
		this.interval = setInterval(() => {
			let _counter = this.state.counter;
			this.setState({counter: _counter + this.props.amount});
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	init() {
		ReactDOM.render(<ReactSample {...this.props} />, this.el);
	}

	render() {
		return (
			<div>
				Hello <span data-name>{this.props.name}</span>: {this.state.counter}
			</div>
		);
	}

}

export default ReactSample;

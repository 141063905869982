import $ from 'jquery';

class Careers {

	static name() {
		return 'Careers';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$selectDepartment: $(el).find('#department-select'),
			$selectLocation: $(el).find('#location-select'),
			$resetJobFilter: $(el).find('#js-resetJobFilter'),
			$seeJobsBtn: $(el).find('#js-btnSeeJobs'),
			$veteranChkBoxes: $(el).find('.js-veteranchkbx'),
			$disabilityChkBoxes: $(el).find('.js-disabilityChkBx'),
			$selectCountry: $(el).find('#country'),
			$submitAppBtn: $(el).find('#js-appSubmit'),
			$jobApplicationForm: $(el).find('#jobApplicationForm'),
			$errorContainer: $(el).find('.form-error'),
			$validationMessages: $(el).find('.validation-message'),
			$submitErrors: $(el).find('.submit-error'),
			$filenameLabel: $(el).find('span[class="file-name"]'),
			$chooseFileButton: $(el).find('button.custom-file-upload'),
			$fileInput: $(el).find('input[name="resume"]'),
			$spinner: $(el).find('.loader'),
			$phone: $(el).find('input[name="phone"]'),
		};

		let utils = new Utils();

		this.filterDropdownChange = () => {
			let baseUrl = window.location.href.split('?')[0];
			baseUrl = baseUrl.replace('#opportunities', '');

			let query = [];

			let departmentVal = objects.$selectDepartment.val();
			let locationVal = objects.$selectLocation.val();

			if (departmentVal !== 'department-default') {
				query.push('selectDepartment=' + encodeURIComponent(departmentVal));
			}

			if (locationVal !== 'location-default') {
				query.push('selectLocation=' + encodeURIComponent(locationVal));
			}

			if (query.length > 0) {
				baseUrl += '?';

				query.forEach(val => baseUrl += val + '&');
				baseUrl = baseUrl.slice(0, -1);
			}

			baseUrl += '#opportunities';

			window.location = baseUrl;
		};

		this.resetJobsFilter = () => {

			let urlNow = window.location.href;
			let baseURL = urlNow.split('?')[0];

			if (!baseURL.endsWith('#opportunities')) {
				baseURL = baseURL + '#opportunities';
			}

			window.location = baseURL;
		};

		this.seeJobsLink = () => {
			this.resetJobsFilter();
		};

		// CareerApplicationForm
		this.countryChange = () => {
			let countryValue = $('#country option:selected').val();

			if ($('#country option:selected').text().indexOf('United States') >= 0) {
				$('#state').parent().show();
				$('#state').show().prop('required', true);
			} else {
				$('#state').parent().hide();
				$('#state').prop('required', false);
			}
		};

		this.job = (e) => {
			e.preventDefault();
			e.stopPropagation();

			document.jobApplicationForm.fileName.value = document.jobApplicationForm.resume.value;
			document.jobApplicationForm.veteranName.value
				= document.jobApplicationForm.disabilityName.value
				= document.jobApplicationForm.firstName.value + ' ' + document.jobApplicationForm.lastName.value;

			let validationOut = '';

			validationOut += this.validateFileField('resume', 'A Resume');
			validationOut += this.validateRequiredDropdown('referralSource', 'Referer');


			validationOut += this.validateTextField('firstName', 'First name');
			validationOut += this.validateTextField('lastName', 'Last name');
			validationOut += this.validateEmailField('email', 'Email Address');
			validationOut += this.validateTextField('phone', 'Phone');
			validationOut += this.validateTextField('address1', 'Address');
			validationOut += this.validateTextField('city', 'City');
			validationOut += this.validateTextField('zip', 'Zip/Postal Code');

			validationOut += this.validateRequiredDropdown('country', 'Country');
			if ($('#country option:selected').text().indexOf('United States') >= 0) {
				validationOut += this.validateRequiredDropdown('state', 'State');
			}

			validationOut += this.validateRequiredDropdown('gender', 'gender');
			validationOut += this.validateRequiredDropdown('race', 'race');

			validationOut += this.validateTextField('veteranName', 'Digital signature');
			validationOut += this.validateDateField('veteranDate', 'Veteran date');
			//validationOut += this.validateCheckBoxField('veteran', 'Veteran declaration ');

			validationOut += this.validateTextField('disabilityName', 'Digital signature');
			validationOut += this.validateTextField('disabilityDate', 'Disability date');
			//validationOut += this.validateCheckBoxField('disabled', 'Disability declaration ');

			if (validationOut !== '') {
				//$('#js-validation-msg').text(validationOut);
				this.showValidationErrors();

				objects.$jobApplicationForm.addClass("was-validated");
				return false;
			} else {
				$('#js_appSubmit').hide();
				objects.$spinner.removeClass('d-none');
				objects.$submitAppBtn.addClass('d-none');

				let that = this;

				$.ajax({
					url: objects.$jobApplicationForm.attr('action'),
					headers: {
						Accept: 'application/json'
					},
					method: 'post',
					data: new FormData(objects.$jobApplicationForm[0]),
					cache: false,
					contentType: false,
					processData: false,
					success: function (data) {
						if (data.location != null) {
							window.location.href = data.location;
						}

						else {
							that.showSubmissionErrors(data.errorKey);
							console.log('show errors!');
						}

						objects.$spinner.addClass('d-none');
						objects.$submitAppBtn.removeClass('d-none');
					}
				});
			}
		};

		this.validateFileField = (fieldName, description) => {
			let $field = $('input[name=' + fieldName + ']');
			let val = $field.val();
			let error = '';
			if (!val) {
				error = description + ' is required.\n';
				$field.addClass('is-invalid');
			} else {
				$field.removeClass('is-invalid');
			}

			return error;
		};

		this.validateEmailField = (fieldName, description) => {
			let $field = $('input[name=' + fieldName + ']');
			let error = '';
			error += utils.validateEmail(document.jobApplicationForm.email);

			if (error.length) {
				error = description + ' is required.\n';
				$field.addClass('is-invalid');
			} else {
				$field.removeClass('is-invalid');
			}
			return error;
		};

		this.validateCheckBoxField = (fieldName, description) => {
			let $fields = $('input[name=' + fieldName + ']');
			let $field = $('input[name=' + fieldName + ']:checked');
			let error = '';

			if (!$field.length) {
				// $('.' + fieldName + '-label').css('background-color', 'yellow');
				error = description + ' is required.\n';
				$fields.addClass('is-invalid');
			} else {
				$fields.removeClass('is-invalid');
			}

			return error;
		};

		this.validateDateField = (fieldName, description) => {

			let $field = $('input[name=' + fieldName + ']');
			let val = $field.val();
			let error = '';
			if (!val || isNaN(Date.parse(val))) {
				// $('input[name=' + fieldName + ']').css('background-color', 'yellow');
				error = description + ' is required.\n';
				$field.addClass('is-invalid');
			} else {
				$field.removeClass('is-invalid');
			}

			return error;
		};

		this.validateTextField = (fieldName, description) => {
			let $field = $('input[name=' + fieldName + ']');
			let val = $field.val();
			let error = '';
			//extra validation for phone number, ensure the pattern is respected
			if (!val || $field.is(':invalid')) {
				error = description + ' is required.\n';
				$field.addClass('is-invalid');
			} else {
				$field.removeClass('is-invalid');
			}

			return error;
		};

		this.validateRequiredDropdown = (fld, description) => {
			let error = '';
			let $field = $('select[name=' + fld + ']');
			let $tfld = $('select[name=' + fld + '] option:selected');

			if (!$tfld.val().length) {
				//fld.style.background = 'Yellow';
				error = 'Please make a selection for ' + description + '.\n';
				$field.addClass('is-invalid');
			} else {
				$field.removeClass('is-invalid');
			}

			return error;
		};

		this.veteranCheckBoxEvent = (e) => {
			let iD_Clicked = $(e.target).attr('id');
			let eVet = document.getElementById('js-veteranChkBx');
			let vetChoices = eVet.getElementsByTagName('input');

			for (let i = 0, len = vetChoices.length; i < len; i++) {
				if (vetChoices[i].type === 'checkbox') {
					let item = vetChoices[i];
					if (iD_Clicked !== item.id) {
						$('#' + item.id).prop('checked', false);
					}
				}
			}
		};

		this.disabilityCheckBoxEvent = (e) => {
			let iD_Clicked = $(e.target).attr('id');
			let eDis = document.getElementById('js-disabilityChkBx');
			let diChoices = eDis.getElementsByTagName('input');

			// assign function to onclick property of each checkbox
			for (let i = 0, len = diChoices.length; i < len; i++) {
				if (diChoices[i].type === 'checkbox') {
					let item = diChoices[i];
					if (iD_Clicked !== item.id) {
						$('#' + item.id).prop('checked', false);
					}
				}
			}
		};

		this.populateDate = () => {
			let now = new Date();

			const day = ("0" + now.getDate()).slice(-2);
			const month = ("0" + (now.getMonth() + 1)).slice(-2);
			const today = (month)+"-"+(day)+"-"+now.getFullYear() ;

			$('[data-date-input]').val(today);
		};

		// Initialize Careers.js
		this.initCareers = () => {

			// listener for options drop down change
			objects.$selectDepartment.on('change', this.filterDropdownChange);

			objects.$selectLocation.on('change', this.filterDropdownChange);

			objects.$phone.on('keypress', utils.telephoneKeyPress);

			// Button Listeners
			objects.$resetJobFilter.on('click', () => {
				this.resetJobsFilter();
			});

			objects.$seeJobsBtn.on('click', () => {
				this.seeJobsLink();
			});

			//Hide the state
			this.countryChange();

			//job Application listeners
			objects.$selectCountry.on('change', () => {
				this.countryChange();
			});

			objects.$submitAppBtn.on('click', (e) => {
				this.job(e);
			});

			objects.$el.find('#veteran-1').on('click', this.veteranCheckBoxEvent);
			objects.$el.find('#veteran-2').on('click', this.veteranCheckBoxEvent);
			objects.$el.find('#veteran-3').on('click', this.veteranCheckBoxEvent);
			objects.$el.find('#veteran-4').on('click', this.veteranCheckBoxEvent);

			objects.$el.find('#disabled-1').on('click', this.disabilityCheckBoxEvent);
			objects.$el.find('#disabled-2').on('click', this.disabilityCheckBoxEvent);
			objects.$el.find('#disabled-3').on('click', this.disabilityCheckBoxEvent);
			objects.$el.find('#disabled-4').on('click', this.disabilityCheckBoxEvent);

			objects.$chooseFileButton.click(function() {
				objects.$filenameLabel.click();
			});

			objects.$fileInput.change(function() {
				let path = $(this).val().split('\\');
				objects.$filenameLabel.html(path[path.length - 1]);
			});

			//apply selected filter styles
			$(window).on('load', function ()  {
				if(objects.$selectDepartment.val() !== "department-default"){
					objects.$selectDepartment.addClass("selected");
				}
				if(objects.$selectLocation.val() !== "location-default"){
					objects.$selectLocation.addClass("selected");
				}
				//move error svg into correct element, international phone plugin breaks styling
				let $phoneField = $('input[type="tel"]');
				$phoneField.parent().siblings('span').insertAfter($phoneField);
			});
		};

		this.showValidationErrors = () => {
			objects.$submitErrors.hide();
			objects.$validationMessages.show();

			let $invalidInputs = objects.$el.find('.form-control:invalid, .custom-select:invalid, .custom-check:invalid');

			$invalidInputs.each(function(index, node) {
				let message;
				if (node.validity.patternMismatch) {
					message = node.dataset.patternError;
				}
				else if (node.validity.valueMissing) {
					message = node.dataset.error;
				}

				$(this).siblings('.invalid-feedback').html(message);
			});

			$('.page-breadcrumb').get(0).scrollIntoView();
		};

		this.showSubmissionErrors = (key) => {
			objects.$validationMessages.hide();
			// objects.$errorContainer.find(`.submit-error.${key}`).removeClass('d-none');
			// objects.$errorContainer.show();
			$('.page-breadcrumb').get(0).scrollIntoView();
		};
	}

	init() {
		this.initCareers();
	}
}

export default Careers;

class PreFooterForm {

	static name() {
		return 'PreFooterForm';
	}

	constructor(props, el, $, Utils) {
		const instance = $(el).data('instance');

		const objects = {
			$el: $(el),
			$successMessage: $(el).siblings('.success-message'),
			$dataSelect: $(el).find('[data-show-content]'),
			$result: $(el).find('.result'),
			$phone: $(el).find(`input[name="phone${instance}"]`),
			$regionDropdown: $(el).find('[data-regions]'),
			$regionLabel: $(el).find(`label[for="region-select${instance}"]`),
			$countryDropdown: $(el).find('[data-countries]'),
			$countryLabel: $(el).find(`label[for="country-select${instance}"]`),
			$firstDivisionDropdown: $(el).find('[data-divisions]'),
			$firstDivisionLabel: $(el).find(`label[for="division-select${instance}"]`)
		};

		this.countries = [];

		let that = this;

		let utils = new Utils();

		this.isSuccess = () => {
			objects.$el.hide();
			objects.$successMessage.show();

			const data = {};

			data[`relation${instance}`] = objects.$el.find(`select[name="relation${instance}"] option:selected`).val();
			data[`firstName${instance}`] = objects.$el.find(`input[name="firstName${instance}"]`).val();
			data[`company${instance}`] = objects.$el.find(`input[name="company${instance}"]`).val();
			data[`phone${instance}`] = objects.$el.find(`input[name="phone${instance}"]`).val();
			data[`email${instance}`] = objects.$el.find(`input[name="email${instance}"]`).val();
			data[`questionOrComment${instance}`] = objects.$el.find(`textarea[name="comments${instance}"]`).val();
			data[`comments${instance}`] = objects.$el.find(`textarea[name="comments${instance}"]`).val();
			data['region'] = objects.$el.find(`[name="region${instance}"]`).val();
			data['country'] = objects.$el.find(`[name="country${instance}"]`).val();
			data['division'] = objects.$el.find(`[name="division${instance}"]`).val();

			$.ajax({
				type: objects.$el.attr('method'),
				url: objects.$el.attr('action'),
				data: data,
				dataType: 'json',
				encode: true
			});
		};

		this.toggleDropdownContent = (e) => {
			objects.$result.hide();
			$('#' + $(e.target).val()).show();
		};

		this.initPreFooterForm = () => {
			objects.$dataSelect.on('change', this.toggleDropdownContent);
			objects.$phone.on('keypress', utils.telephoneKeyPress);

			let that = this;

			objects.$regionDropdown.change(function() {
				let region = $(this).val();

				objects.$countryDropdown.addClass('d-none');
				objects.$countryLabel.addClass('d-none');
				objects.$firstDivisionDropdown.addClass('d-none');
				objects.$firstDivisionLabel.addClass('d-none');

				if (region == null || region.trim().length <= 0) {
					objects.$countryDropdown.addClass('d-none');
					objects.$countryLabel.addClass('d-none');
					objects.$countryDropdown.attr('disabled', true);

					return;
				}

				objects.$countryDropdown.removeClass('d-none');
				objects.$countryLabel.removeClass('d-none');
				objects.$countryDropdown.attr('disabled', false);
				that.loadCountries(region);
			});

			objects.$countryDropdown.change(function() {
				const country = that.countries.find(c => c.key === $(this).val());

				if (country == null) {
					return;
				}

				if (country.children) {
					objects.$firstDivisionDropdown.removeClass('d-none');
					objects.$firstDivisionLabel.removeClass('d-none');
						if (country.key =='unitedStates'){
							objects.$firstDivisionLabel.html('Select state');
							}
						else if (country.key =='canada'){
							objects.$firstDivisionLabel.html('Select province');
							}
						else {
							objects.$firstDivisionLabel.html('Select division');
							}
					objects.$firstDivisionDropdown.attr('disabled', false);
					that.loadFirstLevelDivisions(objects.$regionDropdown.val(), objects.$countryDropdown.val());
				} else {
					objects.$firstDivisionDropdown.addClass('d-none');
					objects.$firstDivisionLabel.addClass('d-none');
					objects.$firstDivisionDropdown.attr('disabled', true);
					objects.$firstDivisionDropdown.val(null);
				}
			});

			this.loadRegions();
			utils.submitForm(objects.$el, this.isSuccess);
		};

		this.loadRegions = () => {
			$.get(props.actionUrl, function(resp) {
				objects.$regionDropdown.find('option:not(":first-child")').remove();

				resp.items = resp.items.sort((a, b) => a.label.localeCompare(b.label));

				resp.items.forEach(function(region) {
					objects.$regionDropdown.append($(`<option value="${region.key}">${region.label}</option>`));
				});
			});
		};

		this.loadCountries = (region) => {
			$.get(props.actionUrl + '?region=' + region, function(resp) {
				objects.$countryDropdown.find('option:not(":first-child")').remove();

				resp.items = resp.items.sort((a, b) => a.label.localeCompare(b.label));

				resp.items.forEach(function(area) {
					objects.$countryDropdown.append($(`<option value="${area.key}">${area.label}</option>`));
				});

				that.countries = resp.items;
			});
		};

		this.loadFirstLevelDivisions = (region, country) => {
			$.get(props.actionUrl + '?region=' + region + '&country=' + country, function(resp) {
				objects.$firstDivisionDropdown.find('option:not(":first-child")').remove();

				resp.items = resp.items.sort((a, b) => a.label.localeCompare(b.label));

				resp.items.forEach(function(division) {
					objects.$firstDivisionDropdown.append($(`<option value="${division.key}">${division.label}</option>`));
				});
			});
		};
	}

	init() {
		this.initPreFooterForm();
	}
}

export default PreFooterForm;

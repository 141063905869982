class PressArticle {

	static name() {
		return 'PressArticle';
	}

	constructor(props, el, $) {
		const objects = {
			$el: $(el),
			$modal: $(el).find('.modal'),
			$picture: $(el).find('picture[data-modal-toggle]'),

		};

		this.initPressArticle = () => {
			objects.$picture.click(function() {
				objects.$modal.modal();
			});
		};
	}

	init() {
		this.initPressArticle();
	}
}

export default PressArticle;

//Product option selection utilities

import $ from 'jquery';    //unused

class ProductOptionSelection {

	static name() {
		return 'ProductOptionSelection';
	}


	constructor(props, el, $, Utils) {

		const objects = {
			$el:$(el),
			$prodDetailDropdown:$(el).find('.productDetailDropdown'),
			$configOptionsSelect:$(el).find('#configOptions select'),
			$configOptionsForm:$(el).find('#configOptionForm'),
			$productKeyField:$(el).find('#product-key-field'),
			$productWSUrl:$(el).data('product-ws-url'),
			$pricingServiceUrl:$(el).data('pricing-service-url'),
			$dataRedirectURL:$(el).data('redirect-url'),
			$dataRedirectURL403:$(el).data('redirect-url-403'),
			$dataConfigCategoryCount:$(el).data('config-category-count'),
			$selectLabel:$(el).data('selectLabel'),
			$errorRedirectScript:$(el).data('error-redirect'),
			$trueClientIp:$(el).data('trueClientIp')
		};

		// The desired action Needs to be finalized.
		// To be used When an error response is returned by the Product Web Service
		this.errorRedirectScript= () => {

			if(objects.$dataRedirectURL)
			{
				window.location = objects.$dataRedirectURL;
			}
			else if(objects.$errorRedirectScript) {
				eval(objects.$errorRedirectScript);
			}
		};

		this.errorRedirectScript();

		let utils = new Utils();
		let selectedId;
		let selectedValue;
		let selectedPartNumber;
		let primaryPartNumber;
		let currentDropdownArray = [];
		let idArray = [];
		let valueArray = [];
		let globalOptions;              // populated once in getData. then re-used
		let configCategoryCount = 0;
		let dependantOptions = [];
		let partNumberMap = new Map();
		let productType = utils.getUrlParameter('productType');
		let productKey = utils.getUrlParameter('productKey');
		let langId = utils.getLangId();
		let updatedURL = window.location.href;
		let errorRedirectURL;
		let firstSelectionMade;
		let selectionSlot;                    //unused
		let specNames = [];                    //unused
		let specValues = [];                    //unused
		let labels = [];                        //unused

		let vicorResponse; // Populated with initial call to requestVicorProductData

		// listener for .productDetailDropdown'.change
		// Called before configOptionsChangeHandler
		this.prodDetailDropdownChangeHandler = () =>{
			selectedValue = objects.$prodDetailDropdown.val();
			selectedId = objects.$prodDetailDropdown.attr('id');

			this.getData();
		};

		// listener for #configOptions select  change
		this.configOptionsChangeHandler = () => {
			// save current selected values
			let selectedValues = Array();
			let xName = '';
			let xVal = '';
			let xText = '';
			let pKey = '';

			$('option[name|="mechanicalOption"]').filter(':selected').each(
				function (index) {

					let dropdownCount = $('option[name|="mechanicalOption"]').length;

					selectedValues[index] = new Array(3);
					selectedValues[index][0] = $(this).parent().attr('name');
					xName = $(this).parent().attr('name');

					selectedValues[index][1] = $(this).val();
					xVal = $(this).val();

					selectedValues[index][2] = $(this).text();
					xText = $(this).text();
					pKey += xVal;
				});


			let selectedPartNumber = partNumberMap.get(pKey);

			// on a three drop down set xVal is the third dropdown value.
			//Needs to be the second drop down value
			this.setDependantOptions(xName, xVal);

			// restore selected options if valid
			for (let oIdx = 0; oIdx < selectedValues.length; oIdx++) {
				$('select[name="' + selectedValues[oIdx][0] + '"]').val(selectedValues[oIdx][1]);
			}

			//legacy for 'Apply>' button
			if (this.isConfigurable()) {
				this.setConfigurable();
			}
			else {
				this.setUnconfigurable();
			}

			objects.$productKeyField.val(selectedPartNumber);
			objects.$configOptionsForm.submit();
		};

		//Creates a redirect URL to go the current product family page
		this.getFamilyPageURL = () => {
			return location.protocol + '//' + location.host + '/' + $('#product-graph').attr('data-product-family');
		};

		this.getOptionData = (dataUrl) => {
			let dataSet;

			// Promise for data web service call
			this.requestVicorProductData({url: dataUrl + '/'+ productKey + '/' + productType + '/' + langId + '/'})
				.then(data => {
					// dataSet = JSON.parse(data);
					return dataSet;
				})
				.catch(error => {
					console.info(error);
				});
		};

		// initialize drop down content & update on select change
		this.getData = () => {

			let currentDropdownArray = [];

			objects.$prodDetailDropdown.each(function() {
				currentDropdownArray.push(this);   // push the whole ,<option ... onto the array
				idArray.push(this.id);             // push the select ID onto the array
				valueArray.push($(this).val());    // selected options    Select... is an empty String  [Never used?]
			});

			objects.$prodDetailDropdown.prop('disabled', true);

			if (!globalOptions) {
				this.requestVicorProductData({url: objects.$productWSUrl + '/'+ productKey + '/' + productType + '/' + langId + '/'})
					.then(data => {
						let optionsJsonObject = JSON.parse(data);
						globalOptions = optionsJsonObject.product.mechanicalOptionCategories.optionList;

						//let options = JSON.parse(JSON.stringify(globalOptions).replaceAll('&#176;', '°').replaceAll('&deg;', '°'));
						let optionString = this.replaceAll(JSON.stringify(globalOptions), '&#176;', '°');
						optionString = this.replaceAll(optionString, '&deg;', '°');
						let options = JSON.parse(optionString);

						this.initProductOptionSelection();

						this.processOptions(options, currentDropdownArray);
					})
					.catch(error => {
						console.info(error);
					});
			}
			else {
				//let options = JSON.parse(JSON.stringify(globalOptions).replaceAll('&#176;', '°').replaceAll('&deg;', '°'));
				let optionString = this.replaceAll(JSON.stringify(globalOptions), '&#176;', '°');
				optionString = this.replaceAll(optionString, '&deg;', '°');
				let options = JSON.parse(optionString);

				this.processOptions(options, currentDropdownArray);
			}

			//Re-enable the select drop downs
			//$('.productDetailDropdown').prop('disabled', false);
		};

		this.processOptions = (options, currentDropdownArray) => {

			// Get current dropdown values
			let currentDropdownOptions = this.getCurrentDropdownSelections(currentDropdownArray);

			// Get the selected part, to fill in values that might not be filled in current dropdowns
			let selectedPart = this.getSelectedPart(options, currentDropdownArray);

			primaryPartNumber = selectedPart.partNumber;
			let fixedSelections = this.getSelections(currentDropdownOptions, selectedPart);
			let updatedDropdown = this.getUpdatedDropdown(options, fixedSelections);

			for (let i = 0; i < updatedDropdown.length; i++) {
				if (updatedDropdown[i].length) {
					let optionElement = $('#' + updatedDropdown[i][0].id).find('option');
					optionElement.remove();
				}

				for (let j = 0; j < updatedDropdown[i].length; j++) {
					if (j === 0) {

						// Causing an extra 'empty' entry without the if
						if (objects.$selectLabel) {
							$('#' + updatedDropdown[i][j].id)
								.append($('<option></option>')
									.attr('value', '')
									.text(objects.$selectLabel));
						}
					}

					$('#' + updatedDropdown[i][j].id)
						.append($('<option></option>')
							.attr('value', updatedDropdown[i][j].value)
							.attr('name', 'mechanicalOption-' + updatedDropdown[i][j].id + '-' + updatedDropdown[i][j].value)
							.text(updatedDropdown[i][j].label));
				} //for

				if (updatedDropdown[i].length && $('#' + updatedDropdown[i][0].id + ' option[value="' + fixedSelections[i].value + '"]').length) {
					$('#' + updatedDropdown[i][0].id).val(fixedSelections[i].value);
				}
			}

			//re-enable the drop down(s)
			setTimeout(function() {
				objects.$prodDetailDropdown.prop('disabled', false);
			}, 200);
		};

		/** Find the OptionList- optionRef item that matches the selected specValue and return it **/
		this.filterBySelections = (selectedValues, index) => {
			return function(element){
				let valueToFilterAgainst = selectedValues[index].value;
				return (element.optionRef[index].specValue === valueToFilterAgainst);
			};
		};

		/** Boolean test if the optionRef.specValue = the selected value **/
		this.filterByCurrentAndPreviousSelections = (selectedValues, index) => {
			return function(element){
				for (let i = 0; i <= index; i++) {
					if (element.optionRef[i].specValue !== selectedValues[i].value) {
						return false;
					}
				}
				return true;
			};
		};

		/*Takes the collection of 2 .. x  select elements.
      * returns a collection of entry objects with id,value,label.
      */
		this.getCurrentDropdownSelections = (currentDropdownArray) => {
			let selections = [];

			for (let d = 0; d < currentDropdownArray.length; d++) {
				let entry = Object();
				entry.id = currentDropdownArray[d].id;
				entry.value = $(currentDropdownArray[d]).val();
				entry.label = $('.productDetailDropdown option[value="' + entry.value + '"]').text();
				selections.push(entry);
			}

			return selections;
		};


		this.getSelectedPart = (optionsList, currentDropdownArray) => {

			for (let c = 0; c < optionsList.length; c++) {
				if (optionsList[c].selected) {
					return optionsList[c];
				}
			}
		};

		this.getSelectedPartNumber = (optionsList, currentDropdownArray) => {

			for (let c = 0; c < optionsList.length; c++) {
				if (optionsList[c].selected) {
					return optionsList[c];
				}
			}
		};

		this.getSelections = (dropdownSelections, selectedPart) => {

			if (selectedPart) {
				for (let i = 0; i < dropdownSelections.length; i++) {
					if (!dropdownSelections[i].value.length) {

						for (let j = 0; j < selectedPart.optionRef.length; j++) {
							if (selectedPart.optionRef[j].specName === dropdownSelections[i].id) {
								dropdownSelections[i].value = selectedPart.optionRef[j].specValue;
								dropdownSelections[i].label = selectedPart.optionRef[j].label;
								break;
							}
						}
					}
				}
			}

			return dropdownSelections;
		};

		/** Get the optionList/optionRef object that matches the selected specValue
		 * and build an updated dropdown **/
		this.getUpdatedDropdown = (optionsList, selectedValues) => {

			let dropdownArray = [];
			let filteredOptionsList = optionsList;

			for (let i = 0; i < selectedValues.length; i++) {
				let optionSet = [];

				// The first order [0] dropdown will always have every option
				if (i === 0) {
					for (let j = 0; j < optionsList.length; j++) {
						for (let k = 0; k < optionsList[j].optionRef.length; k++) {

							if (selectedValues[i].id === optionsList[j].optionRef[k].specName) {

								let option = Object();
								option.id = optionsList[j].optionRef[k].specName;
								option.value = optionsList[j].optionRef[k].specValue;
								option.label = optionsList[j].optionRef[k].label;
								let addOption = true;

								for (let h = 0; h < optionSet.length; h++) {
									//This only checks the first two drop downs for equivalency
									if (this.isEquivalent(option, optionSet[h])) {
										addOption = false;
									}
								}
								if (addOption) {
									optionSet.push(option);
								}
							}
						}
					}
				}

					// Every subsequent dropdown will be filtered by the subsequent selection
				// (Unless the current selection is invalid with previous selections)
				else {

					if (filteredOptionsList.filter(this.filterByCurrentAndPreviousSelections(selectedValues, i - 1)).length) {
						filteredOptionsList = filteredOptionsList.filter(this.filterBySelections(selectedValues, i - 1));
					}

					for (let j = 0; j < filteredOptionsList.length; j++) {
						for (let k = 0; k < filteredOptionsList[j].optionRef.length; k++) {
							if (selectedValues[i].id === filteredOptionsList[j].optionRef[k].specName) {
								let option = Object();
								option.id = filteredOptionsList[j].optionRef[k].specName;
								option.value = filteredOptionsList[j].optionRef[k].specValue;
								option.label = filteredOptionsList[j].optionRef[k].label;
								let addOption = true;

								for (let h = 0; h < optionSet.length; h++) {
									if (this.isEquivalent(option, optionSet[h])) {
										addOption = false;
									}
								}
								if (addOption) {
									optionSet.push(option);
								}
							}
						}
					}
				}
				dropdownArray.push(optionSet);
			}
			return dropdownArray;
		};

		// Initialize the global dependantOptions array. Includes li html
		this.initializeDependentOptions = () => {

			let dataUrl = objects.$productWSUrl;
			dependantOptions = Array();

			return this.requestVicorProductData({url: dataUrl + '/'+ productKey + '/' + productType + '/' + langId + '/'})
				.then(data => {

					//get a javascript object
					let optionsJsonObject = JSON.parse(data);
					//Get the specname(s) to build the drop downs
					let specNameList  = optionsJsonObject.product.mechanicalOptionCategories.specNameList;
					let optionList  = optionsJsonObject.product.mechanicalOptionCategories.optionList;

					if(specNameList) {
						specNameList.forEach(
							function (specNameListItem, specIndex) {

								dependantOptions[specIndex] = new Array(3);
								//Name for the dropdown
								dependantOptions[specIndex][0] = specNameListItem.specName;  // mechanical.category in old Vicor
								dependantOptions[specIndex][2] = new Array(optionList.length);  // for specValue & label

								optionList.forEach(function (optionListItem, index) {

									let pn = optionList[index].partNumber;
									let pnKey = '';

									if(optionListItem.selected === true)
									{
										primaryPartNumber = pn;
									}
									optionListItem.optionRef.forEach(function (optionRef, index) {

										pnKey += optionRef.specValue;
										if (specNameListItem.specName === optionRef.specName) {

											dependantOptions[specIndex][1] = optionRef.specValue;
											dependantOptions[specIndex][2][index] = new Array(optionList.length);
											dependantOptions[specIndex][2][index][0] = optionRef.specName;
											dependantOptions[specIndex][2][index][1] = '<option value="' + optionRef.specValue + '" name="mechanicalOption-' +
												optionRef.specValue + '">' + optionRef.label + '</option>';
										}
									});
									// lookup map for selection-change page refresh.
									partNumberMap.set(pnKey, pn);
								});
							}); //specName List
					}
				})
				.catch(error => {
					//the promise failed
					console.info(error);
				});
		};

		this.setDependantOptions = (selectedCategory, selectedValue) => {

			for (let x = 0; x < dependantOptions.length; x++) {
				if (dependantOptions[x][0] === selectedCategory && dependantOptions[x][1] === selectedValue) {
					if (dependantOptions[x][2].length > 0) {

						let $dependantSelect = '';
						if(Array.isArray(dependantOptions[x][2][0])) {
							// A two drop down set doesn't have a third array
							$dependantSelect = $('select[name="' + dependantOptions[x][2][0][0] + '"]');
						}
						else
						{
							$dependantSelect = $('select[name="' + dependantOptions[x][2][0] + '"]');
						}

						$dependantSelect.find('option').remove().end().append('<option value="">'+objects.$selectLabel+'</option>');

						for (let y = 0; y < dependantOptions[x][2].length; y++) {
							if (dependantOptions[x][2].length > 0) {

								if(Array.isArray(dependantOptions[x][2][y]))
								{
									if(dependantOptions[x][2][y].length > 0) {
										$dependantSelect.append(dependantOptions[x][2][y][1]);
									}
								}
							}
						}
					}
				}
			}
		};

		/**
		 * used to set up Apply button in old vicor
		 * 	<div class="pad-v">
		 <a href="javascript: doConfigure();" id="configure" class="block-ltgray pull-right" disabled="disabled">
		 Apply&nbsp;<i class="icon-fwdcarrot-white"></i></a>
		 </div>
		 * End used to set up Apply button in old vicor
		 */

		this.setUnconfigurable = () => {
			$('#configure').removeClass('block-dkblue');
			$('#configure').addClass('block-ltgray');
			$('#configure').attr('disabled', 'disabled');
		};

		this.setConfigurable = () => {
			$('#configure').addClass('block-dkblue');
			$('#configure').removeClass('block-ltgray');
			$('#configure').removeAttr('disabled');
		};

		this.isConfigurable = () => {

			//mechanicalOption-PRODUCT_GRADE-E
			let optionsSelected = $('option[name|="mechanicalOption"]').filter(':selected').length;
			if (optionsSelected === objects.$dataConfigCategoryCount) {
				return true;
			}
			else {
				return false;
			}
		};

		// Called in productPage.jsp in old Vicor
		// This is the 'Apply" button action
//		this.doConfigure = () =>  {
//			if (this.isConfigurable() && $('#configure').attr('disabled') !== 'disabled') {
//				$('#configure').attr('disabled', 'disabled');
//				let configOptions = '';
//				$('option[name|="mechanicalOption"]').filter(':selected').each(function (index) {
//					if (index > 0) {
//						configOptions += ',';
//					}
//					configOptions += $(this).parent().attr('name') + '|' + $(this).val();
//				});
//
//				let $configForm = $('#configForm');
//				$configForm.find('input[name=configOptions]').val(configOptions);
//				$configForm.submit();
//
//			}
//		};

		this.determineConfigurableClass = () =>{
			//This executed as a part of the iife in old Vicor
			if (this.isConfigurable()) {
				this.setConfigurable();
			}
			else {
				this.setUnconfigurable();
			}
		};


		this.URL_add_parameter= (url, param, value) =>{
			let hash       = {};
			let parser     = document.createElement('a');

			parser.href    = url;

			let parameters = parser.search.split(/\?|&/);

			for(let i=0; i < parameters.length; i++) {
				if(!parameters[i])
					continue;

				let ary      = parameters[i].split('=');
				hash[ary[0]] = ary[1];
			}

			hash[param] = value;

			let list = [];
			Object.keys(hash).forEach(function (key) {
				list.push(key + '=' + hash[key]);
			});

			parser.search = '?' + list.join('&');
			return parser.href;
		};


		this.isEquivalent = (a, b) => {
			// Create arrays of property names
			let aProps = Object.getOwnPropertyNames(a);
			let bProps = Object.getOwnPropertyNames(b);

			// If number of properties is different,
			// objects are not equivalent
			if (aProps.length !== bProps.length) {
				return false;
			}

			for (let i = 0; i < aProps.length; i++) {
				let propName = aProps[i];

				// If values of same property are not equal,
				// objects are not equivalent
				if (a[propName] !== b[propName]) {
					return false;
				}
			}

			// If we made it this far, objects
			// are considered equivalent
			return true;
		};

		// XMLHttpRequest wrapper using callbacks
		this.requestVicorProductData = obj => {
			return new Promise((resolve, reject) => {

				if(vicorResponse) {
					resolve(vicorResponse);
				}
				else {
					let xhr = new XMLHttpRequest();
					xhr.open(obj.method || 'GET', obj.url + '?ipAddress=' + encodeURIComponent(objects.$trueClientIp));

					if (obj.headers) {
						Object.keys(obj.headers).forEach(key => {
							xhr.setRequestHeader(key, obj.headers[key]);
						});
					}

					xhr.onload = () => {
						if (xhr.status >= 200 && xhr.status < 300) {
							vicorResponse = xhr.response;
							resolve(vicorResponse);
						} else if (xhr.status == 403) {
							window.location.href = objects.$dataRedirectURL403;

						} else {
							reject(xhr.statusText);
						}
					};
					xhr.onerror = () => reject(xhr.statusText);

					xhr.send(obj.body);

				}
			});
		};

		this.initProductOptionSelection = () => {
			let that = this;

			this.initializeDependentOptions()
				.then(function() {
					that.determineConfigurableClass();

					// listener for options drop down change
					objects.$prodDetailDropdown.on('change', () => {
						that.prodDetailDropdownChangeHandler();
					});

					$('#configOptions select').on('change', (e) => {
						that.configOptionsChangeHandler();
					});
				});

			//$("div.breadcrumb a").last().attr("href", "#");
			//this.getFamilyPageURL();

			//Executed as a location redirect when a bad/mot-found part number was included in the url
			//${errorRedirectScript}  // function this.errorRedirectScript
		};

		// Utilities
		this.escapeRegExp = (str) => {
			return str.replace(/[.*+?^\\${}()|[\]\\]/g, '\\$&');
		};

		// Used to replace an escaped degree sign with a degree character
		this.replaceAll = (str, search, replacement)  => {
			return str.replace(RegExp(this.escapeRegExp(search), 'g'), replacement);
		};
	}//constructor

	init() {
		this.getData();
	}
}

export default ProductOptionSelection;

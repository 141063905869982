class FAQSearch {

	static name() {
		return 'FAQSearch';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$form: $(el).find('form'),
			$termInput: $(el).find('input[data-type="term"]'),
			$tagInput: $(el).find('input[data-type="tag"]'),
			allTags: $(el).data('all-tags').split(','),
			filteredTags: $(el).data('filtered-tags').split(',')
		};

		this.termToTag = () => {
			objects.$tagInput.val(objects.$termInput.val());
			objects.$termInput.val('');
		};

		this.searchTagFunction = (event, ui) => {
			console.log('search tag func: '+ui.item.value);
			objects.$tagInput.val(ui.item.value);
			objects.$termInput.val('');
			objects.$form.submit();
		};

		this.init = () => {
			console.log('init FAQ Search');

			objects.$form.submit(function (event) {
				console.log('submit');
				if (!(objects.$termInput.val() || objects.$tagInput.val())) {
					event.preventDefault();
					return;
				}
				let term = objects.$termInput.val();
				if (term != '') {
					var mode = $('input[name=mode]:checked').val();
					if (mode === 'all' && $.inArray(term, objects.allTags) != -1) {
						this.termToTag();
					} else if ($.inArray(term, objects.filteredTags) != -1) {
						this.termToTag();
					} else {
						objects.$tagInput.val('');
					}
				}
			});
		};
	}


	init() {
		this.init();
	}

}

export default FAQSearch;

import Chart from 'chart.js';
import chartUtil from './ChartUtils';


class ChartCore{

	static name() {
		return 'ChartCore';
	}

	constructor(props, el, $, Utils) {

		const familyId = $(el).data('product-family');
		//Points for Y-axis and X-axis


		// These points draw the curve - normal
		let x = [1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35,37,39,41,43,45,47,49,50,51,53,55,57,59,61,63,65,67,69,71,73,75,77,79,81,83,85,87,89,91,93,95,97,99,100];
		let y = [100.00,98.09,96.21,94.37,92.57,90.80,89.07,87.38,85.72,84.09,82.51,80.95,79.43,77.94,76.49,75.07,73.68,72.33,71.00,69.71,68.44,67.21,66.00,64.82,63.67,63.10,62.54,61.44,60.36,59.30,58.27,57.26,56.26,55.29,54.33,53.39,52.46,51.55,50.65,49.76,48.88,48.01,47.14,46.28,45.42,44.57,43.71,42.85,41.99,41.12,40.25,39.81,0.00];
		let poi = [1, 25, 50, 75, 100];

		// These points draw the curve - buck and buck boost
		if(familyId === 'zvs_buck_boost' || familyId === 'zvs_buck') {
			x = [10,30,50,70,90,110,130,150,170,190,210,230,250,270,290,310,330,350,370,390,410,430,450,470,490,500,510,530,550,570,590,610,630,650,670,690,710,730,750,770,790,810,830,850,870,890,910,930,950,970,990,1000];
			y = [100.00,98.09,96.21,94.37,92.57,90.80,89.07,87.38,85.72,84.09,82.51,80.95,79.43,77.94,76.49,75.07,73.68,72.33,71.00,69.71,68.44,67.21,66.00,64.82,63.67,63.10,62.54,61.44,60.36,59.30,58.27,57.26,56.26,55.29,54.33,53.39,52.46,51.55,50.65,49.76,48.88,48.01,47.14,46.28,45.42,44.57,43.71,42.85,41.99,41.12,40.25,39.81,0.00];
			poi = [1, 250, 500, 750, 1000];

		}


		//const x = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1500, 1800, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000];
		//const y = [100.00, 93.31, 89.60, 87.06, 85.14, 83.60, 82.32, 81.23, 80.28, 79.43, 74.11, 71.17, 69.15, 67.62, 66.41, 65.39, 64.52, 63.76, 63.10, 58.87, 56.53, 54.93, 53.72, 52.75, 51.94, 51.25, 50.65, 50.12, 49.12, 48.13, 47.26, 46.76, 45.45, 44.90, 44.22, 43.63, 42.67, 41.90, 41.26, 40.71, 40.23, 39.81, 0.00];
		//Max and Min value indexes
		const minChartPoint = y[y.length - 1];
		const maxChartPoint = y[0];

		//Point or labels for X-axis

		//const poi1 = [1, 10, 100, 1000, 10000];

		const objects = {

			$el:$(el),
			$priceChart:$(el).find('#vicorPriceChart')

		};

		let utils = new Utils();
		let line = [];
		let points = [];
		let suggestedPoint = [];
		let myLineChart = null;
		let chartUtilObj = null;

		this.initChartCore = () => {
			//Set line array with values to print the Curve in the chart
			for (let i = 0; i < x.length; i++) {
				line.push({
					'x': x[i],
					'y': y[i]
				});
			}

			chartUtilObj = new chartUtil(props,el, $, Utils);
			chartUtilObj.init();
		};

		//Call to restful service which returns the pricing depending on the qty
		//1 piece price
		//500 piece price
		//* suggested piece price (typed in by the user)
		//
		//Note: we have to figure out how to, dynamically, change the server instance
		this.queryPrice = (serviceUrl, productId, quantity, identifier, trueClientIp) =>{

			let qty = quantity === '' ? 100 : quantity;

			// console.info('serviceUrl: ' + serviceUrl + ' productId: ' + productId + ' quantity: ' + quantity + ' identifier: ' + identifier +' qty: ' + qty);

			$.ajax({
				method: 'GET',
				data: {
					'ipAddress':trueClientIp
				},
				url: serviceUrl + productId +'/'+ qty,
				dataType: 'jsonp',
				crossDomain: true,
				async: false,
				jsonpCallback: 'jsonpCallback',
				contentType:'application/json',
//				beforeSend: function(request) {
//					request.setRequestHeader("true-client-ip", trueClientIp);
//				},
				success : function(priceObj){

					//  console.info('AJAX success: priceObj is undefined = ' + (priceObj === undefined) + ' : ' + priceObj.priceList[0] + ' :: ' + priceObj.priceList[1] + ' :: ' +  priceObj.priceList[2] );

					$('#'+identifier+'-minInput').val(priceObj.priceList[0]);
					$('#'+identifier+'-maxInput').val(priceObj.priceList[1]);

					if($('#'+identifier+'-quantityInput').val() === ''){
						$('#'+identifier+'-userQtyInput').val('');
					}else{
						$('#'+identifier+'-userQtyInput').val(priceObj.priceList[2]);
						let currency = utils.currencyFormatter(priceObj.priceList[2], 'en-US', 'currency', 'USD', 2);
						$('#'+identifier+'-price').text(currency);
					}

					$('#'+identifier+'-userQtyInput').trigger('change');
					$('#qty').text(priceObj.priceList[2]+'pc Price:');


					redraw(identifier);
				}
			});
		};

		//Draw the logarithmic chart with user values given
		//Here is were we implement the chart.js API
		function redraw (identifier){
			let maxPrice  = $('#'+identifier+'-maxInput').val(); //500
			let minPrice  = $('#'+identifier+'-minInput').val(); //1
			let userPrice   = $('#'+identifier+'-userQtyInput').val(); //any value given by the user
			let quantity  = $('#'+identifier+'-quantityInput').val() > 80 || $('#'+identifier+'-quantityInput').val() < 10 ? $('#'+identifier+'-quantityInput').val() : parseFloat($('#'+identifier+'-quantityInput').val())+2;
			let suggestedPoint = getPointDataset(quantity>1000?1000:quantity);

			let config = {
				type: 'line',
				data: {
					datasets: [{
						label: 'Suggested Price',
						fill: false,
						pointRadius: 8,
						pointHoverRadius: 8,
						data: suggestedPoint,//Point
						borderColor : '#577282',
						backgroundColor : '#577282',
						lineTension: 0,
						cubicInterpolationMode: 'monotone'
					},
						{
							label: 'Less than 100',
							fill: false,
							pointRadius: 0,
							pointHoverRadius: 0,
							pointHitRadius: 0,
							data: line,
							borderColor: '#577282',
							backgroundColor: '#577282',
							lineTension: 0,
							cubicInterpolationMode: 'monotone'
						}
					]
				},
				options: {
					animation: false,
					responsive: true,
					showAllToolTips: true,
					intersect: false,
					layout: {
						padding: {
							right: 50
						}
					},
					hover: {
						mode: null
					},
					tooltips: {
						enabled: false,

						custom: function(tooltipModel) {
							// Tooltip Element
							let tooltipEl = document.getElementById('chartjs-tooltip');

							// Create element on first render
							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.id = 'chartjs-tooltip';
								tooltipEl.innerHTML = '<table></table>';
								document.body.appendChild(tooltipEl);
							} else {
								tooltipEl.innerHTML = '<table></table>';
							}

							// Hide if no tooltip
							/*if (tooltipModel.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}
*/
							// Set caret Position
							tooltipEl.classList.remove('above', 'below', 'no-transform');
							if (tooltipModel.yAlign) {
								tooltipEl.classList.add(tooltipModel.yAlign);
							} else {
								tooltipEl.classList.add('no-transform');
							}

							function getBody(bodyItem) {
								return bodyItem.lines;
							}

							// Set Text
							if (tooltipModel.body) {
								let bodyLines = tooltipModel.body.map(getBody);

								let innerHtml = '<tbody>';

								bodyLines.forEach(function(body, i) {
									innerHtml += '<tr><td id="price-tooltip">' + $('#'+identifier+'-price').text() + ' ea</td></tr>';
								});
								innerHtml += '</tbody>';

								let tableRoot = tooltipEl.querySelector('table');
								tableRoot.innerHTML = innerHtml;

								console.log(suggestedPoint);
							}

							// `this` will be the overall tooltip
							let positionY = this._chart.canvas.offsetTop;
							let positionX = this._chart.canvas.offsetLeft;

							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.position = 'absolute';
							tooltipEl.style.left = positionX + tooltipModel.caretX + 'px';
							tooltipEl.style.top = positionY + tooltipModel.caretY + 'px';
							tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
							tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
							tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
							tooltipEl.style.pointerEvents = 'none';
							tooltipEl.style.padding = 0 + 'px ' + 15 + 'px';
							tooltipEl.style.zIndex = 1;
							tooltipEl.style.background = '#577282';
						}

					},
					scales: {
						xAxes: [{
							type: 'linear',
							position: 'bottom',
							gridLines: {
								display:true,
								color: '#a6bbc3'
							},
							scaleLabel: {
								display: true,
								labelString: '',
								fontSize: 15,
								fontFamily: 'NeueFrutigerLight',
								fontColor: '#4A5D6D'
							},
							ticks:{
								fontSize: 15,
								fontFamily: 'NeueFrutigerLight',
								fontColor: '#4A5D6D',
								min: round(x.reduce(
									function(a,b){return Math.min(a,b)})
								),
								stepSize: (familyId === 'zvs_buck_boost' || familyId === 'zvs_buck' ? 10 : 1),
								reverse: false,
								callback: function(label, index, labels){
									//Reads and print the 10^1...10^4 values for the X-axis
									if ($.inArray(label, poi) >= 0){
										return label;
									} else {
										return null;
									}
								},
								autoSkip: false,
								maxRotation: 0,
								minRotation: 0
							}
						}],
						yAxes: [{
							type: 'linear',
							position: 'left',
							gridLines: {
								display:true,
								color: '#a6bbc3'
							},
							ticks: {
								fontSize: 15,
								fontFamily: 'NeueFrutigerLight',
								fontColor: '#4A5D6D',
								min: minChartPoint, //Min y Axis label
								max: maxChartPoint, // Max y Axis label
								fixedStepSize: 50, //Step Size for y Axis
								callback: function(value, index, values) {
									//Function to show the label according the max and min price instead of the curve values.
									let label='';
									if (value === maxChartPoint){
										label = '$'+maxPrice;
									}else if (value === minChartPoint){
										label = '';
									} else {
										label = ''; //'Custom price goes here';
									}
									return label;
								}
							}
						}]
					},
					legend: {
						labels: {
							// return true or false based on legendItem's datasetIndex (legendItem.datasetIndex)
							filter: function(legendItem, chartData) {
								return false;
							}
						}
					}
				}
			};

			Chart.plugins.register({
				beforeRender: function (chart) {
					if (chart.config.options.showAllTooltips) {
						// create an array of tooltips
						// we can't use the chart tooltip because there is only one tooltip per chart
						chart.pluginTooltips = [];
						chart.config.data.datasets.forEach(function (dataset, i) {
							chart.getDatasetMeta(i).data.forEach(function (sector, j) {
								chart.pluginTooltips.push(new Chart.Tooltip({
									_chart: chart.chart,
									_chartInstance: chart,
									_data: chart.data,
									_options: chart.options,
									_active: [sector]
								}, chart));
							});
						});

						// turn off normal tooltips
						chart.options.tooltips.enabled = false;
					}
				},

				afterDraw: function (chart, easing) {
					if (chart.config.options.showAllTooltips) {
						// we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
						if (!chart.allTooltipsOnce) {
							if (easing !== 1)
								return;
							chart.allTooltipsOnce = true;
						}

						// turn on tooltips
						chart.options.tooltips.enabled = true;
						Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
							tooltip.initialize();
							tooltip.update();
							// we don't actually need this since we are not animating tooltips
							tooltip.pivot();
							tooltip.transition(easing).draw();
						});
						chart.options.tooltips.enabled = false;
					}
				}
			});

			// Cancel all mouse events because we're having the tooltips display persistently.  Allowing mouse events
			// will cause the tooltip to disappear
			Chart.plugins.register({
				beforeEvent: function(chart, e) {
					return e.type !== 'mousemove';
				}
			});

			if(myLineChart!=null){
				myLineChart.destroy();
			}

			//Create DOM element for the chart
			let ctx = document.getElementById('vicorPriceChart').getContext('2d');
			//let ctx = objects.$priceChart.getContext().TEXTURE_2D;
			myLineChart = new Chart(ctx, config);
			showTooltips(myLineChart);
		}

		/**
		 * Show persistent tooltips on the chart
		 * @param chart
		 */
		function showTooltips(chart) {
			let elements = [];

			for (let i = 0; i < chart.data.datasets.length; i++) {
				if (!chart.isDatasetVisible(i)) {
					continue;
				}

				let meta = chart.getDatasetMeta(i);
				for (let j = 0; j < meta.data.length; ++j) {
					let element = meta.data[j];
					if (!element._view.skip && element._model.radius > 0) {
						elements.push(element);
						break;
					}
				}

				if (elements.length > 0) {
					break;
				}
			}

			chart.tooltip._active = elements;
			chart.tooltip.update(true);
		}

		//Round a number upward to its nearest integer
		let round = (num) => {
			return Math.ceil(num);
		};

		//Get the point value base on the quantity
		function getPointDataset(quantity){
			let emptyList = [];
			let xLength = x.length-1;

			if(quantity > 10 ){
				//Get the 10 first index values for x,y array
				for (let i=0; i < xLength; i++){
					if( quantity > x[i] && quantity <= x[i+1]){
						let ix = x.indexOf(x[i]);
						emptyList.push({
							'x': x[ix],
							'y': y[ix]
						});
					}
				}
			}else{
				//Get the values for 10^2 ...10^4
				if(quantity > 0){
					emptyList.push({
						'x': x[quantity-1],
						'y': y[quantity-1]
					});
				}else{
					emptyList.push({
						'x': x[0],
						'y': y[0]
					});
				}
			}
			return emptyList;
		}
	}

	init() {
		this.initChartCore();
	}
}

export default ChartCore;

import 'jquery.cookie';

class LanguageSwitcher {

	static name() {
		return 'LanguageSwitcher';
	}

	constructor(props, el, $) {

		const objects = {
			$el: $(el),
			$languageLink: $(el).find('[data-language]'),
			$domain: $(el).find('[data-language]').data('server'),
			$topLevelDomain: $(el).find('[data-language]').data('domain')
		};

		this.setLocation = (e) => {
			e.preventDefault();
			if(objects.$domain.endsWith('com') || objects.$domain.endsWith('cn')) {
				$.cookie('preferredLang', $(e.target).attr('rel'), {domain: objects.$topLevelDomain, path: '/', expires: 60 * 60 * 24 * 90 ,secure:true});

			}
			else {
				$.cookie('preferredLang', $(e.target).attr('rel'), {domain: objects.$domain, path: '/', expires: 60 * 60 * 24 * 90,secure:true});
			}
			//$.removeCookie('preferredLang', { domain: objects.$domain, path: '/' });
            //$.cookie('preferredLang', $(e.target).attr('rel'), {domain: objects.$topLevelDomain, path: '/', expires: 60 * 60 * 24 * 90});
			window.location = $(e.target).attr('href');
		};


		this.initLanguageSwitcher = () => {
			objects.$languageLink.on('click', this.setLocation);
		};

	}

	init() {
		this.initLanguageSwitcher();
	}


}

export default LanguageSwitcher;

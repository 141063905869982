class Header {

	static name() {
		return 'Header';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$nav: $(el).find('.navbar'),
			$navbarCollapse: $(el).find('.navbar-collapse'),
			$navLink: $(el).find('a[data-target]'),
			$activeLink: $(el).find('.nav-link.is-active'),
			$parentLinks: $(el).find('.nav-link[data-level]'),
			$backbtn: $(el).find('.btn-back'),
			$backbtnIcon: $(el).find('.btn-back .icon'),
			$closebtn: $(el).find('.btn-close'),
			$headerSearch: $(el).find('.search.form-inline'),
			$searchInput: $(el).find('.search .form-control'),
			$searchCategories: $(el).find('a[data-category]'),
			$textareas: $('textarea'),
			$menuDialogDropdown: $(el).find('.dropdown-menu'),
			$menuDialog: $(el).find('.dropdown-menu.parent'),
		};

		let utils = new Utils();


		// Slide out menu functionality
		this.handleProgression = (e) => {

			// Display menu
			objects.$navbarCollapse.addClass('d-block');

			// Pause Slider
			$('.carousel-slides').slick('slickPause');

			// Set active panel levels
			const addActivePanel = () => {
				let thisDropdown = $($(e.target).attr('data-target')).find('.dropdown-menu');

				if($(e.target).attr('data-target') !== undefined) {
					$($(e.target).attr('data-target')).addClass('is-active');

					// $('.nav-link.nested').attr('tabindex', -1);
					this.toggleTabIndex($($(e.target).attr('data-target')), 0);

					if(!thisDropdown.hasClass('is-active')) {
						this.toggleTabIndex(thisDropdown, -1);
					}
				}
			};

			// Set container offsets & animation
			const setOffset = () => {
				let $thisDropdownWidth;

				// Body Animation
				$('body').addClass('nav-offset');
				$('body').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
					$('body').addClass('nav-offset-reset');
				});

				if(!(utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md')) {
					$thisDropdownWidth = $(e.target).siblings('.dropdown-menu.parent').outerWidth();
				} else {
					$thisDropdownWidth = $('.dropdown-menu.parent').outerWidth();
					$('[data-current]').removeAttr('data-current');
					$('[data-previous]').removeAttr('data-previous');

					if($(e.target).attr('id') !== 'navbarSupportedContent') {
						$(e.target).attr('data-previous', '');
						$('[data-previous]').attr('tabindex', -1);
						$('.navbar-nav > li > a').attr('tabindex', -1);
						objects.$closebtn.focus();
					}
				}

				$('.header, #main, .footer').css(
					'transform', 'translateX(-' + $thisDropdownWidth + 'px)'
				);
			};

			addActivePanel();
			setOffset();
			this.displayBack();
			utils.initTrapFocus(objects.$navbarCollapse);
		};


		// Remove Offset
		this.removeOffset = () => {
			// Body Animation
			$('body').removeClass('nav-offset');
			$('.header, #main, .footer').css('transform', '');

			let resetAll = () => {
				$('body').removeClass('nav-offset-reset');
				$('.dropdown-menu').removeClass('is-active');
				$('[data-current]').removeAttr('tabindex');
				$('[data-previous]').removeAttr('tabindex');
				$('.navbar-nav > li > a').removeAttr('tabindex');
				$('[data-current]').removeAttr('data-current');
				$('[data-previous]').removeAttr('data-previous');
				objects.$navbarCollapse.removeClass('d-block');
				this.toggleTabIndex(objects.$menuDialogDropdown.filter(':not(.is-active)'), -1);
			};

			setTimeout(resetAll, 600);

			// Play Slider
			$('.carousel-slides').slick('slickPlay');
		};


		this.toggleTabIndex = (el, indexVal) => {
			let tabbableEls = '[href], input, button:not(.btn-close), select, .featured-nav-links .nav-link';

			if((utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md')) {
				el.find(tabbableEls).attr('tabindex', indexVal);
			}
		};


		// Populate Persistent Submenu
		this.populateSubmenu = () => {
			objects.$parentLinks.each(function() {
				let $dropdown = $(this).siblings('.dropdown-menu');
				let $childLinks = $dropdown.find('.nav-link');
				let $submenu = $(this).siblings('.persistent-submenu');

				$childLinks.each(function() {
					$(this).attr('href', $(this).data('url'));
				});

				let cloned = $childLinks.clone().removeAttr('role data-toggle data-target aria-haspopup aria-expanded aria-controls');
				$submenu.append(cloned).wrapInner('<div class="container"></div>');
			});
		};

		// Mobile Only
		this.displayMobileMenu = (e) => {
			if ($(e.target).attr('id') === 'navbarSupportedContent') {
				this.handleProgression(e);
			}
		};
		this.displayBack = (progressionStarted) => {
			if($('.dropdown-menu').hasClass('is-active')) {
				objects.$backbtnIcon.addClass('d-block');
				objects.$backbtn.attr('tabindex', 0);
			} else {
				objects.$backbtnIcon.removeClass('d-block');
				objects.$backbtn.attr('tabindex', -1);
			}

			if(progressionStarted) {
				objects.$closebtn.focus();
			}
		};
		this.handleBack = () => {
			$('[data-current]').removeAttr('data-current');
			$($('[data-previous]').data('target')).removeClass('is-active');
			$('[data-previous]').removeAttr('tabindex');
			$('[data-current]').removeAttr('tabindex');
			// $('.nav-link.nested').removeAttr('tabindex');
			$('.navbar-nav > li > a').removeAttr('tabindex');
			this.toggleTabIndex($($('[data-previous]').data('target')), -1);
			$('[data-previous]').removeAttr('data-previous').attr('data-current', '');
			let currentDropdownId = $('[data-current]').parents('.dropdown-menu').attr('id');
			$('[data-target="' + ('#' + currentDropdownId) + '"]').attr('data-previous', '');
			this.displayBack(true);
		};

		this.replaceBreadcrumb = ()=>{
			let $productPageBreadCrumb = $('.product-page-breadcrumb');
			if($productPageBreadCrumb.length > 0){
				$('.breadcrumb-item').html($productPageBreadCrumb.html());
			}
		};

		// Init
		this.Header = () => {

			window.onscroll = function(e) {
				if(this.oldScroll > this.scrollY) {
					objects.$el.addClass('scroll-up');
				} else {
					objects.$el.removeClass('scroll-up');
				}
				this.oldScroll = this.scrollY;
			};

			objects.$navLink.on('click', this.handleProgression);
			objects.$backbtn.on('click', this.handleBack);

			// ADA tabindex set
			if((utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md')) {
				this.toggleTabIndex(objects.$menuDialogDropdown.filter(':not(.is-active)'), -1);
				objects.$backbtn.attr('tabindex', -1);
				$('.dropdown-menu.parent').find('.btn-close').attr('tabindex', -1);
				$('.persistent-search-toggle .search-toggler').attr('tabindex', -1);
			}

			// Remove tabbing from inactive elements on desktop
			if(!(utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md')) {
				$('.dropdown-menu.parent').find('[data-toggle="collapse"]').attr('tabindex', -1);
			}

			// Menu toggle
			$('.header .collapse').on('show.bs.collapse', (e) => {this.displayMobileMenu(e)});

			if((utils.getViewportSize() === 'lg' || utils.getViewportSize() === 'xl' || utils.getViewportSize() === 'xxl')) {
				objects.$menuDialog.on('shown.bs.collapse', (e) => {utils.initTrapFocus($(e.target))});
			}

			objects.$menuDialog.on('shown.bs.collapse', (e) => {utils.initTrapFocus($(e.target))});


			// Reset container offsets & animation
			$(document).on('click', (e) => {
				if (!$(e.target).parents().is(objects.$el) || $(e.target).parents().is(objects.$closebtn) || $(e.target).is(objects.$closebtn)) {
					this.removeOffset();
					$('#navbarSupportedContent').collapse('hide');
				}
			});
			
			// Header Search toggle
			$(document).on('click', (e) => {
				if (!$(e.target).is(objects.$searchInput) || !$(e.target).parents().is(objects.$headerSearch)) {
					objects.$headerSearch.collapse('hide');
					objects.$searchInput.removeClass('active');
				}
			});

			// $(document).on('click', (e) => {
			// 	if (!$(e.target).is(objects.$searchInput) || !$(e.target).parents().is(objects.$headerSearch)) {
			// 		setTimeout(function() {
			// 			objects.$headerSearch.collapse('hide');
			// 			objects.$searchInput.removeClass('active');
			// 		}, 500);
			// 	}
			// });
			
			objects.$searchInput.on('change keyup', (e) => {
				let valLength = $(e.target).val().length;

				if(valLength > 0) {
					$(e.target).addClass('active');
				} else {
					$(e.target).removeClass('active');
				}
			});

			objects.$headerSearch.on('submit', (e) => {
				if (objects.$searchInput.val().trim().length <= 0) {
					e.preventDefault();
				}
			});

			objects.$searchCategories.on('click', (e) => {
				let $this = $(e.target);

				objects.$headerSearch.find('input[name="cat"]').val($this.data('category'));
				objects.$headerSearch.submit();
			});

			$('.form-fields-hny').attr('tabindex', -1);

			objects.$headerSearch.on('show.bs.collapse', function () {
				$('.search-backdrop').removeClass('d-none');
				$('#searchTerm').focus();
				$('.search-wrapper').removeClass('contract').addClass('expanded');
				objects.$headerSearch.removeClass('header-search-hidden');
			});			
			
			objects.$headerSearch.on('shown.bs.collapse', function () {
				//$('.search .form-control:visible').focus();
				$('#searchTerm').val('');
				$('#searchTermMobile').val('');
				$('#searchTerm').focus();
			});

			objects.$headerSearch.on('hide.bs.collapse', function (e) {
				e.preventDefault(); // Prevent the default collapse hide action
			
				$('.search-backdrop').addClass('d-none');
				$('.search-toggler:visible').focus();
				$('#searchTerm').val('');
				$('#searchTermMobile').val('');
				$("#search-suggestions-wrapper").remove();
				$(".no-results-container").remove();
				$(".no-results-dropdown").remove();
				$(".no-suggestions-container").remove();
			
				// Trigger the contract animation
				$('.search-wrapper').removeClass('expanded').addClass('contract');
			
				// Listen for the end of the animation to hide the search form
				$('.search-wrapper').one('animationend', function() {
					$('.search-wrapper').removeClass('contract');
					objects.$headerSearch.removeClass('show').addClass('header-search-hidden'); // Hide the element after the animation
				});
			});	
			
			// Populate & Activate Persistent Submenu
			this.populateSubmenu();
			utils.initSetSticky();

			this.replaceBreadcrumb();
		};
	}

	init() {
		this.Header();
	}
}

export default Header;

class Accordion {

	static name() {
		return 'Accordion';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el)
		};

		this.initAccordion = () => {

		};

	}

	init() {
		this.initAccordion();
	}


}

export default Accordion;

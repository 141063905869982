class HeroSlider {

	static name() {
		return 'HeroSlider';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$carousel: $(el).find('.carousel-slides'),
			$primaryHeroHeadline: $(el).find('.hero-description-container'),
			$subheadContainer: $(el).find('.sub-subhead-container'),
			$alertMessage: $('#site-alert .container'),
			$figureContainer: $(el).find('.content-container'),
			$slideContainer: $(el).find('.absolute-cont'),
			$heroDescription: $(el).find('.hero-desc')
		};

		let utils = new Utils();

		this.setHeroDescH = () => {
			let subContatinerH = objects.$carousel.find('.subheading').html();
			let heroSubContainer = objects.$primaryHeroHeadline.find('.subheading');

			heroSubContainer.html(subContatinerH);
		};

		this.setAlertOffset = () => {
			$(el).css('top', -(objects.$alertMessage.height()));
		};

		this.animate = () => {
			$(el).addClass('animate-flicker');
			setTimeout(function(){
				$(el).removeClass('animate-flicker');
			}, 1000);
		};

		this.flexFont = () => {
			const relFontsize = $('.sub-subhead-container').outerWidth() * 0.099;
			const relFontLh = $('.sub-subhead-container').outerWidth() * 0.12;
			const relFontsizeSub = $('.sub-subhead-container').outerWidth() * 0.043;
			const relFontSubLh = $('.sub-subhead-container').outerWidth() * 0.08;
			const relSubH = $('.sub-subhead-container').outerWidth() * 0.09;

			$('.hero-slider .hero-desc').css({ 'font-size':  relFontsize + 'px'});
			$('.hero-slider .hero-desc').css({ 'line-height':  relFontLh + 'px'});
			$('.hero-slider .hero-desc').css({ 'margin-bottom':  relSubH / 2 + 'px'});

			$('.hero-slider .slide-subhead').css({ 'font-size':  relFontsizeSub + 'px'});
			$('.hero-slider .slide-subhead').css({ 'line-height':  relFontSubLh + 'px'});
			$('.hero-slider .slide-subhead').css({ 'height':  relSubH + 'px'});
			$('.hero-slider .slide-subhead').css({ 'margin-bottom':  relSubH + 'px'});
		};

		this.resetVh = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};

		this.initHeroSlider = () => {
			this.resetVh();

			$(window).on('load', (e) => {
				this.setAlertOffset();
				this.setHeroDescH();
				this.flexFont();
				setTimeout(function(){
					$(el).addClass('active');
				}, 50);
			});

			$(window).on('resize', (e) => {
				this.flexFont();
				this.setHeroDescH();
				this.resetVh();
			});
		};

	}

	init() {
		this.initHeroSlider();
	}


}

export default HeroSlider;

import DataTable from 'datatables.net-dt';
//import 'datatables.net-plugins/sorting/any-number.mjs';
import TableSorter from 'tablesorter';
import { ready } from 'jquery';

class FilterList {
	static name() {
		return 'FilterList';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$filterCloseIcons: $(el).find('[data-uncheck-filter]'),
			$filterMenu: $(el).find('[id^=filter-menu]'),
			$filterRow: $(el).find('[data-filter-row-container]'),
			$filterCollapseDropdowns: $(el).find('[data-filter-container]'),
			$toggleBtns: $(el).find('[data-view]'),
			$resetBtn: $(el).find('[data-reset]'),
			$persistentContainer: $(el).find('.persistent-container'),
			$closeIcon: $(el).find('.close-icon-container'),
			$results: $(el).find('.family-product'),
			$productCount: $(el).find('.product-count'),
			$loadMoreBtn: $(el).find('[data-load-more]'),
			$loadMoreBtnContainer: $(el).find('[data-loaded]'),
			$psdWidget: $(el).find('.grid-view-psd-container'),
			$tableViewPsdWidget: $('.table-view-psd-container'),
			$productMatrixDatatable: $(el).find('.product-matrix-datatable'),
			$pageFirst: $(el).find(".page-first"),
			$pagePrev: $(el).find(".page-prev"),
			$currentPage: $(el).find(".current-page"),
			$totalPages: $(el).find(".total-pages"),
			$pageNext: $(el).find(".page-next"),
			$pageLast: $(el).find(".page-last"),
			$saveColumnsButton: $(el).find('.save-columns'),
			$columnSelectModal: $(el).find('.column-select-modal'),
			$openColumnSelectModal: $(el).find('.open-column-select-modal'),
			$cancelSaveColumnsButton: $(el).find('.cancel-save-columns'),
			$filtersWrapper: $(el).find('.filters-wrapper'),
			$expandFiltersButton: $(el).find('.expand-filters-button'),
			$scrollButtonLeft: $(el).find('.scroll-button__left'),
			$scrollButtonRight: $(el).find('.scroll-button__right'),
			$filters: $(el).find('.filters'),
			$numberOfRecords: $(el).find('.number-of-records'),
			$searchProducts: $(el).find('.search-products'),
			$uomSwitch: $(el).find('.uom-switch'),
			$pageLengthOption: $(el).find('.page-length-option'),
			$clearFilters: $(el).find('.clear-filters'),
			$searchStateControl: $(el).find('.icon-search-state-control'),
			$resetFilters: $(el).find('.reset-filters'),
			$filterList: $(el).find('.filter-list'),
			$productSearchWrap: $(el).find('.product-search-wrap'),
			$contentBasedMatrix: $(el).find('.content-based-matrix'),
			$contentBasedMatrixSearch: $(el).find('.content-based-matrix-search'),
			$contentBasedAccessoriesTable: $(el).find('.content-based-accessories-table-inner-wrapper'),
			$serviceBasedAccessoriesTable: $(el).find('.service-based-accessories-table-inner-wrapper'),
			$initialSearchTerm: $(el).data('initial-search')
		};

		let initialLoad = true;

		const numDropdowns = objects.$filterCollapseDropdowns.length;
		const matrixId = objects.$el.data('matrix-id');

		let checkedFiltersForScreenWidth = 0;
		var windowWidth = 0;

		let utils = new Utils();

		let _filters = {
			applied: [],
			items: []
		};

		let _vtable = {
			rows: [],
			filterNames: [],
			filterValues: []
		};

		let _viewport = {
			maxResults: 0,
			loadMoreSize: 0,
			psdSlot: 0,

			reset: function() {
				let isSmall = utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm';

				this.maxResults = isSmall ? 6 : (objects.$tableViewPsdWidget.children().length > 0 ? 7 : 8);
				this.loadMoreSize = isSmall ? 4 : 8;
				this.psdSlot = isSmall ? 5 : 6;
			}
		};

		_viewport.reset();

		// Product lists
		this.toggleView = (e) => {
			let $this = $(e.target);

			objects.$toggleBtns.removeClass('is-active');
			$this.addClass('is-active');

			if ($this.data('view') === 'grid') {
				objects.$el.addClass('grid-view');
				objects.$tableViewPsdWidget.removeClass('d-lg-block').removeClass('d-xl-block');
			} else {
				objects.$el.removeClass('grid-view');
				objects.$tableViewPsdWidget.addClass('d-lg-block').addClass('d-xl-block');
			}
		};

		this.toggleCheckedFilters = (e) => {
			let $thisFilterContainerUl = $(e.target).parents('[data-filter-container]').siblings('[data-checked-filters]');

			if ($(e.target).is(':checked')) {
				$(e.target).parents('li').addClass('active');
				$('<li />').appendTo($thisFilterContainerUl)
					.html($(e.target).closest('li').find('label').html())
					.data('filter-value', $(e.target).closest('li').find('input').val())
					.append(objects.$closeIcon.html());
			} else {
				$(e.target).parents('li').removeClass('active');
				$($thisFilterContainerUl).find('li[data-filter-value="' + $(e.target).closest('li').find('input').val() + '"]').remove();

				let val = $(e.target).closest('li').find('input').val();

				$thisFilterContainerUl.find('li').filter(function() {
					return $(this).data('filter-value') === val;
				}).remove();
			}

			// let savedFilters = [];
			// $('[data-checked-filters]').each(function() {
			// 	$(this).find('.filter-item').each(function(){
			// 		if($(this).html() !== undefined){
			// 			savedFilters.push($(this).html());
			// 		}
			// 	});
			// });

			// sessionStorage.setItem('savedFilters', savedFilters.join());

			this.modifyFilters($(e.target));
			this.filter($(e.target));
		};

		this.resetFilters = () => {

			objects.$el.find('[data-checked-filters]').children('li').remove();
			objects.$filters.find('[type="checkbox"]').prop('checked', false);

			objects.$uomSwitch.prop('checked', true);

			objects.$el.find('li.active').removeClass('active');
			_filters.applied = [];

			objects.$columnSelectModal.find('[type="checkbox"]').each(function() {
				$(this).prop('checked', true);
			});

			objects.$saveColumnsButton.click();

			handleHideShowColumnsOnSmallerScreens();

			this.filter();

			objects.$resetFilters.addClass('disabled').attr('disabled', true);

		};

		// Modal general functionality
		this.displayMobileModal = (e) => {
			if ($(e.target).attr('id').indexOf('filter-menu') >= 0) {
				objects.$persistentContainer.addClass('fixed');

				$('body').addClass('sticky-menu-open');
			}
		};

		this.hideMobileModal = (e) => {
			if ($(e.target).attr('id').indexOf('filter-menu') >= 0) {
				objects.$persistentContainer.removeClass('fixed');

				$('body').removeClass('sticky-menu-open');
			}
		};

		this.adjustRowHeight = () => {
			let newH = 'auto';

			if(utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md') {
				newH = $(window).height();
			} else {
				$('body').removeClass('sticky-menu-open');
			}

			objects.$filterRow.css('height', newH);
		};

		/**
		 * Extracts the actual data values from the cell, used as a key into the column filters
		 * If last node of the cell is text, use that as the content, else use the previous node (probably a ul)
		 * @param td
		 * @returns {*|jQuery}
		 */
		this.extractCellData = (td) => {
			let nodes = td.contents();

			for (let i = nodes.length - 1; i >= 0; i--) {
				if (nodes[i].nodeType === 3) {
					let text = $(nodes[i]).text().trim();

					if (text.length > 0) {
						return text;
					}
				}

				if (nodes[i].nodeType === 1
					&& (nodes[i].nodeName === 'UL' || nodes[i].nodeName==='DIV') ){
						return $(nodes[i]).clone().wrap('<p/>').parent().html();
				}
			}
		};

		/**
		 * Build a 'virtual' table where comparisons are checked via simple values and native types
		 * rather than string comparisons and the DOM
		 */
		this.buildVirtualTable = () => {
			let that = this;
			let indexValueIncrementer = {};

			for (let i = 0; i < numDropdowns; i++) {
				indexValueIncrementer[i] = 0;
				_vtable.filterNames[i] = [];
				_vtable.filterValues[i] = {};
			}

			objects.$results.each(function(i) {
				let tr = $(this);
				let values = [];

				tr.find('td[data-filter-index]').each(function() {
					let td = $(this);
					let index = td.data('filter-index');
					let data = that.extractCellData(td);

					if (data != null) {
						let key = data.toLowerCase();

						// If we never encountered this value before, create a new substitute value
						if (_vtable.filterValues[index][key] == null) {
							_vtable.filterValues[index][key] = indexValueIncrementer[index];
							_vtable.filterNames[index].push(data);
							indexValueIncrementer[index] = indexValueIncrementer[index] + 1;
						}

						values[index] = _vtable.filterValues[index][key];
					}
				});

				values[numDropdowns] = tr;
				_vtable.rows[i] = values;
			});
			//console.log("After buildVirtualTable, stuff looks like this:");
			//console.log("_vtable: ");
			//console.log(_vtable);

		};

		function naturalSorter(as, bs){
			var a, b, a1, b1, i= 0, n, L,
				rx=/(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
			if(as=== bs) return 0;
			a= as.toLowerCase().match(rx);
			b= bs.toLowerCase().match(rx);
			L= a.length;
			while(i<L){
				if(!b[i]) return 1;
				a1= a[i],
					b1= b[i++];
				if(a1!== b1){
					n= a1-b1;
					if(!isNaN(n)) return n;
					return a1>b1? 1:-1;
				}
			}
			return b[i]? -1:0;
		}
        
        function _anyNumberSort(a, b, high) {
                var aOrig = a;
                var bOrig = b;
                var reg = /[+-]?((\d+(\.\d*)?)|\.\d+)([eE][+-]?[0-9]+)?/;
                a = a.replace(',', '.').match(reg);
                a = a !== null ? parseFloat(a[0]) : high;
                b = b.replace(',', '.').match(reg);
                b = b !== null ? parseFloat(b[0]) : high;
                return a < b ? -1 : a > b ? 1 : naturalSorter(aOrig, bOrig);
        }
        DataTable.ext.type.order['any-number-asc'] = function (a, b) {
            return _anyNumberSort(a, b, Number.POSITIVE_INFINITY);
        };
        DataTable.ext.type.order['any-number-desc'] = function (a, b) {
            return _anyNumberSort(a, b, Number.NEGATIVE_INFINITY) * -1;
        };

		/**
		 * Populate dropdown li's from values present in the table
		 */
		this.buildFilterMarkup = () => {

			// console.log('buildFilterMarkup');

			for (let i=0; i < _vtable.filterNames.length; i++) {
				_vtable.filterNames[i] = _vtable.filterNames[i].sort(naturalSorter);

				for (let j=0; j < _vtable.filterNames[i].length; j++) {
					$($(objects.$filterCollapseDropdowns[i]).find('ul')[0]).append(this.createDropdownOption(i, j));
				}
			}
		};

		/**
		 * Create a dropdown option from a vtable entry
		 * @param i filter index
		 * @param j filter value index
		 * @returns {*}
		 */
		this.createDropdownOption = (i, j) => {

			// console.log('createDropdownOption');

			let id = `filter-${i}-option-${j}-${matrixId}`;
			let key = _vtable.filterNames[i][j].toLowerCase();

			let li = $('<li>');
			let input = $('<input>')
				.prop('type', 'checkbox')
				.prop('id', id)
				.prop('autocomplete','off')
				.addClass('custom-check')
				.val(_vtable.filterValues[i][key]);
			let label = $('<label>')
				.prop('for', id)
				.html(_vtable.filterNames[i][j]);

			return li.append(input).append(label);
		};

		this.initFilterList = () => {
			// Init functions on load
			this.adjustRowHeight();

			// Events
			objects.$toggleBtns.on('click', this.toggleView);
			objects.$el.find('.filters').find('[type="checkbox"]').on('change', this.toggleCheckedFilters);
			objects.$resetBtn.on('click', this.resetFilters);

			// Custom clear filter event dom ready
			$(document).on('click', '[data-uncheck-filter]', function(e) {
				let $thisFilterLi = $(e.target).parents('li');

				$(e.target).closest('div')
					.find('[data-filter-index]')
					.find('[type="checkbox"][value="' + $thisFilterLi.data('filter-value') + '"]')
					.prop('checked', false)
					.change();

				$thisFilterLi.remove();
			});

			if(utils.getViewportSize() === 'lg' || utils.getViewportSize() === 'xl' || utils.getViewportSize() === 'xxl') {
				$('.pos-r').on('mouseleave', (e) => {
					objects.$filterCollapseDropdowns.collapse('hide');
				});
			}

			// Modal functionality
			objects.$filterMenu.on('show.bs.collapse', (e) => {this.displayMobileModal(e)});
			objects.$filterMenu.on('hidden.bs.collapse', (e) => {this.hideMobileModal(e)});

			// let savedFilters = [];
			// if(sessionStorage.getItem('savedFilters') !== null){
			// 	savedFilters = sessionStorage.getItem('savedFilters').split(',');
			// }

			// objects.$el.find('.filters').find('[type="checkbox"]').each(function(){
			// 	//click objects that are stored in sessionStorage
			// 	if($(this).parent().find('.filter-item')[0] !== undefined && savedFilters.indexOf($.trim($(this).parent().find('.filter-item')[0].innerHTML)) >= 0){
			// 		$(this).click();
			// 	}
			// });

			utils.listeners({
				resize: () => {
					this.adjustRowHeight();
					_viewport.reset();
				}
			});

			// Initially sort on first visible header

			let indexNumber = -1;
			let headerArray = objects.$el.find('th').toArray();
			for(var i = 0; i < headerArray.length; i++) {
				if($(headerArray[i]).is(':visible')) {
					indexNumber = i;
					break;
				}
			}
			let firstVisible = indexNumber;

			if (firstVisible < 0) {
				firstVisible = 1;
			}

		};

		/**
		 * Add/remove from the array of active filters based on if an event target is checked
		 * @param e
		 */
		this.modifyFilters = (e) => {
			let val = parseInt(e.val());
			let index = e.closest('[data-filter-index]').data('filter-index');

			if (_filters.applied[index] == null) {
				_filters.applied[index] = [];
			}

			if (e[0].checked) {
				_filters.applied[index].push(val);
			}

			else {
				_filters.applied[index] = _filters.applied[index].filter(filter => filter !== val);
			}
		};

		/**
		 * Updates the status (disabled or not) of the filter checkboxes based on if selecting them
		 * would bring back items or not
		 */
		this.updateFilterStatus = (checkedValue) => {
			let cached = [];
			let that = this;

			let checkedVal = -1;
			let checkedIndex = -1;

			if(checkedValue) {
				checkedVal = parseInt(checkedValue.val());
				checkedIndex = checkedValue.closest('[data-filter-index]').data('filter-index');
			}

			objects.$el.find('.filters').find('[type="checkbox"]').each(function () {
				let index = $(this).closest('div').data('filter-index');

				if(index != checkedIndex) {
					// We only need to evaluate filters again once
					// per column with no choices enabled on it
					// Clone existing filters
					let filters = JSON.parse(JSON.stringify(_filters.applied));
					// Remove this column
					filters[index] = [];

						cached[index] = that.evaluateFilters(filters);

					let val = parseInt($(this).val());

					let disable = !$(this).prop('checked') && !cached[index].some(function(row) {
						return row[index] === val;
					});

					$(this).prop('disabled', disable);

					if (disable) {
						$(this).parent('li').addClass('disabled');
					} else {
						$(this).parent('li').removeClass('disabled');
					}

					if (disable) {
						if (!$(this).prop('checked')) {
							$(this).prop('checked', false);
						}

						// This is commented out, as we don't want to exclude filters currently applied
						//if (_filters.applied[index] != null) {
						//	_filters.applied[index] = _filters.applied[index].filter(filter => filter !== val);
						//}
					}
				}
			});
		};

		/**
		 * Takes an array of filters and filters results against them, returning the filtered results
		 * @param filters
		 * @returns {*|Array|jQuery}
		 */
		this.evaluateFilters = (filters) => {
			let filteredItems = _vtable.rows;

			// For each column, the filters are additive, otherwise they're subtractive if in adjacent columns
			filters.forEach(function (columnFilter, i) {

				if (columnFilter != null && columnFilter.length > 0) {
					let results = [];

					columnFilter.forEach(function (valueFilter) {
						results =
							results.concat(
								filteredItems.filter(function (row) {
									return row[i] === valueFilter;
								})
							);
					});

					filteredItems = results;
				}
			});

			return filteredItems;
		};

		this.filter = (checkedValue) => {

			//console.log("About to filter.");
			//console.log("filters applied is: ");
			//console.log(_filters.applied);
			_filters.items = this.evaluateFilters(_filters.applied);
			_viewport.reset();

			const isEmpty = (array) => {
				return Array.isArray(array) && (array.length == 0 || array.every(isEmpty));
			};

			//* handle reset button state
			if(!isEmpty(_filters.applied)) {
				objects.$resetFilters.removeClass('disabled').removeAttr('disabled');
				objects.$productSearchWrap.removeClass('open');
				objects.$productSearchWrap.find('input').val('');
			} else {
				objects.$resetFilters.addClass('disabled').attr('disabled', true);
			}

			//console.log("filters items is: ");
			//console.log(_filters.items);

			this.updateCount();
			this.updateFilterCount();
			this.updateFilterStatus(checkedValue);
			this.showResults();
			this.positionPsdWidget();
		};

		this.positionPsdWidget = () => {
			if (objects.$psdWidget.find('.power-system-designer').length <= 0) {
				objects.$psdWidget.hide();

				return;
			}

			let visibleRows = objects.$results.filter(':visible');

			if (visibleRows.length < 7) {
				$(visibleRows[visibleRows.length - 1]).after(objects.$psdWidget.detach()[0]);
			}

			else {
				$(visibleRows[_viewport.psdSlot]).after(objects.$psdWidget.detach()[0]);
			}
		};

		this.updateCount = () => {
			
			if(initialLoad) {
				objects.$productCount.text(_filters.items.length);
				initialLoad = false;
			}

			if (_viewport.maxResults >= _filters.items.length) {
				objects.$loadMoreBtn.addClass('d-none');
				objects.$loadMoreBtnContainer.addClass('spacer');
				if(!initialLoad) {
					//$('html, body').animate({scrollTop: (objects.$productCount.filter(':visible').offset().top + objects.$productCount.filter(':visible').outerHeight(true) - 30)}, 500);
				}
			}

			else {
				objects.$loadMoreBtn.removeClass('d-none');
				objects.$loadMoreBtnContainer.removeClass('spacer');
				initialLoad = false;
			}
		};

		this.initLoadMore = () => {
			let that = this;

			objects.$loadMoreBtn.click(function(e) {
				// _viewport.maxResults += _viewport.loadMoreSize;
				_viewport.maxResults = _filters.items.length;

				if($(this).is('[data-first-load]')) {
					$(this).hide();
					$('[data-loaded]').removeClass('d-none');

					$('html, body').animate({
						scrollTop: (objects.$psdWidget.offset().top) + (objects.$psdWidget.outerHeight(true) - 48)
					}, 500);
				}

				/*
				for (let i = _viewport.maxResults - _viewport.loadMoreSize; i < _viewport.maxResults; i++) {
					if (_filters.items[i] != null) {
						_filters.items[i][numDropdowns].show();
					}
				}
				*/
				// Replacing old 'load more' logic with 'load all' logic

				for (let i = 0; i < _filters.items.length; i++) {
					if (_filters.items[i] != null) {
						_filters.items[i][numDropdowns].show();
					}
				}

				that.updateCount();
				// console.log('Triggering Table Sort');
				// $('.tablesorter').trigger('sort');
			});
		};

		let dimensionsHidden = false;

		const handleUoM = (table, dimensionsHidden) => {

			// console.log('handleUoM');

			var columnMm = table.column('.column-dimensions-mm');
			var columnIn = table.column('.column-dimensions-in');

			if (dimensionsHidden) {

				// console.log('handleUoM dimensionsHidden');
				columnIn.visible(false);
				columnMm.visible(false);

			} else if (objects.$uomSwitch.is(":checked")) {
				$.when(columnIn.visible(false)).then(function() {
					columnMm.visible(true);

					$('.filter-column-dimensions-in').addClass('d-none');
					$('.filter-column-dimensions-mm').removeClass('d-none');
					$('.filter-column-dimensions-in').find('.clear-filters').click();
				});

			} else {
				$.when(columnMm.visible(false)).then(function() {
					columnIn.visible(true);
					$('.filter-column-dimensions-mm').addClass('d-none');
					$('.filter-column-dimensions-in').removeClass('d-none');
					$('.filter-column-dimensions-mm').find('.clear-filters').click();
				});
			}
		};

		const hideColumns = (table) => {
			objects.$columnSelectModal.find('[type="checkbox"]').each(function() {
				let columnName = $(this).attr('data-filter-target');

				if (!$(this).prop('checked')) {
					if (columnName == 'column-dimensions') {
						dimensionsHidden = true;
					}
					table.column('.' + columnName).visible(false);
				} else {
					if (columnName == 'column-dimensions') {
						dimensionsHidden = false;
					}
					table.column('.' + columnName).visible(true);
				}
			});
			handleUoM(table, dimensionsHidden);
		};

		const handleFilterColumns = (table) => {
			objects.$columnSelectModal.find('[type="checkbox"]').each(function() {
				let columnName = $(this).attr('data-filter-target');
				let filterTarget = '.filter-' + columnName;

				if (!$(this).prop('checked')) {
					$(filterTarget).hide();
					$(filterTarget).find('.clear-filters').click();
				} else {
					$(filterTarget).show();
				}
			});
		};

		this.showResults = () => {
			objects.$results.removeClass('shown').addClass('hidden');

			objects.$productMatrixDatatable.hide();

			objects.$searchProducts.val(objects.$initialSearchTerm);
			objects.$contentBasedMatrixSearch.val(objects.$initialSearchTerm);

			// for (let i = 0; i < _viewport.maxResults; i++) {
			// 	if (_filters.items[i] != null) {
			// 		_filters.items[i][numDropdowns].show();
			// 	}
			// }

			for (let i = 0; i < _filters.items.length; i++) {
				if (_filters.items[i] != null) {
					_filters.items[i][numDropdowns].removeClass('hidden').addClass('shown');
				} else {
					_filters.items[i][numDropdowns].removeClass('shown').addClass('hidden');
				}
			}

			$.fn.dataTableExt.afnFiltering.push(
				function(oSettings, aData, iDataIndex) {
					var row = oSettings.aoData[iDataIndex].nTr;
					return $(row).hasClass('hidden') ? false : true;
				}
			);

			let table = objects.$productMatrixDatatable.DataTable({
				"destroy": true,
				dom: 'rt',
				columnDefs: [
					{ orderable: false, targets: [-1, 2]},
					{ "searchable": false, "targets": 2 },
					{ type: 'any-number', targets : "_all" }
				],
				"pageLength": 25
			});

			$.when(handleFilterColumns(table)).then(function() {
				hideColumns(table);
				objects.$productMatrixDatatable.show();
			});

			objects.$uomSwitch.unbind().on('change', function(e) {
				objects.$resetFilters.removeClass('disabled').attr('disabled', false);
				handleUoM(table, dimensionsHidden);
			});

			objects.$searchProducts.unbind().keyup(function() {
				$.when(table.search($(this).val()).draw()).then(function(){
					objects.$totalPages.html(table.page.info().pages);
					objects.$numberOfRecords.html(table.page.info().recordsDisplay);
					objects.$productCount.html(table.page.info().recordsDisplay);
				});
			});

			let info = table.page.info();

			if (info) {
				objects.$currentPage.val(info.page + 1);
				objects.$totalPages.html(info.pages);
				objects.$numberOfRecords.html(info.recordsDisplay);

				objects.$productCount.html(info.recordsDisplay);
			}

			let page = 0;

			table.on("order.dt", function() {
				objects.$resetFilters.removeClass('disabled').attr('disabled', false);

				if (table.page() !== page) {
					$.when(table.page(page).draw('page')).then(function() {
						handlePageButtonState(table.page.info());
					});
				}
			});

			table.on("page", function() {
				page = table.page();
			});

			const handlePageButtonState = (info) => {
				if (info.page + 1 == info.pages) {
					objects.$pageNext.addClass('inactive');
					objects.$pageLast.addClass('inactive');
				} else {
					objects.$pageNext.removeClass('inactive');
					objects.$pageLast.removeClass('inactive');
				}

				if (info.page == 0) {
					objects.$pageFirst.addClass('inactive');
					objects.$pagePrev.addClass('inactive');
				} else {
					objects.$pageFirst.removeClass('inactive');
					objects.$pagePrev.removeClass('inactive');
				}
			};

			objects.$currentPage.keypress(function (e) {
				let info = table.page.info();
				let inputVal = $(this).val();

				if (e.which == 13) {
					if (inputVal > info.pages) {
						table.page(info.pages - 1).draw( 'page' );
						$(this).val(info.pages);
					} else if (inputVal <= 0) {
						table.page(0).draw( 'page' );
						$(this).val(1);
					} else {
						table.page(inputVal - 1).draw( 'page' );
					}
					return false;
				}

				handlePageButtonState(info);
			});

			// pagination
			// $('#page-length').unbind().on('change', function() {
			// 	let pageLength = $(this).find(':selected').val() * 1;
			// 	table.page.len(pageLength).draw();
			// 	let info = table.page.info();
			// 	objects.$currentPage.val(info.page + 1);
			// 	objects.$totalPages.html(info.pages);

			// 	handlePageButtonState(info);
			// });

			if ($('.page-select-wrapper')[0]){
				$('.page-select-wrapper').unbind().click(function(e) {
					this.querySelector('.page-select').classList.toggle('open');
				});
			}

			objects.$pageLengthOption.unbind().click(function(e) {
				if (!this.classList.contains('selected')) {
					let pageLength = $(this).attr('data-value');
					table.page.len(pageLength).draw();

					let info = table.page.info();
					objects.$currentPage.val(info.page + 1);
					objects.$totalPages.html(info.pages);

					handlePageButtonState(info);

					$(this).parents('.page-length-options').find('.page-length-option.selected').removeClass('selected');
					$(this).addClass('selected');

					// console.log($(this).closest('.page-select'));
					// console.log(pageLength);

					$(this).closest('.page-select').find('.page-select__trigger span').text(pageLength);

					// scrollToResults();
				}
			});

			if ($('.page-select')[0]) {
				//**closes custom select when user clicks outside it */
				window.addEventListener('click', function(e) {
					const select = document.querySelector('.page-select');
					if (!select.contains(e.target)) {
						select.classList.remove('open');
					}
				});
			}

			objects.$pageNext.unbind().click(function(e) {
				table.page( 'next' ).draw( 'page' );
				let info = table.page.info();
				objects.$currentPage.val(info.page + 1);

				handlePageButtonState(info);
				// scrollToResults();
			});

			objects.$pageLast.unbind().click(function(e) {
				table.page( 'last' ).draw( 'page' );
				let info = table.page.info();
				objects.$currentPage.val(info.page + 1);

				if (info.page == info.pages) {
					$(this).addClass('inactive');
				} else {
					$(this).removeClass('inactive');
				}

				handlePageButtonState(info);
				// scrollToResults();
			});

			objects.$pagePrev.unbind().click(function(e) {
				table.page( 'previous' ).draw( 'page' );
				let info = table.page.info();
				objects.$currentPage.val(info.page + 1);

				handlePageButtonState(info);
				// scrollToResults();
			});

			objects.$pageFirst.unbind().click(function(e) {
				table.page( 'first' ).draw( 'page' );
				let info = table.page.info();
				objects.$currentPage.val(info.page + 1);

				handlePageButtonState(info);
				// scrollToResults();

			});

			//hide/show columns modal

			let checkedItems = [];

			objects.$openColumnSelectModal.unbind().click(function(e) {
				checkedItems = [];

				objects.$columnSelectModal.modal('show');

				objects.$columnSelectModal.find('[type="checkbox"]').each(function() {
					if ($(this).prop('checked')) {
						checkedItems.push($(this).attr('data-filter-target'));
					}
				});
			});

			objects.$saveColumnsButton.unbind().click(function(e) {
				e.preventDefault();

				let checkboxes = objects.$columnSelectModal.find('input').length;
				let checked = objects.$columnSelectModal.find('input:checked').length;


				if (checked < checkedFiltersForScreenWidth ) {
					objects.$resetFilters.removeClass('disabled').attr('disabled', false);
				}

				$.when(handleFilterColumns(table)).then(function() {
					hideColumns(table);
					handleShowScrollButtons();
					objects.$columnSelectModal.modal('hide');
				});
			});

			objects.$cancelSaveColumnsButton.unbind().click(function() {

				objects.$columnSelectModal.find('[type="checkbox"]').each(function() {
						$(this).prop('checked', false);
				});

				for (let i = 0; i < checkedItems.length; ++i) {
					objects.$columnSelectModal.find('.'+checkedItems[i]).prop('checked', true);
				}

				handleShowScrollButtons();
				objects.$columnSelectModal.modal('hide');
			});

			objects.$searchStateControl.unbind().click(function() {
				if($(this).parents('.product-search-wrap').hasClass('open')) {
					if($(this).parents('.product-search-wrap').find('input').val()) {
						$(this).parents('.product-search-wrap').find('input').val('');
						table.search('').draw();
					} else {
						$(this).parents('.product-search-wrap').removeClass('open');
					}
				} else {
					$(this).parents('.product-search-wrap').addClass('open');
				}
			});
		};


		this.updateFilterCount = () => {
			objects.$el.find('.filter-col-wrapper').each(function(i, obj) {
				let filterCount = $(this).children('.checked-filters').children('li').length;

				if (filterCount > 0) {
					$(this).find('.active-filter-count').removeClass('d-none').children('.count').html(filterCount);
					$(this).find('.active-filter-count-wrapper').removeClass('d-none');
				} else {
					$(this).find('.active-filter-count').addClass('d-none').children('.count').html(filterCount);
					$(this).find('.active-filter-count-wrapper').addClass('d-none');
				}
			});
		};

		objects.$clearFilters.click(function() {
			let filters = $(this).parents('.filter-col-wrapper').find('.filter-list').children('li');

			filters.each(function(i, obj) {
				let checkbox = $(this).find('input');

				if (checkbox.is(':checked')) {
					$(this).find('[type="checkbox"]').click();
				}
			});
		});

		objects.$scrollButtonLeft.click(function(){
			var leftPos = objects.$filters.scrollLeft();
			objects.$filters.animate({scrollLeft: leftPos - 200}, 500);
		});

		objects.$scrollButtonRight.click(function(){
			var leftPos = objects.$filters.scrollLeft();
			objects.$filters.animate({scrollLeft: leftPos + 200}, 500);
		});

		objects.$expandFiltersButton.click(function(){
			if(!objects.$filtersWrapper.hasClass('expanded')) {
				$(this).find('.collapse-text').removeClass('d-none');
				$(this).find('.expand-text').addClass('d-none');
				$(this).find('.icon-down-arrow').removeClass('icon-down-arrow').addClass('icon-up-arrow');
				objects.$filtersWrapper.addClass('expanded');
			} else {
				$(this).find('.expand-text').removeClass('d-none');
				$(this).find('.collapse-text').addClass('d-none');
				$(this).find('.icon-up-arrow').removeClass('icon-up-arrow').addClass('icon-down-arrow');
				objects.$filtersWrapper.removeClass('expanded');
			}

			handleShowScrollButtons();
		});

		// const scrollToResults = () => {
		// 	$('html,body').animate({
		// 		scrollTop: $("#product-matrix-pagination").offset().top
		// 	}, 'slow');
		// };

		const handleShowScrollButtons = () => {
			let width = objects.$filters.outerWidth();
			let scrollWidth = objects.$filters[0].scrollWidth;

			objects.$scrollButtonLeft.css({visibility: 'hidden'});
			objects.$scrollButtonRight.css({visibility: 'hidden'});

			if (scrollWidth > width) {
				objects.$scrollButtonRight.css({visibility: 'visible'});
			}
		};

		const handleShowHideFilters = () => {
			let rowCount = objects.$productMatrixDatatable.find('tr').length;

			if (rowCount < 25 && _filters.applied <= 0) {
				objects.$serviceBasedAccessoriesTable.find('.filters-wrapper').hide();
				objects.$serviceBasedAccessoriesTable.find('.expand-filters-button').remove();
				objects.$serviceBasedAccessoriesTable.find('.reset-filters').hide();
				objects.$serviceBasedAccessoriesTable.find('.edit-filters').hide();
				objects.$serviceBasedAccessoriesTable.find('.filters-title').hide();
				objects.$serviceBasedAccessoriesTable.find('.uom').hide();
				objects.$serviceBasedAccessoriesTable.find('.product-matrix-records-info').hide();
				objects.$serviceBasedAccessoriesTable.find('.product-matrix-page-controls').hide();
				objects.$serviceBasedAccessoriesTable.find('.filter-container').hide();
			}
		};

		const handleHideShowColumnsOnSmallerScreens = () => {
			if ($(window).width() > 1440) {
				objects.$columnSelectModal.find('ul').find('li:last-child').find('.custom-check').prop('checked', true);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(2)').find('.custom-check').prop('checked', true);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(3)').find('.custom-check').prop('checked', true);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(4)').find('.custom-check').prop('checked', true);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(5)').find('.custom-check').prop('checked', true);
				checkedFiltersForScreenWidth = objects.$columnSelectModal.find('.custom-check:checked').length;

				objects.$saveColumnsButton.click();
			} else if ($(window).width() > 1200 && $(window).width() < 1440 && $(window).width() > 990) {
				objects.$columnSelectModal.find('ul').find('li:last-child').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(2)').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(3)').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(4)').find('.custom-check').prop('checked', true);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(5)').find('.custom-check').prop('checked', true);
				checkedFiltersForScreenWidth = objects.$columnSelectModal.find('.custom-check:checked').length;

				objects.$saveColumnsButton.click();
			} else if ($(window).width() < 1200 && $(window).width() > 990) {
				objects.$columnSelectModal.find('ul').find('li:last-child').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(2)').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(3)').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(4)').find('.custom-check').prop('checked', false);
				objects.$columnSelectModal.find('ul').children('li:nth-last-child(5)').find('.custom-check').prop('checked', false);
				checkedFiltersForScreenWidth = objects.$columnSelectModal.find('.custom-check:checked').length;

				objects.$saveColumnsButton.click();
			}
		};

		const setTableHeaderTitleWidths = () => {
			$('.column-title').each(function() {
				$(this).css("width", 'auto');

				var titleWidth = $(this).width();
				var spanWidth = $(this).find('span').width() + 1;

				if (titleWidth > spanWidth) {
					$(this).css("width", spanWidth);
				}
			});
		};

		const debounce = (funcParam, timeParam) => {
			var time = timeParam || 100;
			var timer;
			return function(event) {
				if (timer) clearTimeout(timer);
				timer = setTimeout(funcParam, time, event);
			};
		};

		$(document).ready(function() {
			windowWidth = $(window).width();
			handleShowHideFilters();
			handleShowScrollButtons();
			handleHideShowColumnsOnSmallerScreens();
			objects.$serviceBasedAccessoriesTable.show();
			setTableHeaderTitleWidths();
			if(objects.$initialSearchTerm) {
				objects.$searchProducts.keyup();
				objects.$contentBasedMatrixSearch.keyup();
			}
		});

		$(window).on('resize', debounce(function() {
			if ($(window).width() != windowWidth) {
				handleShowScrollButtons();
				handleHideShowColumnsOnSmallerScreens();
				setTableHeaderTitleWidths();
			}
		}, 250));

		objects.$filters.scroll( function() {
			let width = objects.$filters.outerWidth();
			let scrollWidth = objects.$filters[0].scrollWidth;
			let scrollLeft = objects.$filters.scrollLeft();

			if (scrollLeft === 0){
				objects.$scrollButtonLeft.css({visibility: 'hidden'});
			} else {
				objects.$scrollButtonLeft.css({visibility: 'visible'});
			}

			if (scrollWidth - width === scrollLeft){
				objects.$scrollButtonRight.css({visibility: 'hidden'});
			} else {
				objects.$scrollButtonRight.css({visibility: 'visible'});
			}
		});

		this.handleContentBasedMatrix = () => {
			var $rows = objects.$contentBasedMatrix.find('tbody tr');

			objects.$contentBasedMatrixSearch.keyup(function() {

				var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

				$rows.show().filter(function() {
					var text = $(this).find('.filter-item').text().replace(/\s+/g, ' ').replace(/\u2013|\u2014/g, "-").toLowerCase();
					return !~text.indexOf(val);
				}).hide();

				// var val = '^(?=.*\\b' + $.trim($(this).val()).split(/\s+/).join('\\b)(?=.*\\b') + ').*$',
				// 	reg = RegExp(val, 'i'),
				// 	text;

				// 	console.log(val);

				// $rows.show().filter(function() {
				// 	text = $(this).text().replace(/\s+/g, ' ');
				// 	return !reg.test(text);
				// }).hide();
			});

			let indexNumber = -1;
			let headerArray = objects.$contentBasedMatrix.find('th').toArray();

			for(var i = 0; i < headerArray.length; i++) {
				if($(headerArray[i]).is(':visible')) {
					indexNumber = i;
					break;
				}
			}
			let firstVisible = indexNumber;

			if (firstVisible < 0) {
				firstVisible = 1;
			}

			objects.$contentBasedMatrix.tablesorter({
				cssIcon: 'tablesorter-icon',
				headerTemplate: '<div class="column-title"><span>{content}</span></div><div style="display:inline-block; margin-left: 4px; margin-right: 12px;">{icon}</div>',
				sortRestart: true,
				sortList: [[firstVisible, 1]],
				headers: {
					'.add-to-cart' : {
						sorter: false
					}
				}
			});

			let rowCount = objects.$contentBasedMatrix.find('tr').length;

			if (rowCount < 25 && _filters.applied <= 0) {
				var filterContainer = objects.$contentBasedAccessoriesTable.find('.filter-container');

				filterContainer.hide();
			}
		};
	}

	init() {
		this.buildVirtualTable();
		this.buildFilterMarkup();
		this.initFilterList();
		this.initLoadMore();
		this.filter();
		this.handleContentBasedMatrix();
	}
}

export default FilterList;

class AutoComplete {

	static name() {
		return 'AutoComplete';
	}

	constructor(props, el, $, Utils) {

		let searchResult = null;

		const objects = {
			$el: $(el),
			$searchTerm: $(el).find('.js-search-term'),
			$searchTermMobile: $(el).find('.js-search-term-mobile'),
			$partSearchUrl: $(el).data('part-search-url'),
			$siteSearchUrl: $(el).data('site-search-autosuggest-url'),
			$familyLookupUrl: $(el).data('family-lookup-url'),
			$showOnlyParts: $(el).data('show-only-parts'),
			$searchBarContainer: $(el).find('.search-wrapper'),
			$formControl: $(el).find('.form-control'),
			$searchSuggestionsContainer: function() {return $(el).find('.search-suggestions')}, // Doesn't exist
			$noResultsContainer: function() {return $(el).find('.no-results-container')},				// Doesn't exist
			$noResultsDropdown:	function() {return $(el).find('.no-results-dropdown')},					// Doesn't exist
			$noResultsMessage:	function() {return $(el).find('.no-results-message')},					// Doesn't exist
			$noSuggestionsContainer: function() {return $(el).find('.no-suggestions-container')},		// Doesn't exist
			$formId: $(el).data('form-id'),
		};
		
		this.hideNoResults = () => {
			objects.$noResultsContainer().remove();
			objects.$noResultsDropdown().remove();
			objects.$noSuggestionsContainer().remove();			
		};
		
		this.syncSearchTerms = () => {
			let searchTerm = objects.$searchTerm.val();
			if (!searchTerm) {
				searchTerm = objects.$searchTermMobile.val();
				objects.$searchTerm.val(objects.$searchTermMobile.val());			
			}		
			return searchTerm;	
		};

		this.performSearch = () => {
			let searchTerm = this.syncSearchTerms();
			if (!searchTerm) {
				// #20785 want to go to the search page on blank criteria
				$(location).attr("href", objects.$siteSearchUrl);
			}
			this.parseSearch(searchTerm);
			/*if (!parsePart(searchTerm, '<c:url value="${url.server}"/>', '<fmt:message key="vi_productPagePath"/>')) {
				if (searchTerm.indexOf("\"") == 0 && searchTerm.lastIndexOf("\"") == searchTerm.length - 1) {
					$('#search-form input[name="src_terms[0].match"]').val("exact_phrase");
				}
				else {
					$('#search-form input[name="src_terms[0].match"]').val("all_words");
				}
				$("#search-form").submit();
			}*/
		};

		this.runSearchForm = () => {
			this.syncSearchTerms();
			let formId = "search-form";
			if(objects.$formId) {
				formId = objects.$formId;
			}
			// Grab element from document - mobile hack
			document.getElementById(formId).submit();
		};

		this.parseSearch = (searchTerm) => {
			//console.log("Search Term = " + searchTerm);

			if(searchTerm.length < 3) {
				objects.$searchSuggestionsContainer().hide();
			}
			if(searchTerm.length === 3 || searchTerm.length > 3 && searchTerm.length < 6) {
				searchResult = null;
				this.autoSuggestAjax(searchTerm);
			}
			let searchRegEx = /[A-Za-z]{5}/;
			if (!searchRegEx.test(searchTerm)) {
				let partRegEx = /[0-9|A-Za-z|-]{5}/;
				//console.log("Result = " + partRegEx.test(searchTerm));
				if (partRegEx.test(searchTerm)) {
					this.partsSearchAjax(searchTerm);
				}
			}else{
				//$("#search-form").submit();
				searchResult = null;
				this.autoSuggestAjax(searchTerm);
			}
				
		};
		// TEST
		this.toggleFormControlBorder = () => {
			if (objects.$searchSuggestionsContainer().css('display') === 'block' || objects.$noSuggestionsContainer().css('display') === 'block') {
				objects.$formControl.addClass('no-border-bottom');
			} else {
				objects.$formControl.removeClass('no-border-bottom');
			}
		};		

		this.partsSearchAjax = (searchTerm) => {
			let client = objects.$partSearchUrl + '?searchTerm=' + searchTerm;
			$.ajax({
				url: client,
				method: 'POST',
				async: true,
				beforeSend: function (xhr) {
					if (xhr && xhr.overrideMimeType) {
						xhr.overrideMimeType('application/json;charset=utf-8');
					}
				},
				dataType: 'json'
			})
			.done((res) => {
				// clear results
				if (objects.$searchSuggestionsContainer().length > 0) {
					objects.$searchSuggestionsContainer().remove();
					this.hideNoResults();
				}

				const products = res.products;
				searchResult = products;
				let currentProduct = null;
				if (products.length === 0) {
					// No results found, display message if not already displayed
					if (objects.$noResultsMessage().length === 0) {
						const noResultsMessage = '<div class="no-results-container search-suggestions" id="search-suggestions-wrapper"><li class="no-results-message">Part number not found. Try a partial part number search to see suggestions or change the part number.</li>';
						const seeMoreResultsButton = '<li class="no-results-dropdown" role="menuitem"><button type="submit" class="dropdown-item font-class searchSubmit" id="seeMoreResultsBtn"><span class="searchSubmit">SEE MORE RESULTS</span></button></li></div>';
						objects.$searchBarContainer.append(noResultsMessage + seeMoreResultsButton);
						objects.$searchBarContainer.show();
						objects.$searchBarContainer.attr('aria-expanded', true);
					}
				} else {
					// Results found, remove any existing message and display search results
					this.hideNoResults();
					const familyLookupUrl = objects.$familyLookupUrl +'?family=';

					let loopSize = Math.min(products.length, 5);
					let resultsHTML = $('<ul class="search-suggestions search-bottom" id="search-suggestions-wrapper"> </ul>');
					
					for (let i = 0; i < loopSize; i++) {
						currentProduct = products[i];

						// Highlight the search term in the product id
						let highlightedProductId = currentProduct.productId.replace(new RegExp(searchTerm, 'gi'), '<span class="highlight">$&</span>');

						let linksHTML = "";
						if (products.length < 2) {
							linksHTML = '<div><a class="product-link" href="' +
								currentProduct.datasheetUrl + '">Data Sheet</a>';
								if(currentProduct.dynamic === 'Y') {
									linksHTML = linksHTML + '<a class="product-link" href="' +
										currentProduct.productPageUrl + '">Product page</a>';
								}
								linksHTML = linksHTML + '</div>';
						}
						let productLinkHref = "";
						if(currentProduct.dynamic === 'Y') {
							productLinkHref = currentProduct.productPageUrl;
						} else {
							productLinkHref = familyLookupUrl + currentProduct.family;
						}

						// Check if product specifications are not null and append it to productSpecifications, otherwise add a blank space
						let productSpecifications = '';
						productSpecifications += currentProduct.vinNom !== null ? currentProduct.vinNom + 'Vin ' : ' ';
						productSpecifications += currentProduct.voutNom !== null ? currentProduct.voutNom + 'Vout ' : ' ';
						productSpecifications += currentProduct.powerOut !== null ? currentProduct.powerOut + 'W' : ' ';

						// Create the list item (li) HTML for the product, including the product ID and specifications
						let li = '<li class="parts-results"><a class="js-everything" href="' + productLinkHref + '" data-category>' +
							'<div class="product-id">' + highlightedProductId + '</div>' +
							'<div class="product-information">' +
							'<span class="product-specification">' + productSpecifications.trim() + '</span>' +
							'</div>' +
							'</a></li>';

						// Append the product list item to the resultsHTML element
						resultsHTML.append(li);

						// If there are additional links HTML, create and append a separate list item for them
						if (linksHTML) {
							let linksLi = '<li class="product-links">' + linksHTML + '</li>';
							resultsHTML.append(linksLi);
						}
					}

					objects.$searchBarContainer.append(resultsHTML);
					// Check if products length is less than 2 to replace the header
					const viewAllHeader = products.length < 2 ?
						'<li class="suggested-links" role=menuitem"><span>&nbsp;Family page</span></li>' :
						'<li class="suggested-links" role=menuitem"><span>&nbsp;Suggested links</span></li>';
					objects.$searchSuggestionsContainer().append(viewAllHeader);

					const viewAll = '<div class="view-all-container"><a class="view-all-container" href="' + familyLookupUrl + currentProduct.family + '&term=' + searchTerm + '"><span class="view-all">See all<b> ' + searchTerm +
						'</b> in the family page</span></a></div>';
					objects.$searchSuggestionsContainer().append(viewAll);
				}
			})
			.fail((err) => {
				console.log('Error getting search results: ', err);
			})
			.always(() => {
				// Additional actions to perform always
			});
		};

		this.autoSuggestAjax = (searchTerm) => {
			let client = objects.$siteSearchUrl + '?searchTerm='+searchTerm;
			$.ajax({
				url: client,
				method: 'GET',
				async: true,
				beforeSend: function (xhr) {
				if (xhr && xhr.overrideMimeType) {
					xhr.overrideMimeType('application/json;charset=utf-8');
				}
				},
				dataType: 'json'
			})
			.done((res) => {
				// Remove the "No results" message if it exists
				this.hideNoResults();
				// clear results
				if (objects.$searchSuggestionsContainer().length > 0) {
					objects.$searchSuggestionsContainer().remove();
				}

				const suggestions = res.suggestions;
				if (!suggestions || suggestions.length === 0) {
					// Display "no results" container
					const noSuggestionsContainer = $(
						'<div class="no-suggestions-container">' +
							'<div>No results found for <span class="highlight">' + searchTerm + '</span></div>' +
							'<div  class="suggestions-container"> <div class="suggestion">Suggestions:</div>' +
								'<li>Make sure all words are spelled correctly.</li>' +
								'<li>Try different keywords.</li>' +
								'<li>Try more general words.</li>' +
								'<li>Try fewer keywords.</li>' +
							'</div>' +
						'</div>'
						);
					objects.$searchBarContainer.append(noSuggestionsContainer);
				} else {
					if (suggestions && suggestions.length > 0) {
						let loopSize = 5;
						if (suggestions.length < loopSize) {
							loopSize = suggestions.length;
						}
						let resultsHTML = $('<ul class="search-suggestions" id="search-suggestions-wrapper"> </ul>');
						for (let i = 0; i < loopSize; i++) {
							let currentSuggestion = suggestions[i];
							// Highlight search term for title
							let highlightedTitle = currentSuggestion.value.replace(new RegExp(searchTerm.replace(/ /g, '[\\s]?'), 'gi'), '<span class="highlight">$&</span>');
							// Highlight search term for description
							let highlightedDescription = currentSuggestion.description ? currentSuggestion.description.replace(new RegExp(searchTerm.replace(/ /g, '[\\s]?'), 'gi'), '<span class="highlight">$&</span>') : '';
							// Remove any unwanted <p> tags
							highlightedDescription = highlightedDescription.replace(/<\/?p[^>]*>/g, '');

							let liClass = 'suggestions-results-details';
							let descriptionClass = currentSuggestion.description ? '' : ' no-description';
							let li = '<li class="' + liClass + '"><a class="product-id js-everything" href="' + currentSuggestion.href + '" title="'
								+ currentSuggestion.category + ' - ' + currentSuggestion.value + '" ><div class="category">' + currentSuggestion.category + '</div>'
								+ '<div class="title">' + highlightedTitle + '</div>'
								+ '<div class="description' + descriptionClass + '">' + highlightedDescription + '</div>'
								+ '</a></li>';
							resultsHTML.append(li);
						}
						objects.$searchBarContainer.append(resultsHTML);
						// TEST Expand the wrapper
						// expandSearchWrapper();
					}
				}
			})
			.fail((err) => {
				console.log('Error getting site search results: ', err);
			})
			.always(() => {

				let viewAll = '<li" role="menuitem"><button type="submit" class="dropdown-item font-class searchSubmit"><span class="searchSubmit">SEE MORE RESULTS</span></button></li>';
				objects.$searchSuggestionsContainer().append(viewAll);
				objects.$searchSuggestionsContainer().show();
				objects.$searchSuggestionsContainer().attr('aria-expanded', true);
				// TEST Expand the wrapper
				// expandSearchWrapper();
			});
		};

		// TEST TO ADD CURTAIN LIKE FUNCTIONALITY FOR RESULTS 
		// NOT WORKING PROPERLY BC THE #search-suggestions-wrapper KEEPS RE-CREATING ITSELF FOR EACH KEY

		// function expandSearchWrapper() {
		// 	const wrapper = document.getElementById('search-suggestions-wrapper');
		// 	if (wrapper) {
		// 		wrapper.classList.add('expand');
		// 		wrapper.style.height = 'auto'; // Temporarily set to auto to calculate the full height
		// 		const height = wrapper.scrollHeight + 'px'; // Get the full height
		// 		wrapper.style.height = '0px'; // Reset to 0 to trigger the transition
		// 		setTimeout(() => {
		// 			wrapper.style.height = height; // Set to the full height for expansion
		// 		}, 10); // Small delay to ensure the reset to 0 height is applied
		// 	}
		// }

		// function collapseSearchWrapper() {
		// 	const wrapper = document.getElementById('search-suggestions-wrapper');
		// 	if (wrapper) {
		// 		wrapper.style.height = '0px';
		// 		wrapper.classList.remove('expand');
		// 	}
		// }

		// document.addEventListener("DOMContentLoaded", function(event) {
		// 	$("#searchTerm").on('keyup', function(evt) {
		// 		let searchTerm = $(evt.currentTarget).val();
		// 		if (searchTerm.length < 3) {
		// 			collapseSearchWrapper();
		// 		} else {
		// 			parseSearch(searchTerm);
		// 		}
		// 	});
		// });
		
		this.onKeyupFunction = (evt) => {
			console.log("keyup");
			let searchTerm =  $(evt.currentTarget).val();
			this.parseSearch(searchTerm);

		};
		
		this.onKeyPressFunction = (event) => {
			if (event.which == 13 ) {
				event.preventDefault();
				if (searchResult && searchResult.length === 1) {
					let currentProduct = searchResult[0];
					let productLinkHref = currentProduct.dynamic === 'Y' ? currentProduct.productPageUrl : objects.$familyLookupUrl+'?family=' + currentProduct.family;
					window.location.href = productLinkHref;
				} else {
					this.runSearchForm();
				}
			}
		};
		
		this.initAutoComplete = () => {
			console.log('In AutoComplete');
			
			let mainClass = this;
			
			document.addEventListener("DOMContentLoaded", function(event) {
				$(el).click(function (event) {
					var element = event.target;
					if(element.classList.contains("searchSubmit")){
						event.preventDefault();
						mainClass.runSearchForm();
					}
				});
	
				objects.$searchTerm.on('keyup',mainClass.onKeyupFunction);
				objects.$searchTermMobile.on('keyup',mainClass.onKeyupFunction);

				/*$("#searchTerm").on('keyup', utils.debounce((e) => {
					console.log("keyup debounce");
					let searchTerm =  $(e.currentTarget).val();
					if(searchTerm.length === 5 || searchTerm.length > 5) {
						parseSearch(searchTerm);
					}
				}, 500));*/
	
	
				/*objects.$input.on('keyup', utils.debounce((e) => {
					this.handleSearch(e);
				}, 500));*/
	
				objects.$searchTerm.keypress(mainClass.onKeyPressFunction);
				objects.$searchTermMobile.keypress(mainClass.onKeyPressFunction);
					
			});
		};
		
	}
	init() {
		this.initAutoComplete();
	}
}

export default AutoComplete;
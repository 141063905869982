class HeroInterior {

	static name() {
		return 'HeroInterior';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$subheadContainer: $(el).find('.sub-subhead-container'),
			$subhead: $(el).find('.slide-subhead'),
			$heroDescription: $(el).find('.hero-desc')
		};

		let utils = new Utils();

		this.flexFont = () => {

			const subheadW = objects.$subheadContainer.outerWidth();

			if(!(utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm' || utils.getViewportSize() === 'md' || utils.getViewportSize() === 'lg')) {
				if(objects.$el.hasClass('photo-page')) {

					// Photo Page view text adjustment
					objects.$subhead.css({ 'font-size':  (subheadW * 0.0355) + 'px'});
					objects.$subhead.css({ 'line-height':  (subheadW * 0.0412) + 'px'});
					objects.$heroDescription.css({ 'font-size':  (subheadW * 0.0645) + 'px'});
					objects.$heroDescription.css({ 'line-height':  (subheadW * 0.0705)  + 'px'});
				} else {

					// Default View text adjustment
					objects.$subhead.css({ 'font-size':  (subheadW * 0.049) + 'px'});
					objects.$subhead.css({ 'line-height':  (subheadW * 0.057) + 'px'});
					objects.$heroDescription.css({ 'font-size':  (subheadW * 0.092) + 'px'});
					objects.$heroDescription.css({ 'line-height':  (subheadW * 0.0981) + 'px'});
				}

			} else {
				objects.$subhead.removeAttr('style');
				objects.$heroDescription.removeAttr('style');
			}
		};

		this.initHeroInterior = () => {
			$(window).on('load', (e) => {
				this.flexFont();

				setTimeout(function(){
					$(el).addClass('active');
				}, 50);
			});

			$(window).on('resize', (e) => {
				this.flexFont();
			});
		};

	}

	init() {
		this.initHeroInterior();
	}


}

export default HeroInterior;

import $ from 'jquery';

class Utils {

	constructor() {

		let _this = this;

		this.constants = {
			size: { small: 321, medium: 768, large: 992, xlarge: 1201, xxlarge: 1600 },
		};

		this.getViewportSize = () => {
			let width = window.innerWidth;
			if (width < this.constants.size.small) {
				return 'xs';
			} else if (width < this.constants.size.medium) {
				return 'sm';
			} else if (width < this.constants.size.large) {
				return 'md';
			} else if (width < this.constants.size.xlarge) {
				return 'lg';
			} else if (width < this.constants.size.xxlarge) {
				return 'xl';
			} else {
				return 'xxl';
			}
		};

		/**
		 * a11yClick
		 * This event controls the click, spacebar and enter key for binding
		 */
		this.a11yClick = (event) => {
			if (event.type === 'click') {
				return true;
			}
			else if (event.type === 'keypress') {
				const code = event.charCode || event.keyCode;
				if (code === 13) { //TODO: Check this in other browsers (code === 32)
					return true;
				}
			}
			else {
				return false;
			}
		};

		/**
		 * ariaHelp
		 * This event controls the injection of text into an aria-live control
		 */
		this.ariaHelp = (string) => {
			$('#a11y-help').text(string);
		};

		/**
		 * isIOS
		 * Checks if device is iOS to address potential mobile Safari/iOS issues
		 */
		this.isIOS = () => {
			const devices = [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			];
			while (devices.length) {
				if (navigator.platform === devices.pop()) {
					return true;
				}
			}
			return false;
		};

		/**
		 * sanitize
		 * This method strips html tags
		 */
		this.sanitize = function (str) {
			str = str.replace(/<(?:.|\n)*?>/gm, '');
			str = str.replace(/[|<|>|=|?|:|%|]/gm, '');
			return str;
		};

		/**
		 * encodeURIComponent
		 * This method escapes quotes and slashes (e.g. for search queries)
		 */
		this.encodeURIComponent = (str) => {
			str = str.replace(/"/g, '\\"');
			return encodeURIComponent(JSON.parse(`"${str}"`)).replace(/\(/g, '%28').replace(/\)/g, '%29');
		};

		/**
		 * ajaxLoader
		 * This method injects a loader to a selected container. This would usually be fired in a beforeSend ajax method as content loads in.
		 */
		this.ajaxLoader = (container) => {
			const loader = `
				<div class="ajax-loader">
					<svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/></svg>
				</div>
			`;
			container.html(loader);
		};

		/**
		 * scrollToTop
		 * This method creates a button that scrolls page to top
		 */
		this.scrollToTop = () => {
			const $backToTop = $(`
				<div class="scroll-to-top-container">
					<button class="scroll-to-top" aria-label="Scroll to top">
						<svg class="icon" focusable="false" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-back-to-top"></use></svg>
					</button>
				</div>
			`);
			$('.footer').before($backToTop);
			$('.scroll-to-top').on('click', (event) => {
				event.preventDefault();
				this.scrollTo(null, null, null, () => { });
			});

			$('.scroll-to-top').on('mouseleave', (event) => {
				$(event.currentTarget).find('use').attr('xlink:href', '#icon-back-to-top');
			});

			$(window).on('scroll', (e) => {
				let footerOffset = $('footer').offset().top;

				$(window).scrollTop() > 2000 ?
					/* > 2000px from top - show button */
					$('.scroll-to-top').addClass('is-visible') :
					/* <= 2000px from top - hide button */
					$('.scroll-to-top').removeClass('is-visible');

				/* Position absolute button when footer has entered the bottom of the window */
				if ($(window).scrollTop() + window.innerHeight >= footerOffset) {
					$('.scroll-to-top-container').css({
						position: 'absolute',
						top: (footerOffset - 50)
					});
				} else {
					$('.scroll-to-top-container').removeAttr('style');
				}
			});
		};

		/**
		 * debounce
		 * This method prevents excessive firing from things like scroll and resize handlers. Should be used instead of setTimeout.
		 * @param {function} func - description
		 * @param {integer} wait - timeout in ms
		 * @param {boolean} immediate - call function at start, defaults to false
		 */
		this.debounce = (func, wait, immediate) => {
			let timeout;
			return function () {
				let context = this, args = arguments;
				const later = function () {
					timeout = null;
					if (!immediate) { func.apply(context, args) }
				};
				const callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) { func.apply(context, args) }
			};
		};

		/**
		 * Load External Script and add to page
		 * @param  {[type]}   url      [description]
		 * @param  {Function} callback [description]
		 * @return {[type]}            [description]
		 */
		this.loadScript = (url) => {
			return new Promise((resolve, reject) => {
				let ready = false;
				const tag = document.getElementsByTagName('script')[0];
				const script = document.createElement('script');

				script.type = 'text/javascript';
				script.src = url;
				script.async = true;
				script.onload = script.onreadystatechange = () => {
					if (!ready && (!this.readyState || this.readyState === 'complete' || this.readyState === 'loaded')) {
						ready = true;
						resolve(this);
					}
				};
				tag.parentNode.insertBefore(script, tag);
				script.onerror = script.onabort = reject;
			});
		};

		/**
		 * getUrlParameter
		 * This method returns a url parameters value.
		 * @param {function} callback
		 */
		this.getUrlParameter = (sParam) => {
			let sPageURL = decodeURIComponent(window.location.search.substring(1)),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : sParameterName[1];
				}
			}
		};

		this.URL_add_parameter = (url, param, value) => {
			let hash = {};
			let parser = document.createElement('a');

			parser.href = url;

			let parameters = parser.search.split(/\?|&/);

			for (let i = 0; i < parameters.length; i++) {
				if (!parameters[i])
					continue;

				let ary = parameters[i].split('=');
				hash[ary[0]] = ary[1];
			}

			hash[param] = value;

			let list = [];
			Object.keys(hash).forEach(function (key) {
				list.push(key + '=' + hash[key]);
			});

			parser.search = '?' + list.join('&');
			return parser.href;
		};

		/**
		 * Use this interval in all scenarios where you would use setInterval. More performant using RAF
		 * @param  {callback} fn
		 * @param  {[number]} delay in milliseconds
		 * @return {[object]} object.value for cancelling raf
		 *
		 * Usage
		 * timer = utils.requestInterval( this.frame, 500 )
		 * utils.clearRequestInterval( timer )
		 *
		 */
		this.requestInterval = (fn, delay) => {
			let start = new Date().getTime();
			let handle = {};

			function loop() {
				const current = new Date().getTime();
				const delta = current - start;

				if (delta >= delay) {
					fn.call();
					start = new Date().getTime();
				}
				handle.value = self.requestAnimationFrame(loop);
			}

			handle.value = self.requestAnimationFrame(loop);
			return handle;
		};

		this.clearRequestInterval = handle => {
			self.cancelAnimationFrame(handle.value);
		};

		/**
		 * Convert Audio/Video Element's seconds to formatted string
		 * @param  {[number]} seconds
		 * @return {[string]} "00:00:00"
		 */
		this.secondsToTime = seconds => {
			let h, m, s;
			const Time = x => {
				return {
					map: f => Time(f(x)),
				};
			};
			Time(seconds)
				.map(x => Math.floor(x))
				.map(x => { h = Math.floor(x / 3600); return x })
				.map(x => { m = Math.floor(x % 3600 / 60); return x })
				.map(x => { s = Math.floor(x % 3600 % 60); return x });
			(h < 10) ? h = '0' + h : null;
			(m < 10) ? m = '0' + m : null;
			(s < 10) ? s = '0' + s : null;

			return (h > 0) ? `${h}:${m}:${s}` : `${m}:${s}`;
		};

		/*
		 * Single Listener for resize and scroll. Call all live module functions.
		 * Resize and scroll are called within Classes via this.resize or this.scroll respectively
		 */
		this.listeners = (mod) => {
			let resizes = [], scrolls = [];

			// mod.map( v => {
			if ($.isFunction(mod.resize)) {
				resizes.push(mod.resize);
			}
			if ($.isFunction(mod.scroll)) {
				scrolls.push(mod.scroll);
			}
			// });

			/*
			 *	Resize is debounced, scroll is not. You can deboune the class specific scroll method if you want.
			 */

			$(window).on('resize', _this.debounce((e) => {
				for (let i = 0; i < resizes.length; i++) {
					resizes[i](e);
				}
			}, 250, false));

			$(window).on('scroll', (e) => {
				for (let i = 0; i < scrolls.length; i++) {
					scrolls[i](e);
				}
			});
		};

		this.scrollTo = (scrollTarget = null, scrollOffset = null, duration = null, callback = null, scrollingElement = null) => {
			let scrollPos = 0;
			if (!scrollingElement) {
				scrollingElement = $('html, body');
			}
			if (scrollTarget !== null) {
				scrollPos = scrollTarget.offset().top;

				if (scrollOffset !== null) {
					scrollPos = scrollPos + scrollOffset;
				}
			}
			scrollingElement.animate({ scrollTop: scrollPos }, duration === null ? 'slow' : duration, callback === null ? null : callback);
		};

		// New set sticky funcion places is-absolute class as soon as the user scrolls
		this.setSticky = () => {
			$('[data-set-sticky]').each((i, el) => {
				let $el = $(el);
				let elOffset = $el.data('offset');
				let additionalOffsetElement = $($el.data('additional-offset'));
				let additionalOffset = additionalOffsetElement.length ? additionalOffsetElement.height() : 0;
				let endStickyContainer = $($el.data('sticky-container'));
				let endStickyContainerOffset = endStickyContainer.length ? endStickyContainer.offset().top : 0;
		
				let applySticky = () => {
					let scrollTop = $(window).scrollTop();
		
					if (scrollTop > elOffset - additionalOffset) {
						$el.addClass('is-persistent').css('top', Math.abs(additionalOffset) + 'px');
		
						if (scrollTop > endStickyContainerOffset - additionalOffset) {
							$el.addClass('is-absolute').css('top', Math.abs(endStickyContainerOffset - additionalOffset) + 'px');
						} else {
							$el.removeClass('is-absolute').css('top', Math.abs(additionalOffset) + 'px');
						}
		
						if ($el.data('spacer-content')) {
							$($el.data('spacer-content')).css('height', $el.height());
						}
					} else {
						$el.removeClass('is-persistent is-absolute').css('top', 'auto');
		
						if ($el.data('spacer-content')) {
							$($el.data('spacer-content')).css('height', 0);
						}
					}
				};
		
				$(window).on('scroll', applySticky);
				$(window).on('resize', () => {
					endStickyContainerOffset = endStickyContainer.length ? endStickyContainer.offset().top : 0;
					applySticky();
				});
		
				applySticky();  // Apply sticky state on initial load
			});
		};

		// this.setSticky = () => {
		// 	$('[data-set-sticky]').each((i, el) => {
		// 		let $el = $(el);
		// 		let elOffset = $el.data('offset');
		// 		let endStickyContainer = $($el.data('sticky-container'));
		// 		let $scrollPos = elOffset;
		// 		let $additionalOffset = $($el.data('additional-offset')).height();
		// 		let endStickyContainerOffset = 0;
		// 		if ($additionalOffset !== null && $additionalOffset !== undefined) {
		// 			$scrollPos = $additionalOffset;
		// 			endStickyContainerOffset = $additionalOffset;
		// 		}
		// 		if (endStickyContainer.length) {
		// 			endStickyContainerOffset = endStickyContainer.offset().top;
		// 		}
		// 		if($(window).scrollTop() > $scrollPos) {
		// 			$(el).addClass('is-persistent');
		// 			if($(window).scrollTop() > endStickyContainerOffset) {
		// 				$(el).addClass('is-absolute');
		// 				$(el).css('top', 0);
		// 				$(el).css('top', endStickyContainerOffset);
		// 				if ($el.data('spacer-content')) {
		// 					$($el.data('spacer-content')).css('height', $el.height());
		// 				}
		// 			} else {
		// 				$(el).removeClass('is-absolute');
		// 				$(el).css('top', 0);
		// 			}
		// 		} else {
		// 			if($(window).scrollTop() < 1) $(el).removeClass('is-persistent');
		// 			// $(el).css('top', 'auto');
		// 			if ($el.data('spacer-content')) {
		// 				$($el.data('spacer-content')).css('height', 0);
		// 			}
		// 		}
		// 		$(window).on('resize', function() {
		// 			$(el).css('top', 0);
		// 		});
		// 	});
		// };

		this.setStickyOffset = () => {
			$('[data-set-sticky]').each((i, el) => {
				let $el = $(el);
				$el.removeClass('is-persistent');
				$el.data('offset', $el.offset().top);
			});
		};

		this.setTooltips = () => {
			$('[data-toggle="tooltip"]').tooltip();
		};

		this.setPopover = () => {
			let _utils = new Utils();
			$('[data-toggle="popover"]').each((i, el) => {
				let $el = $(el);

				if (_utils.getViewportSize() === 'xs' || _utils.getViewportSize() === 'sm') {
					$el.popover({
						placement: 'bottom',
						offset: '-10px 50px'
					});

				} else {
					$el.popover({
						placement: 'right',
						offset: '-39% -3px'
					});
				}

				$el.on('shown.bs.popover', function () {
					let $close = $('.popover').find('.btn-close');

					$close.on('click', (e) => {
						$('.popover').popover('hide');
					});
				});
			});
		};

		/**
		 * initViewMore()
		 * Sets up functionality for any 'view more' list buttons
		 */
		this.initViewMore = () => {
			$('[data-view-more]').each((i, el) => {
				$(el).unbind('click');
				let $el = $(el);
				let targetSelector = $el.data('view-more');
				let initialCount = $el.data('initial-count');
				let displayClass = $el.data('display-class');
				let moreCount = $el.data('count');

				// reset by removing display (hiding) class
				$(`${targetSelector}`).removeClass(displayClass);

				// get total count of items being shown
				let $targetItems = $(`${targetSelector}:visible`);

				if (targetSelector === '.experiences-listing') {
					$targetItems = $(`${targetSelector}`).not('.hidden');
				}


				// hide separators (if used)
				let separatorSelector = $el.data('separator');

				// add display (hiding) class for items greater than initial count
				for (let j = initialCount; j < ($targetItems.length); ++j) {
					$($targetItems[j]).addClass(displayClass);

					if (separatorSelector) {
						let $separator = $($targetItems[j]).prev(separatorSelector);
						if ($separator) {
							$separator.addClass(displayClass);
						}
					}
				}

				if ($targetItems.length <= initialCount) {
					$el.hide();
				} else {
					$el.show();
				}

				let currentCount = initialCount;
				$el.on('click', (e) => {
					e.preventDefault();
					if (currentCount < $targetItems.length) {
						for (let m = currentCount; m < (currentCount + moreCount); ++m) {
							$($targetItems[m]).fadeIn('fast');
							$($targetItems[m]).removeClass(displayClass);

							// show separators when associated items are visible
							if (separatorSelector) {
								let $separator = $($targetItems[m]).prev(separatorSelector);
								if ($separator) {
									$separator.removeClass(displayClass);
								}
							}
						}
						currentCount += moreCount;
					}
					// hide button once all items are visible
					if (currentCount >= $targetItems.length) {
						$el.hide();
					}
				});
			});
		};

		/**
		 * initScrollTo()
		 * Sets up functionality for any smooth 'scroll to' links
		 */
		this.initScrollTo = () => {
			let _utils = new Utils();
			let $headerSize = 0;

			if (_utils.getViewportSize() === 'xs' || _utils.getViewportSize() === 'sm' || _utils.getViewportSize() === 'md') {
				$headerSize = $('.navbar-header').height();
			}


			$('[data-scroll-to]').each((i, el) => {
				$(el).on('click', (e) => {
					let dest = $(e.target).attr('href');
					_utils.scrollTo($(dest), -($headerSize));
				});
			});
		};

		/**
		 * initSetSticky()
		 * Sets up functionality for sticky elements
		 */
		this.initSetSticky = () => {
			this.setStickyOffset();

			this.listeners({
				scroll: this.setSticky,
				resize: () => {
					this.setStickyOffset();
					this.setSticky();
				}
			});
		};

		this.initBannerLink = () => {
			$('[data-click-link]').each((i, el) => {
				$(el).unbind('click');
				let $el = $(el);
				let link = $el.data('click-link');
				if (link) {
					$el.on('click', (e) => {
						if (e.target.tagName.toLowerCase() !== 'a') {
							e.preventDefault();
							window.location.href = link;
						}
					});
					$('a', $el).unbind('click');
				}

			});
		};

		/** moveModals()
		 * Moves modal markup to container existing at top-level of page to prevent layout/z-index issues
		 */
		this.moveModals = () => {
			let $modals = $('[data-module="Modal"]');
			let $container = $('#modal-container');
			if ($container) $modals.appendTo($container);
		};

		/**
		 * trimBadges()
		 * Sets up functionality for trim all descriptive badges to 3 max
		 */
		this.trimBadges = () => {
			let _utils = new Utils();
			let $badge = $('.badge-descriptive');

			if ($badge.parents('ul').length) {
				let $list = $badge.parents('ul');

				$list.each((i, el) => {
					$(el).children('li').slice(3).hide();
				});
			}
		};

		this.equalHeight = () => {
			let $heightObjects = $($('[data-height-equal]').data('height-equal'));
			let maxHeight = -1;

			$heightObjects.height('auto');

			$heightObjects.each(function () {
				maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
			});

			$heightObjects.each(function () {
				$(this).height(maxHeight);
			});
		};

		this.initTrapFocus = (element) => {

			const focusableElements =
				'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])';
			const modal = element[0]; // select the modal by it's id

			const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
			const focusableContent = modal.querySelectorAll(focusableElements);
			let lastFocusableElement = '';

			if ((this.getViewportSize() === 'xs' || this.getViewportSize() === 'sm' || this.getViewportSize() === 'md')) {
				lastFocusableElement = focusableContent[focusableContent.length - 1];
			} else if (modal.querySelector('[data-next]')) {
				lastFocusableElement = modal.querySelector('[data-next]');
			} else {
				lastFocusableElement = focusableContent[focusableContent.length - 1];
			}

			document.addEventListener('keydown', function (e) {
				let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

				if (!isTabPressed) {
					return;
				}

				if (e.shiftKey) { // if shift key pressed for shift + tab combination
					if (document.activeElement === firstFocusableElement) {
						lastFocusableElement.focus(); // add focus for the last focusable element
						e.preventDefault();
					}
				} else { // if tab key is pressed
					if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.focus(); // add focus for the first focusable element
						e.preventDefault();
					}
				}
			});
		};

		this.initEqualHeight = () => {
			this.equalHeight();

			this.listeners({
				resize: () => {
					this.equalHeight();
				}
			});
		};

		this.insertFormErrorContainers = () => {
			const errorMsgContainer = '<span class="invalid-feedback"></span>';

			// Insert error message container
			$('.form-control, .custom-select, .custom-field, .custom-check + label, .custom-radio + label').each(function () {
				if (!$(this).siblings('.invalid-feedback').length > 0) {
					$(this).after(errorMsgContainer);
				}
			});
		};

		this.submitForm = (e, successFunc, customValidateFunc) => {

			// let _utils = new Utils();
			// let $errorMessage = $($(e).data('error'));
			// let $messageDetails = $errorMessage.children().find('.details');

			e.on('submit', (event) => {
				event.preventDefault();
				event.stopPropagation();

				let $invalidInputs = $(e).find('.form-control:invalid, .custom-select:invalid, .custom-check:invalid, .custom-radio:invalid');

				$invalidInputs.each(function (index, node) {
					let message;
					if (node.validity.patternMismatch) {
						message = node.dataset.patternError;
					}
					else if (node.validity.valueMissing) {
						message = node.dataset.error;
					}

					$(this).siblings('.invalid-feedback').html(message);

				});

				if ($invalidInputs.length == 0) {
					successFunc.call();
				}

				// if (e[0].checkValidity() === false || customValid === false) {
				// 	// Static Error Message - Just show
				// 	if(typeof e.data('errorstatic') !== 'undefined') {
				// 		$errorMessage.show();
				// 	}
				//
				// 	// Dynamic Error Message - Default
				// 	else {
				// 		const newArray = [];
				//
				// 		// Reset message on submit
				// 		$messageDetails.text('');
				// 		$errorMessage.show();
				//
				// 		// Push to Message Array
				// 		$invalidInputs.each(function() {
				// 			if($(this).siblings('label').text() !== '') {
				//
				// 				if(!$(this).siblings('label').data('error-override-text')) {
				// 					newArray.push($(this).siblings('label').text());
				// 				} else {
				// 					newArray.push($(this).siblings('label').data('error-override-text'));
				// 				}
				//
				// 			} else {
				// 				//to handle international phone field
				// 				newArray.push($(this).parent().siblings('label').text());
				// 			}
				// 		});
				// 		let andString = $messageDetails.siblings('.translation-and').text();
				// 		// Append to Error message container
				// 		$messageDetails.append([newArray.slice(0, -1).join(', '), newArray.slice(-1)[0]].join(newArray.length < 2 ? '' : ' '+andString+' '));
				// 	}
				//
				// 	// Scroll to Error message
				// 	_utils.scrollTo($errorMessage, -($('.persistent-container').height()));
				//
				//
				// } else {
				// 	$errorMessage.hide();
				// 	successFunc.call();
				// 	// Post Ajax call
				// }

				e.addClass('was-validated');
			});
		};

		this.getOS = () => {
			let userAgent = window.navigator.userAgent,
				platform = window.navigator.platform,
				macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
				windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
				iosPlatforms = ['iPhone', 'iPad', 'iPod'],
				os = null;

			if (macosPlatforms.indexOf(platform) !== -1) {
				os = 'Mac';
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = 'iOS';
			} else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = 'Windows';
			} else if (/Android/.test(userAgent)) {
				os = 'Android';
			} else if (!os && /Linux/.test(platform)) {
				os = 'Linux';
			}

			return os;
		};

		this.currencyFormatter = (amount, locale, style, currency, decimals) => {
			let formatter = new Intl.NumberFormat(locale, {
				style: style,
				currency: currency,
				minimumFractionDigits: decimals
			});
			return formatter.format(amount);
		};

		this.getLangId = () => {
			let url = window.top.location.href;

			if (url.indexOf('zh_CN') !== -1 || url.indexOf('zh-cn') !== -1) {
				return 'zh_CN';
			}
			else if (url.indexOf('zh_TW') !== -1 || url.indexOf('zh-tw') !== -1) {
				return 'zh_TW';
			}
			else if (url.indexOf('jp_JP') !== -1 || url.indexOf('.jp') !== -1) {
				return 'ja_JP';
			}
			else {
				return 'en_US';
			}
		};

		this.trim = (s) => {
			return s.replace(/^\s+|\s+$/, '');
		};

		this.telephoneKeyPress = (e) => {
			let charCode = 0;
			if (e.charCode != null) charCode = e.charCode;
			else if (e.which != null) charCode = e.which;
			else if (e.keyCode != null) charCode = e.keyCode;

			// Allow everything except a-z and A-Z
			if (charCode < 65 || charCode > 90 && charCode < 97 || charCode > 122) return true;

			if (e.preventDefault) e.preventDefault();
			e.returnValue = false;
			return false;
		};

		this.psdNumberKeyPress = (e) => {
			let charCode = 0;
			if (e.charCode != null) charCode = e.charCode;
			else if (e.which != null) charCode = e.which;
			else if (e.keyCode != null) charCode = e.keyCode;

			// Allow numbers - and .
			if (charCode == 45 || charCode == 46 || charCode >= 48 && charCode <= 57) return true;

			if (e.preventDefault) e.preventDefault();
			e.returnValue = false;
			return false;
		};

		this.validateEmail = (fld) => {
			let error = '';
			let tfld = this.trim(fld.value);
			let emailFilter = /^[^@]+@[^@.]+\.[^@]*\w\w$/;
			let illegalChars = /[()<>,;:\\'[]]/;

			if (fld.value === '') {
				//fld.style.background = 'Yellow';
				error = 'You didn\'t enter an email address.\n';
			} else if (!emailFilter.test(tfld)) {
				//fld.style.background = 'Yellow';
				error = 'Please enter a valid email address.\n';
			} else if (fld.value.match(illegalChars)) {
				//fld.style.background = 'Yellow';
				error = 'The email address contains illegal characters.\n';
			} //else {
			//fld.style.background = 'White';
			//}

			return error;
		};

		this.initBannerLink();

	}  //constructor

	secondsToTime(seconds) {
		this.secondsToTime(seconds);
	}

	requestInterval(fn, delay) {
		this.requestInterval(fn, delay);
	}

	clearRequestInterval(handle) {
		this.clearRequestInterval(handle);
	}

	constants() {
		return this.constants;
	}

	name() {
		return 'Utils';
	}

}

export default Utils;

import slick from 'slick-carousel';

class Carousel {

	static name() {
		return 'Carousel';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
			$carousel: $(el).find('.carousel-slides')
		};

		this.randomizeSlides = () => {
			let $randomSlide = Math.floor(Math.random() * $('.slick-slide:not(.slick-cloned)').length);
			objects.$carousel.slick('slickGoTo', $randomSlide, true);
		};


		this.initCarousel = () => {
			objects.$carousel.slick();

			if(objects.$carousel.data('randomize') !== undefined) {
				this.randomizeSlides();
			}
		};

	}

	init() {
		this.initCarousel();
	}


}

export default Carousel;

class VicorResourceLibrary {

	static name() {
		return 'VicorResourceLibrary';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el:$(el),
			$seeMoreButton:$(el).find('.js-btn-see-more'),
			$resetButton:$(el).find('.js-btn-reset'),
			$items:$(el).find('.featuredcontent'),
			$maxColSize:$(el).data('max-collection-size'),
			$filters:$(el).find('select.js-display'),
			$masterFilters:$(el).find('select.js-master'),
			$resourceType:$(el).data('resource-type'),
			$pageNodeId:$(el).data('page-node-id')

		};

		let utils = new Utils();
		let oneType = objects.$resourceType;

		let maxCollectionSize = 12;
		if(objects.$maxColSize !== undefined) {
			maxCollectionSize = objects.$maxColSize;
		}

		let pageNodeId = undefined;
		if(objects.$pageNodeId !== undefined) {
			pageNodeId = objects.$pageNodeId;
		}

		this.randomInteger=(maxNbr) =>{
			return Math.floor(Math.random() * maxNbr );  // returns a random integer from 0 to maxNbr
		};

		this.seeMoreButtonClick = () => {
			let matches = objects.$el.find('.js-match.d-none');
			let displayMatches = objects.$el.find('.js-match.d-none');
			displayMatches.removeClass('d-none');
			if(matches.length <= displayMatches.length) {
				objects.$seeMoreButton.addClass('d-none');
			}
		};

		this.resetFiltersButtonClick = () => {
			objects.$filters.each(function(){
				if(this.selectedIndex > 0){
					$(this).removeClass('selected');
					$("this option:first").val();
				}
			});

			let dataFilterString = '';

			// Figure out filter string
			objects.$filters.each(function () {
				$("this option:first").val();
				$(this).prop("selectedIndex", 0).change();
				let filterValue = $(this).val();
					sessionStorage.setItem($(this).attr('id'), filterValue);
			});

			sessionStorage.removeItem('filter-type');

			//display logic for resource type listing page
			if(oneType !== undefined && oneType === 'podcast') {
				dataFilterString = '[data-type*=",'+ objects.$resourceType+',"]';
			}


			// Show only items that match filter
			objects.$items.removeClass('js-match');
			objects.$items.addClass('d-none');

			let matches = objects.$el.find('.featuredcontent'+dataFilterString);
			if(matches.length == 0) {
				// If no matches would show, display all
				matches = objects.$items;
			}
			// Mark matches
			matches.addClass('js-match');

			let displayMatches = objects.$el.find('.js-match:lt('+maxCollectionSize+')');
			displayMatches.removeClass('d-none');

			// // comment out to not display all
			// if(objects.$resourceType === undefined) {
			// 	// maxCollectionSize = 12;
			// 	if(objects.$maxColSize !== undefined) {
			// 		maxCollectionSize = objects.$maxColSize;
			// 	}
			// }

			// Show / Hide See More Button
			if(matches.length > displayMatches.length) {
				objects.$seeMoreButton.removeClass('d-none');
			} else {
				objects.$seeMoreButton.addClass('d-none');
			}

			// Update available filters
			objects.$el.find('select.js-display').each(function (){
				let type = $(this).attr('data-filter');
				let myId = $(this).attr('id');
				let checkFilterString = dataFilterString;
				let val = $(this).val();
				if(dataFilterString.length > 0) {
					let dataIndex = checkFilterString.indexOf('data-' + type);
					if (dataIndex > 0) {
						let startCut = checkFilterString.substring(0, dataIndex).lastIndexOf('[');
						let endCut = checkFilterString.substring(dataIndex).indexOf(']') + 2;
						checkFilterString = checkFilterString.substring(0, startCut) + checkFilterString.substring(startCut+endCut);
					}
				}

				// Clear all options
				$(this).find('option').remove();
				// Recreate all options
				var $allOptions = $('#'+myId+'-master > option').clone();
				$(this).append($allOptions);
				// Re-select value
				$(this).val(val);

				$(this).find('option').each(function () {
					let filterValue = $(this).attr('value');
					if(filterValue !== undefined && filterValue.length > 0) {
						let myFilter = '.featuredcontent'+checkFilterString+'[data-'+type+'*=",'+filterValue+',"]';
						let count = objects.$el.find(myFilter).length;
						if(count == 0) {
							// $(this).hide();
							// $(this).addClass('hideme');
							$(this).remove();
						} else {
							// $(this).show();
							// $(this).removeClass('hideme');
						}
					}
				});
			});

		};


		this.filterAll = () => {
			let dataFilterString = '';

			// Figure out filter string
			objects.$filters.each(function () {
				let type = $(this).attr('data-filter');
				let filterValue = $(this).val();
				if(oneType === undefined) {
					sessionStorage.setItem($(this).attr('id'), filterValue);
				}
				if (filterValue !== undefined && filterValue.length > 0) {
					dataFilterString += '[data-' + type + '*=",' + filterValue + ',"]';
				}
			});

			//display logic for resource type listing page
			if(oneType !== undefined) {
				dataFilterString += '[data-type*=",'+ objects.$resourceType+',"]';
				sessionStorage.removeItem('filter-type');
				$("."+pageNodeId).remove();
			}

			// Show only items that match filter
			objects.$items.removeClass('js-match');
			objects.$items.addClass('d-none');

			let matches = objects.$el.find('.featuredcontent'+dataFilterString);
			if(matches.length == 0) {
				// If no matches would show, display all
				matches = objects.$items;
			}
			// Mark matches
			matches.addClass('js-match');

			let displayMatches = objects.$el.find('.js-match:lt('+maxCollectionSize+')');
			displayMatches.removeClass('d-none');

			// comment out to not display all
			// if(objects.$resourceType === undefined) {
			// 	// maxCollectionSize = 12;
			// 	if(objects.$maxColSize !== undefined) {
			// 		maxCollectionSize = objects.$maxColSize;
			// 	}
			// }

			// Show / Hide See More Button
			if(matches.length > displayMatches.length) {
				objects.$seeMoreButton.removeClass('d-none');
			} else {
				objects.$seeMoreButton.addClass('d-none');
			}

			// Update available filters
			objects.$el.find('select.js-display').each(function (){
				let type = $(this).attr('data-filter');
				let myId = $(this).attr('id');
				let checkFilterString = dataFilterString;
				let val = $(this).val();
				if(dataFilterString.length > 0) {
					let dataIndex = checkFilterString.indexOf('data-' + type);
					if (dataIndex > 0) {
						let startCut = checkFilterString.substring(0, dataIndex).lastIndexOf('[');
						let endCut = checkFilterString.substring(dataIndex).indexOf(']') + 2;
						checkFilterString = checkFilterString.substring(0, startCut) + checkFilterString.substring(startCut+endCut);
					}
				}

				// Clear all options
				$(this).find('option').remove();
				// Recreate all options
				var $allOptions = $('#'+myId+'-master > option').clone();
				$(this).append($allOptions);
				// Re-select value
				$(this).val(val);

				$(this).find('option').each(function () {
					let filterValue = $(this).attr('value');
					if(filterValue !== undefined && filterValue.length > 0) {
						let myFilter = '.featuredcontent'+checkFilterString+'[data-'+type+'*=",'+filterValue+',"]';
						let count = objects.$el.find(myFilter).length;
						if(count == 0) {
							// $(this).hide();
							// $(this).addClass('hideme');
							$(this).remove();
						} else {
							// $(this).show();
							// $(this).removeClass('hideme');
						}
					}
				});
			});
			if(oneType !== 'podcast' && oneType !== undefined) {
				oneType = undefined;
			}
		};

		this.applyParamFilterStyles = () => {
			objects.$filters.each(function(){
				if(this.selectedIndex > 0){
					$(this).addClass('selected');
				}
			});
		};

		this.initVicorResourceLibrary = () => {
			// Display random featured item
			let featuredItems = objects.$el.find('.js-featured-item');
			let randomNbr = this.randomInteger(featuredItems.length);
			featuredItems.eq(randomNbr).removeClass('d-none');

			// Add select change listeners
			objects.$el.find('select').on('change', (e) => {
				this.filterAll();
			});

			// Add See More Button listener
			objects.$seeMoreButton.on('click',() => {
				this.seeMoreButtonClick();
			});

			// Add Reset Filters Button listener
			objects.$resetButton.on('click',() => {
				this.resetFiltersButtonClick();
			});

				// apply saved filters
				objects.$filters.each(function () {
					if (sessionStorage.getItem($(this).attr('id')) !== null) {
						$(this).val(sessionStorage.getItem($(this).attr('id')));
					}
				});

			// Apply Initial Filter in case some items were selected via page params
			this.filterAll();

			//apply selected class on elements that have been selected through params
			this.applyParamFilterStyles();
		};
	}

	init() {
		this.initVicorResourceLibrary();
	}

}

export default VicorResourceLibrary;

class GeoLocation {

	static name() {
		return 'GeoLocation';
	}

	constructor(props, el, $) {

		const geolocationOpts = {
			// enableHighAccuracy = should the device take extra time or power to return a really accurate result, or should it give you the quick (but less accurate) answer?
			enableHighAccuracy: false,
			// timeout = how long does the device have, in milliseconds to return a result?
			timeout: 5000,
			// maximumAge = maximum age for a possible previously-cached position. 0 = must return the current position, not a prior cached position
			maximumAge: 0
		};

		this.getLocation = () => {
			navigator.geolocation.getCurrentPosition(this.success, this.error, geolocationOpts);
		};

		this.success = (pos) => {
			this.reverseGeocode(pos.coords);
		};

		this.error = (err) => {
			this.emit(false, `Error: ${err}`);
		};

		this.locationUpdate = (e) => {
			let that = this;

			$.post(props.apiUrl, {
				scheme: 'user',
				regionKey: e.detail.regionKey,
				regionValue: e.detail.regionValue,
				countryKey: e.detail.countryKey,
				countryValue: e.detail.countryValue,
				firstLevelDivisionKey: e.detail.firstLevelDivisionKey,
				firstLevelDivisionValue: e.detail.firstLevelDivisionValue,
				jcrReturnContentType: 'json'
			}, function(resp) {
				resp.updated = true;
				that.emit(true, resp);
			}).fail(function(e) {
				that.emit(false, e);
			});
		};

		this.reverseGeocode = (coords) => {
			let that = this;
			let url = `${props.apiUrl}?latitude=${coords.latitude}&longitude=${coords.longitude}&jcrReturnContentType=json`;

			$.get(url, function(resp) {
				that.emit(true, resp);
			}).fail(function(e) {
				that.emit(false, e);
			});
		};

		this.emit = (success, data) => {
			document.dispatchEvent(new CustomEvent('location', {
				detail: {
					success: success,
					data: data
				}
			}));
		};

		this.parseCookie = () => {
			let cookie = document.cookie.split(';').filter(cookie => cookie.trim().indexOf('locationInfo') !== -1);

			if (cookie.length !== 1) {
				return {};
			}

			return {
				scheme: this.cookieValue('s', cookie[0]),
				regionLongName: this.cookieValue('rln', cookie[0]),
				regionShortName: this.cookieValue('rsn', cookie[0]),
				areaLongName: this.cookieValue('aln', cookie[0]),
				areaShortName: this.cookieValue('asn', cookie[0]),
				latitude: this.cookieValue('lat', cookie[0]),
				longitude: this.cookieValue('lng', cookie[0])
			};
		};

		this.cookieValue = (key, cookie) => {
			let start = cookie.indexOf(key + '=');

			if (start === -1) {
				return null;
			}

			start += key.length + 1;

			let end = cookie.indexOf('&', start);
			end = end !== -1 ? end : cookie.length;

			return cookie.substring(start, end);
		};

		this.initGeoLocation = () => {
			// Don't proceed if we're inside an iframe (e.g. Page Composer)
			if (window.self !== window.top) return;

			// You can send a "locationUpdate" event to override the values of the ip / lat/long geolocation
			// A "location" event will then be fired when the update has taken place, so client side components
			// can listen to that and update their views with respect to the event details

			document.addEventListener('locationUpdate', this.locationUpdate);

			// If we're already geo'd via latlng, don't do it again
			let geolocationInfo = this.parseCookie();

			if (geolocationInfo.scheme != null
				&& geolocationInfo.scheme !== 'UNKNOWN'
				&& geolocationInfo.scheme !== 'IP') {

				this.emit(true, geolocationInfo);

				return;
			}

			if (!('geolocation' in navigator)) {
				// web geolocation not supported, so just use IP based
				// we still have the ip based result, so just emit that
				this.emit(true, geolocationInfo);

				return;
			}

			// If we just have an ip based result, try to acquire the lat/long one
			this.getLocation();
		};
	}

	// When the page loads, a 'location' event shall be fired with results from either IP based, or latitude/longitude
	// based geolocation.  If those values are ever updated, the same event shall be fired, so client side code may
	// be notified of the updates and refresh the page / component, etc.

	init() {
		this.initGeoLocation();
	}
}

export default GeoLocation;
